// IMPORTS
import { Component } from "react";
import { vhToPx } from "../../util/display";

import arrow from "../../img/icons/arrow.svg"
import { setCookie } from "../../util/cookies";
import { copyTextToClipboard } from "../../util/generic";

// CLASSES
export class ViewSettings extends Component{
    constructor(props){
        super(props)
        this.onLogOut = this.onLogOut.bind(this)
    }
    render(){
        /*
        <SettingsCell title={'Account settings'} has_border={true} has_arrow={true}/>
                    <SettingsCell title={'Change password'} has_border={true} has_arrow={true}/>
                    <SettingsCell title={'Help, feedback'} has_border={true} has_arrow={true}/>
        */
        return (
            <div className="ContainerMain ContainerMainBlue" style={{'minHeight': vhToPx('100vh')}}>
                <div className='ContainerH1'>
                    <h1>Settings</h1>
                </div>
                <div className="ContainerContent">
                    <SettingsCell title={'Help, feedback'} has_border={true} has_arrow={true} on_click_function={this.onFeedback}/>
                    <SettingsCell title={'Log out'} has_border={false} has_arrow={true} is_gray_text={true} on_click_function={this.onLogOut}/>
                </div>
            </div>
        )
    }

    onLogOut(){

        setCookie('access_token', '')
        window.location.reload()

    }
    onFeedback(){
        alert('Wurd is in alpha, and all feedback is appreciated! Please reach out to: olivier.mauriele@gmail.com')
    }
}

class SettingsCell extends Component{
    render(){

        let class_names = "SettingsCell"
        class_names += this.props.has_border ? " SettingsCellBorder" : ""
        class_names += this.props.is_gray_text ? " grayText" : ""
        let arrow_component
        if(this.props.has_arrow){
            arrow_component = <div className="inlineBlock" style={{'float':'right'}}>
                                <img src={arrow}/>
                                </div>
        }

        return(<div className={class_names} onClick={this.props.on_click_function}>
                    <div className="inlineBlock">
                        {this.props.title}
                    </div>
                    {arrow_component}
                </div>)
    }
}