// IMPORTS
import { Component } from "react";
import { Url } from "../../util/server";
import { context } from "../../util/global";
import { follow_stack } from "../../models/friends";

// CLASSES
export class FollowButton extends Component{

    constructor(props){

        super(props)
        this.onClick = this.onClick.bind(this)
        this.state = {'user': this.props.user}

    }
    render(){

        let return_component
        let follow_button = this
        let class_names = "ButtonFollow"
        if(this.props.class_names){
            class_names += " " + this.props.class_names
        }
        if(follow_button.state.user.is_followed){
            class_names += " ButtonFollowSelected"
        }

        if(follow_button.state.user.is_followed){
            return_component = <button className={class_names} onClick={this.onClick}>
                Followed
            </button>
        }
        else{
            return_component = <button className={class_names} onClick={this.onClick}>
                Follow
            </button>
        }
        return(
            return_component 
        )
    }
    onClick(){

        this.props.user.is_followed = !this.props.user.is_followed
        this.setState({'user': this.props.user})

        if(this.props.user.is_followed){
            follow_stack.add(this.props.user.gae_key)
        }
        else{
            follow_stack.remove(this.props.user.gae_key)
        }

        if(follow_stack.timeout){
            clearTimeout(follow_stack.timeout)
        }
        let timeout = setTimeout(()=>follow_stack.send(), 500)
        follow_stack.timeout = timeout

    }
}