// IMPORTS
import {Component} from "react"

import { BlobPurpleGreen1, BlobTopLeft2 } from "../background/blob"
import { BlobTop } from "../background/blob"
import { MenuBottom } from "../menu/bottom"
import { MenuTop } from "../menu/top"

import { SearchBar } from "../generic/search"
import {Avatar} from "../users/avatar"

import {Url} from "../../util/server"
import {Topic} from "../../models/topic"

import { context } from "../../util/global"

import moviesIcon from "../../img/icons/movies.svg"
import tvIcon from "../../img/icons/tv.svg"
import { hideKeyboard, vwToPx } from "../../util/display"
import { EmptySearch } from "../generic/empty"
import { MainContext } from "../generic/context"
import { ViewTopic } from "./topic"

import { ActivityCellsList } from "../users/activity";

import { vhToPx } from "../../util/display"

// CONSTANTS
const k_autocomplete_min_length = 3
const k_max_results = 20
const k_autocomplete_url = context.server_root + 'autocompleteTitle/?return_objects=true&return_count=' + k_max_results + '&title='

// CLASSES
export class ViewSearch extends Component{

    static contextType = MainContext
    constructor(props){

        super(props)

        this.serverMatch = this.serverMatch.bind(this)
        this.parseSearch = this.parseSearch.bind(this)
        this.parseAutocomplete = this.parseAutocomplete.bind(this)
        this.parseAutocompleteWithResponse = this.parseAutocompleteWithResponse.bind(this)
        this.searchHasChanged = this.searchHasChanged.bind(this)
        this.searchBarDidEnter = this.searchBarDidEnter.bind(this)
        this.onModalClose = this.onModalClose.bind(this) // doesn't work
        this.parseFriendsLikes = this.parseFriendsLikes.bind(this)

        this.state = {
            // list of autocomplete options, received from server, and their gae keys
            'autocomplete_list': [],
            'autocomplete_topics': [],

            // last server search
            'autocomplete_server_last_title': undefined,

            // current search value
            'autocomplete_search_title': '',

            // status
            'is_searching': false,

            // friends
            'activities_friends': undefined,
                }

    }

    // props: topics_default
    render(){

        // Autcomplete components
        let autocomplete_search_title = SearchBar.autocompleteParseValue(this.state.autocomplete_search_title)
        let search_view = this
        let autocomplete_components = []

        // Filter
        let reg = new RegExp(autocomplete_search_title)
        for (let i=0; i<search_view.state.autocomplete_list.length; i++) {
    
            let is_match
            if(search_view.state.autocomplete_topics[i].is_api){
                is_match = true
            }
            else{
                is_match = SearchBar.autocompleteParseValue(search_view.state.autocomplete_list[i]).match(reg)
            }
            if (is_match) {
                autocomplete_components.push(
                    <AutocompleteCell key={search_view.state.autocomplete_topics[i].gae_key} topic={search_view.state.autocomplete_topics[i]}/>
                )
            }
        }

        let main_component
        
        if(this.state.autocomplete_server_last_title != undefined){
            if(autocomplete_components.length == 0){
                main_component = <EmptySearch/>
            }
        }

        let is_empty = main_component == undefined
        if(is_empty){
            main_component = <div>
                                {autocomplete_components}
                            </div> 
        }

        let friends_component

        if(is_empty & (autocomplete_components.length == 0)){
            if(this.state.activities_friends != undefined){
                friends_component = <div>
                                <div className="ContainerH2">
                                    <h2>Your friends recently liked</h2>
                                </div>
                                {/* Changes whole background?? */}
                                <ActivityCellsList
                                    activities={this.state.activities_friends}
                                    show_topic_image={true}/>
                                </div>
                main_component = undefined
            }
        }

        return (
            <div className="ContainerMain ContainerMainBlue" style={{'minHeight': vhToPx('100vh')}}>
                <div className="ContainerContent ContainerContentNoH1">

                    <div className="ViewSearchBar">
                        <SearchBar
                        placeholder="Movies and TV shows"
                        autocomplete_function = {this.searchHasChanged}
                        enter_function = {this.searchBarDidEnter}
                        />
                    </div>
                    <br/><br/><br/>
                    {main_component}
                    {friends_component}
                </div>
            </div>
        )
    }

    isNotEmpty(autocomplete_search_title){

        // TODO: merge with render
        let reg = new RegExp(autocomplete_search_title)
        for (let i=0; i<this.state.autocomplete_list.length; i++) {
    
            let is_match
            if(this.state.autocomplete_topics[i].is_api){
                is_match = true
            }
            else{
                is_match = SearchBar.autocompleteParseValue(this.state.autocomplete_list[i]).match(reg)
            }
            if (is_match) {
                return true
            }
        }
        return false

    }

    // Friends
    componentDidMount(){

        let activities = this.context.activities_recently_liked
        if(this.context.app_ref.current.checkIfLogin(false)){
            if (activities == undefined){
                new Url('qGetTopicsRecentlyLikedByFriends').get(this.parseFriendsLikes)
            }
            else{
                this.setState({'activities_friends': activities})
            }
        }
    }

    parseFriendsLikes(response){
        
        this.context.activities_recently_liked = [...response.results.activities]
        this.setState({'activities_friends': response.results.activities})
    }

    // Close modal
    onModalClose(){
        //this.state.input_ref.current.blur()
    }

    // Search
    searchBarDidEnter(){ // actual search when no topic?
    
        hideKeyboard()
        this.searchHasChanged(this.state.autocomplete_search_title, true)

    }

    parseSearch(response){
    }
	
    // Autocomplete
    parseAutocompleteWithResponse(response, title = undefined){

        let autocomplete_list = []
        let autocomplete_topics = []
        response.forEach(function(a_result){
            autocomplete_list.push(a_result.title)
            autocomplete_topics.push(a_result)
        })

		this.parseAutocomplete(autocomplete_list, autocomplete_topics, title)
    }
    parseAutocomplete(autocomplete_titles, autocomplete_topics, title=undefined){

		this.setState({'autocomplete_list': autocomplete_titles,
                        'autocomplete_topics': autocomplete_topics,
                        'autocomplete_server_last_title': title,
                        'is_searching': false})
 		
	}

    searchHasChanged(title, skip_min_length = false){
        
        let search_view = this

        this.setState({'autocomplete_search_title': title})

        if(search_view.isNotEmpty(title)){ // always send server request if empty list
            // new title is server title + characters
            if(search_view.state.autocomplete_list.length != k_max_results){ // only got top results otherwise, need to narrow search
                if(title.includes(search_view.state.autocomplete_server_last_title)){
                    return
                }
            }
        }

        // title is too short, no autocomplete
        if(skip_min_length == false ){
            if(title.length < k_autocomplete_min_length){
                return search_view.parseAutocomplete([], [], undefined)
            }
        }

        return search_view.serverMatch(title)
    }
	
	serverMatch(title){
        if(this.state.is_searching){
            return
        }
        this.setState({'is_searching': true})
        let search_view = this

        let parse_autocomplete_function = function(response){search_view.parseAutocompleteWithResponse(response, title)}

		Url.simpleGet(k_autocomplete_url + title, parse_autocomplete_function, undefined, 'jsonp')
	}
	
	static _autocompleteParse(value){
		
		value = value.toLowerCase()
		value = value.replace("'s", "s").replace('& ', '')
		
		return value
		
	}
}

class AutocompleteCell extends Component{

    static contextType = MainContext;

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    render(){

        /*
        let icon
        if(this.props.topic.category_title == 'movies'){
            icon = {moviesIcon}
        }
        else if(this.props.topic.category_title == 'tv'){
            icon = {tvIcon}
        }
        */

        if(!this.props.topic.image_url){
            return // just skip
        }
        
        let release_year 
        if(this.props.topic.release_date){
            release_year = this.props.topic.release_date.substring(0,4)
        }
        return(
            
            <div>
                <div className="AutocompleteCell" onClick={this.onClick}>
                    <div className="AutocompleteCellImage">
                        <img src={this.props.topic.image_url}
                                width="100%"
                                height="100%"/>
                    </div>
                    <div className="AutocompleteText">
                        <div>
                            <h2>{this.props.topic.title}</h2>
                        </div>
                        <div className="grayText">
                            {release_year}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    onClick(){

        //hideKeyboard()
        //this.context.app_ref.current.state.modal_ref.current.hide()
        this.context.app_ref.current.showModal(
            <ViewTopic topic={this.props.topic}/>,
            0.5, false, ViewTopic.onCloseModal)

    }
}