// IMPORTS
import { Component } from "react";

import $ from "jquery"

import bookmarks from "../../img/topics/bookmarks.svg"
import bookmarks_selected from "../../img/topics/bookmarks_selected.svg"
import share from "../../img/topics/share.svg"

import { vwToPx } from "../../util/display";

import { WordCell, WordsCells } from "../words/cell";
import { ViewWordCreate } from "../words/create";

import { ActivityCellsList } from "../users/activity";

import { ActivityBookmarkButton, BackButton } from "../generic/button"
import { ActivityBombButton, ActivityHeartButton } from '../generic/button'

import {Expanso} from "../generic/text"

import { Url } from "../../util/server";
import { activityGroupsWithActivities } from "../../models/activity";

import { vhToPx } from "../../util/display";
import { MainContext } from "../generic/context";
import { context } from "../../util/global";
import { ViewRecommend } from "./recommend";

import { releaseDate } from "../../util/date";

import imdb from "../../img/icons/imdb.svg"

import { k_platforms_list } from "./filter";
import { models_dict } from "../../models/main";

import {showValidation} from '../generic/modal';
import { ViewAddFriends } from "./friends";

// CLASSES
export class ViewTopic extends Component{

    static contextType = MainContext
    constructor(props){

        super(props)

        let friends_activities = props.friends_activities

        if(friends_activities == undefined){
            friends_activities = []
        }
        let words = props.words
        if(words == undefined){
            words = []
        }

        this.state = {'friends_activities': friends_activities,
                        'words': words,
                        'topic': this.props.topic,
                    }

        this.onClickButton = this.onClickButton.bind(this)
        this.reloadWords = this.reloadWords.bind(this)
        this.parseTopic = this.parseTopic.bind(this)
        this.parseWords = this.parseWords.bind(this)
        this.onWriteReview = this.onWriteReview.bind(this)
        this.showValidation = this.showValidation.bind(this)

    }

    render(){

        let that = this
        
        // Activities
        let activities_component
        let friends_activities = []
        if(this.state.topic.user_activity){
            if(['like', 'dislike'].includes(this.state.topic.user_activity)){
                friends_activities.push({'activity_type': that.state.topic.user_activity,
                                'score': that.state.topic.user_activity_score,
                                'user':context.user_me,
                                'topic':that.state.topic,
                                'words': []})
                            }
        }
        if(Object.keys(this.state.friends_activities).length > 0){
            this.state.friends_activities.forEach(function(an_activity){
                friends_activities.push(an_activity)
            })
        }
        if(friends_activities.length > 0){
            activities_component = <ActivityCellsList activities={friends_activities}
                                            show_topic_image={false}/>
        }
        else{
            activities_component = <div className="grayText">No friends review so far. <ViewAddFriends/></div>
        }

        // Date
        let release_date_component
        
        if(this.state.topic.release_date != undefined){
            release_date_component = <div className="purpleColor">
                                        Released {releaseDate(this.state.topic.release_date)}
                                        <br/><br/>
                                    </div>
        }

        // IMDB
        let imdb_component
        if(this.state.topic.score_imdb){
            imdb_component = <div>
                                <div className="ContainerTopicImdb">
                                    <div className="inlineBlock imageCenter"><img src={imdb}/></div>
                                    <div className="inlineBlock ContainerTopicImdbScore">{this.state.topic.score_imdb} / 10</div>
                                    
                                </div>
                                <br/>
                            </div>
        }

        // Video
        let video_url = this.state.topic.video_url
        if(video_url != undefined){
            if(video_url.includes('watch')){
                video_url = video_url.replace("watch?v=","embed/")
            }
            else if(video_url.includes("youtube.com")){
                video_url = video_url.replace("youtube.com", "youtube.com/embed")
            }
            else if(video_url.includes("youtu.be")){
                let video_link = video_url.split('/')
                video_link = video_link[video_link.length-1]
                video_url = "https://www.youtube.com/embed/" + video_link
            }

            video_url += '?autoplay=1&mute=1&showinfo=0&enablejsapi=1'

        }

        let video_scaler = 1
        let video_left = vwToPx(64 * (1-video_scaler) + 'vw')
        video_left = 0

        // Write review
        let write_review_component
        if(!this.state.topic.user_created_word){
            write_review_component = <button className="ButtonTeal ButtonLarge"
                                            style={{'marginTop': '1.57vw', 'marginBottom': '4.57vw'}}
                                            onClick={this.onWriteReview}>
                                            Write a Review
                                    </button>
        }

        let class_names_like = "SwipeIconsContainerStyle inlineBlock"
        if(this.state.topic.user_activity=='like'){
            class_names_like += " SwipeIconsContainerSelected"
        }
        let class_names_dislike = "SwipeIconsContainerStyle inlineBlock"
        if(this.state.topic.user_activity=='dislike'){
            class_names_dislike += " SwipeIconsContainerSelected"
        }

        // Platforms
        let platforms_component
        if(this.state.topic.platforms != undefined){
            if(this.state.topic.platforms.length > 0){
                let platform_list = []
                this.state.topic.platforms.forEach(function(a_platform){
                    k_platforms_list.forEach(function(a_filter){
                        if(a_filter.filter == a_platform){
                            platform_list.push(<div className="inlineBlock" key={a_platform} style={{'paddingRight':'2vw', 'verticalAlign':'middle'}}>
                                        <img
                                            src={a_filter.icon}
                                        />
                                    </div>)
                        }
                    })
                    
                })
                platforms_component = <div className="inlineBlock"><span style={{'paddingRight':'2vw'}}>Watch this on: </span>{platform_list}<br/><br/></div>
            }
        }

        return(
            <div className="ContainerMain transparent" style={{'minHeight': vhToPx('100vh')}}>

                {/* VIDEO */}
                <div style={{'borderRadius':'6.4vw',
                            'overflow': 'hidden',
                            
                            }}>
                    <div style={{'left':{video_left},
                            'position': 'relative',
                            }}
                            >
                        <iframe
                            width={vwToPx(100 * video_scaler + 'vw')}
                            height={vwToPx(64 * video_scaler + 'vw')}
                            
                            top={0}
                            position='absolute'
                            frameBorder='0'
                            showinfo='0'
                            src={video_url}
                            allowFullScreen
                            title={this.state.topic.title}
                            className="VideoYoutube"
                            >
                            
                        </iframe>
                    </div>

                    <div className="ContainerContent ContainerModal"
                             style={{'position':'relative','top':'-1.4vw'}}>
                        
                        {/* LIKE/DISLIKE*/}
                        <div
                            style={{'width':vwToPx('91.48vw'),
                            paddingTop:'6vw',
                            marginBottom:'-11vw',
                            }}>
                            <div className={class_names_dislike}
                                style={{'width':vwToPx('12.524vw'),
                                'height': vwToPx('10.2446vw'),
                                }}>
                                    <ActivityBombButton width='100%' is_static={true} on_click_function={(activity_score)=>this.onClickButton('dislike', activity_score)} is_selected={this.state.topic.user_activity=='dislike'} info_function = {(activity_score) => that.showValidation('dislike', activity_score)}/>
                            </div>
                            <div className={class_names_like}
                                style={{'width':vwToPx('12.524vw'),
                                'height': vwToPx('10.2446vw'),
                                'marginLeft': '4.26vw',
                                }}>
                                    <ActivityHeartButton width='100%' is_static={true} on_click_function={(activity_score)=>this.onClickButton('like', activity_score)} is_selected={this.state.topic.user_activity=='like'} info_function = {(activity_score) => that.showValidation('like', activity_score)}/>
                            </div>
                            <span style = {{'float': 'right', 'paddingLeft': '4.26vw'}}
                                onClick={()=>this.onClickButton('bookmark')}>
                                <img
                                    src={(this.state.topic.user_activity=='bookmark') ? bookmarks_selected : bookmarks}
                                />
                            </span>
                            <span style = {{'float': 'right'}}
                                onClick={()=>this.onClickButton('share')}>
                                <img
                                    src={share}
                                />
                            </span>
                        </div>
                        <br/><br/>

                        {/* TITLE */}
                        <div className="ContainerH1 noPaddingLeft">
                            <h1 style = {{'float': 'left'}}>
                                
                                    {this.state.topic.title}
                                
                            </h1>
                        </div>
                        <br/>

                        {/* DATE */}
                        {release_date_component}


                        {/* IMDB */}
                        {imdb_component}

                        {/* DESCRIPTION*/}
                        <div className="grayText">
                            <Expanso text = {this.state.topic.info}/>
                        </div>

                        <br/>
                        
                        {/* Platforms */}
                        {platforms_component}
                        
                        {/* FRIENDS REVIEWS*/}
                        <div className="ContainerH2">
                            <h2>Friends' Reviews</h2>
                        </div>
                        {activities_component}

                        <br/><br/>
                        {/* USERS REVIEWS */}
                        <div className="ContainerH2">
                            <h2>Users' Reviews</h2>
                        </div>
                        {write_review_component}
                        <div className="ContainerMultipleWords">
                            <WordsCells words={this.state.words} on_delete_function={this.reloadWords}/>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount(){
        new Url('qGetTopicWithGaeKey', 'topic_gae_key', this.state.topic.gae_key).get(this.parseTopic)
    }

    static onCloseModal(){
        $('.VideoYoutube').each(function(){
            this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
          });
    }

    onClickButton(button_type, activity_score = 1){

        let view_topic = this
        if(['like', 'dislike', 'bookmark'].includes(button_type)){
            this.state.topic.userActivity(button_type, activity_score, view_topic.context)
            this.forceUpdate()
        }
        else if(button_type == 'share'){
            // Recommend to your friends
            context.user.friends = []

            if(!this.context.app_ref.current.checkIfLogin()){
                return
            }
            
            this.context.app_ref.current.showModal(<ViewRecommend topic={this.state.topic}/>, 0.05)
        }
    }

    onWriteReview(){

        ViewTopic.onCloseModal()
        this.context.app_ref.current.showModal(
            <ViewWordCreate topic={this.state.topic} on_close_function={this.reloadWords}/>,
            0.05,
            true,
        )
    }

    reloadWords(){

        let view_topic = this
        new Url('qGetWords', 'topic_gae_key', this.state.topic.gae_key, 'category_title', this.state.topic.category_title).get(
            view_topic.parseWords
        )
    }

    parseTopic(response){
        
        let friends_activities = []
        if(response.results.friends_reviews.length > 0){
            
            friends_activities = response.results.activities

        }

        let topic = response.results.topic
        models_dict[topic.gae_key] = topic // to be able to retrieve latest version
        this.setState({'friends_activities': friends_activities,
                       'words':response.results.words,
                    'topic':topic})
    }

    parseWords(response){
        this.setState({'words':[...response.results.words]})
    }

    showValidation(activity_type, activity_score){

        // if on?
        if(this.state.topic.user_activity != activity_type){
            showValidation(this.state.topic, activity_type, activity_score, this.context.app_ref)
        }

    }

}