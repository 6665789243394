// IMPORTS
import {Url} from '../util/server'
import {context} from '../util/global'

// CLASSES
class FollowStack{
    constructor(){
        this.friends_add = []
        this.friends_remove = []
        this.timeout = undefined
        this.parse = this.parse.bind(this)
    }
    add(user_gae_key){
        if(!this.friends_add.includes(user_gae_key)){
            this.friends_add.push(user_gae_key)
        }
    }
    remove(user_gae_key){
        if(!this.friends_remove.includes(user_gae_key)){
            this.friends_remove.push(user_gae_key)
        }
    }
    send(){

        let me = this
        
        if((this.friends_add.length > 0) | (this.friends_remove.length > 0)){

            new Url('qFollowUsers', 'follow_users_gae_keys', me.friends_add, 'unfollow_users_gae_keys', me.friends_remove).get(me.parse)
            this.friends_add = []
            this.friends_remove = []
        }

    }
    parse(response){

        context.needs_reload['swipe'] = true
        context.needs_reload['feed'] = true

    }
}

export let follow_stack = new FollowStack()