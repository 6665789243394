// IMPORTS
import { Component } from "react";
import { vhToPx, vwToPx } from "../../util/display";
import {CloseButton, BackButton} from "./button"
import { MainContext } from "./context";
import heart from '../../img/icons/heart.gif'
import heartempty from '../../img/icons/heartempty.png'
import bomb from '../../img/icons/bomb.gif'
import bombempty from '../../img/icons/bombempty.png'
import trash from '../../img/icons/trashselected.svg'
import bookmark from '../../img/icons/bookmarkvalidation.svg'

// CONSTANTS
export let k_modal_type_default = undefined
export let k_modal_type_info = 'info'
export let k_modal_type_validation = 'validation'

// CLASSES
export class ModalView extends Component{

    static defaultProps = {'modal_type': k_modal_type_default,
                            'is_hidden': true,}
    static contextType = MainContext
    constructor(props){
        super(props)
        this.state = {'is_hidden': this.props.is_hidden,
                        // 'children': this.props.children,
                        // 'back_button_opacity': this.props.back_button_opacity,
                        // 'show_full_screen': this.props.show_full_screen,
                        // 'on_close_function': this.props.on_close_function,
                        } // add children dynamically?
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        
    }
    render() {
        if(this.props.modal_type == k_modal_type_default){
                
            let class_names = "ModalView"
            let class_names_background = "ModalViewBackground"
            let style = {}
            if (!this.state.is_hidden) {
                class_names += " ModalViewOpen"
                class_names_background += " ModalViewBackgroundOpen"
                style['minHeight'] = vhToPx('100vh')
            }
            let top_offset
            if(this.props.show_full_screen){
                class_names += " ModalViewFullScreen"
                top_offset = '3.4vw'
            }
            if(this.props.z_index){
                style['zIndex'] = this.props.z_index
            }
            let button_component
            if(this.props.back_button_opacity > 0.){
                if(this.props.show_full_screen){
                    button_component = <BackButton
                                    onClick={()=>this.hide()}
                                    opacity={this.props.back_button_opacity}
                                    top_offset={top_offset}
                                    />
                }
                else{
                    button_component = <CloseButton
                                    onClick={()=>this.hide()}
                                    opacity={this.props.back_button_opacity}
                                    top_offset={top_offset}
                                    />
                }

            }
            return(<div className={class_names_background} style={style}>
                <div className={class_names}>
                    {button_component}
                    {this.props.children}
                </div>
            </div>)

        }
        else if(this.props.modal_type == k_modal_type_info){ // INFO
            let class_names = "ModalViewInfo"
            if (!this.state.is_hidden) {
                class_names += " ModalViewInfoOpen"
            }

            return(<div className={class_names} onClick={()=>this.hide(false)} style={{'height': vhToPx('7vh')}}>
                    {this.props.children}
                </div>
            )
        }
        else if(this.props.modal_type == k_modal_type_validation){ // VALIDATION
            let class_names = "ModalViewValidationClose"
            if (!this.state.is_hidden) {
                class_names += " ModalViewValidationOpen"
            }
            else{
                class_names += " hidden"
            }
            return(
                <div className={class_names}>
                    {this.props.children}
                </div>
            )
        }
        
    }

    componentDidMount(){

        let that = this
        let timeout_ms = 3000
        if(this.props.modal_type == k_modal_type_validation){
            timeout_ms = 1000
        }
        if([k_modal_type_info, k_modal_type_validation].includes(this.props.modal_type)){
            setTimeout(function(){if(!that.state.is_hidden){that.hide(true)}}, 3000)
        }
    }
    
    show(){
        this.setState({'is_hidden': false})
    }
    hide(is_skip_functions = false){

        let that = this
        // console.log(this.props.children, this.props.children.onModalClose)
        // doesn't work...
        if(!is_skip_functions){
            if(this.props.children.onModalClose != undefined){
                this.props.children.onModalClose()
            }
        }
        this.setState({'is_hidden': true}
                        )
        /*
            callback? Makes ref getting reused, so that new modal view is associated with old ref
            function(){
                            modal_view.context.app_ref.current.state.modal_views.pop()
                        }

        */
        if(!is_skip_functions){
            let on_close_function = this.props.on_close_function
            if(on_close_function != undefined){
                on_close_function()
            }
        }
        
    }
}

export class ValidationView extends Component{
    static defaultProps = {
        'image_count': 1,  
        'image_count_max': 3, 
    }
    render(){

        let class_names = "ModalViewValidation" // ModalViewValidationImageCount" + this.props.image_count
        let image_components = []
        for(let i=0;i<this.props.image_count_max;i+=1){
            let image_src
            if(i < this.props.image_count){
                image_src = this.props.image_src
            }
            else{
                image_src = this.props.image_src_empty
            }
            image_components.push(<div className="inlineBlock" key={i} style={{'marginLeft':'-3vw'}}>
                                        <img src={image_src} style={{'height':'13vw'}}/>
                                    </div>)
        } // height: 30vw
        //  style={{'width':vwToPx('55vw'),'textOverflow':'ellipsis','overflow':'hidden', 'whiteSpace':'nowrap', 'display':'inlineBlock'}}
        return(
            <div className={class_names} onClick={this.props.on_click_function}>
                <div className="ModalViewValidationImage" style={{'marginTop':this.props.image_top,'marginRight':this.props.image_right}}>
                    {image_components}
                </div>
                <div className="ModalViewValidationTextContainer">
                    <div className="ModalViewValidationSubtext">
                        {this.props.subtext}
                    </div>
                    <div className="ModalViewValidationText">
                        {this.props.text}
                    </div>
                </div>
            </div>
        )
    }
}

export class ValidationViewHeart extends Component{
    static defaultProps = {
        'image_count': 1,   
    }
    render(){
        let subtext

        if(this.props.image_count == 1){
            subtext = <span className="ModalViewValidationSubtext">You liked 
                            <span className="purpleColor"> {this.props.topic_title}</span>
                        </span>
        }
        else if(this.props.image_count == 2){
            subtext = <span className="ModalViewValidationSubtext">You really liked 
                        <span className="purpleColor"> {this.props.topic_title}</span>
                    </span>
        }
        else if(this.props.image_count == 3){
            subtext = <span className="ModalViewValidationSubtext">You absolutely loved 
                        <span className="purpleColor"> {this.props.topic_title}</span>
                    </span>
        }
        let text
        if(this.props.is_swipe){
            text = "Swipe longer to like more"
        }
        else{
            text = "Tap once more to like more"
        }
        return <ValidationView image_src={heart}
                                image_src_empty={heartempty}
                                text={text}
                                subtext={subtext}
                                image_count={this.props.image_count}
                                on_click_function={this.props.on_click_function}
                                image_top={'1.1vw'}/>
    }
}

export class ValidationViewBomb extends Component{
    static defaultProps = {
        'image_count': 1,   
    }
    render(){
        let subtext
        if(this.props.image_count == 1){
            subtext = <span className="ModalViewValidationSubtext">You didn't like
                        <span className="purpleColor"> {this.props.topic_title}</span>
                    </span>
        }
        else if(this.props.image_count == 2){
            subtext = <span className="ModalViewValidationSubtext">You really disliked 
                    <span className="purpleColor"> {this.props.topic_title}</span>
                </span>
        }
        else if(this.props.image_count == 3){
            subtext = <span className="ModalViewValidationSubtext">You absolutely hated
                        <span className="purpleColor"> {this.props.topic_title}</span>
                    </span>
        }
        let text
        if(this.props.is_swipe){
            text = "Swipe longer to dislike more"
        }
        else{
            text = "Tap once more to dislike more"
        }
        return <ValidationView image_src={bomb}
                            image_src_empty={bombempty}
                            text={text}
                            subtext={subtext}
                            image_count={this.props.image_count}
                            on_click_function={this.props.on_click_function}/>
    }
}

export class ValidationViewBookmark extends Component{
    render(){
        let subtext
        subtext = <span className="ModalViewValidationSubtext">You bookmarked
                        <span className="purpleColor"> {this.props.topic_title}</span>
                    </span>
        return <ValidationView image_src={bookmark}
                                text={"See it in the Bookmarks tab"}
                                subtext={subtext}
                                image_count_max={1}
                                on_click_function={this.props.on_click_function}
                                image_right={'1vw'}/>
    }
}
export class ValidationViewTrash extends Component{
    render(){
        let subtext
        subtext = <span className="ModalViewValidationSubtext">You skipped
                        <span className="purpleColor"> {this.props.topic_title}</span>
                    </span>
        return <ValidationView image_src={trash}
                                text={"We won't show it again"}
                                subtext={subtext}
                                image_count_max={1}
                                on_click_function={this.props.on_click_function}
                                image_right={'0.5vw'}/>
    }
}

export function showValidation(a_topic, activity_type, activity_score, app_ref, is_swipe = false){

    let validation_view
    let topic_title = a_topic.title
    let topic_title_long = a_topic.title
    let topic_title_very_long = a_topic.title
    let max_length_long = 22
    let max_length_very_long = 25
    let max_length
    if(activity_score == 1){
        max_length = 15
    }
    else if(activity_score == 2){
        max_length = 11
    }
    else if(activity_score == 3){
        max_length = 7
    }
    if(topic_title.length > max_length){
        topic_title = topic_title.substring(0,max_length) + '...'
    }
    if(topic_title_long.length > max_length_long){
        topic_title_long = topic_title_long.substring(0,max_length_long) + '...'
    }
    if(topic_title_very_long.length > max_length_very_long){
        topic_title_very_long = topic_title_very_long.substring(0,max_length_very_long) + '...'
    }
    let that = this
    let on_click_function = () => that.undoLastActivity(a_topic)

    if(activity_type == 'like'){
        validation_view = <ValidationViewHeart on_click_function={on_click_function} image_count={activity_score} topic_title = {topic_title} is_swipe={is_swipe}/>
    }
    if(activity_type == 'dislike'){
        validation_view = <ValidationViewBomb on_click_function={on_click_function} image_count={activity_score} topic_title = {topic_title} is_swipe={is_swipe}/>
    }
    if(activity_type == 'bookmark'){
        validation_view = <ValidationViewBookmark on_click_function={on_click_function} is_swipe={is_swipe} topic_title = {topic_title_long}/>
    }
    if(activity_type == 'trash'){
        validation_view = <ValidationViewTrash on_click_function={on_click_function} is_swipe={is_swipe} topic_title = {topic_title_very_long}/>
    }

    /*
    if(!is_first_show){
        
        let views_count = this.context.app_ref.current.state.modal_views.length
        this.context.app_ref.current.state.modal_views[views_count-1].ref.current.hide()

    }
    */

    app_ref.current.showValidation(validation_view)
}