// IMPORTS
import {Component} from 'react'
import heart from '../../img/icons/heart.svg'
import bomb from '../../img/icons/bomb.svg'
//import trash from '../../img/icons/trash.svg'
import trash from '../../img/icons/skipswipe.svg'
import bookmark from '../../img/icons/bookmark.svg'
import { capitalizeFirstLetter } from '../../util/string'
import { vhToPx } from '../../util/display'

// CLASSES
class SwipeButton extends Component{

    static defaultProps = {}
    render(){

        let class_names = "SwipeButton SwipeButton" + capitalizeFirstLetter(this.props.position)
        let style={'zIndex': this.props.z_index, 'opacity': this.props.opacity}
        if(['left', 'right'].includes(this.props.position)){
            style['top'] = vhToPx('35vh')
        }
        if(this.props.position == 'bottom'){
            style['bottom'] = vhToPx('12vh')
        }
        if(this.props.is_selected){
            class_names += " SwipeButtonSelected"
        }
        return(
            <div className={class_names} style={style}>
                <img draggable={false} className={"SwipeButtonImage SwipeButtonImage" + capitalizeFirstLetter(this.props.image_size) + " SwipeButtonImage" + capitalizeFirstLetter(this.props.position)} src={this.props.image_src}/>
            </div>
        )
    }

}

export class SwipeButtonLike extends Component{
    render(){
        return(
            <SwipeButton position='right'
            image_src = {heart}
            image_size = 'big'
            top = {vhToPx('1vh')}
            is_selected={this.props.is_selected}
            z_index={this.props.z_index}
            opacity={this.props.opacity}/>
        )
    }
}
export class SwipeButtonDislike extends Component{
    render(){
        return(
            <SwipeButton position='left'
            image_src = {bomb}
            image_size = 'big'
            is_selected={this.props.is_selected}
            z_index={this.props.z_index}
            opacity={this.props.opacity}/>
        )
    }
}
export class SwipeButtonBookmark extends Component{
    render(){
        return(
            <SwipeButton position='top'
            image_src = {bookmark}
            image_size = 'small'
            is_selected={this.props.is_selected}
            z_index={this.props.z_index}
            opacity={this.props.opacity}/>
        )
    }
}
export class SwipeButtonTrash extends Component{
    render(){
        return(
            <SwipeButton position='bottom'
            image_src = {trash}
            image_size = 'tiny'
            is_selected={this.props.is_selected}
            z_index={this.props.z_index}
            opacity={this.props.opacity}/>
        )
    }
}