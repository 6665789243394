// IMPORTS
import {Component} from 'react'

import {WordCell} from '../words/cell'
import { Avatar } from '../users/avatar'

import { Word } from '../../models/word'
import { vhToPx } from '../../util/display'

import { context } from '../../util/global';

import notification_background from '../../img/background/black card.png'
import alone1 from '../../img/notifications/alone-1.gif'
import alone2 from '../../img/notifications/alone-2.gif'
import alone3 from '../../img/notifications/alone-3.gif'
import faceless1 from '../../img/notifications/faceless-1.gif'
import faceless2 from '../../img/notifications/faceless-2.gif'
import faceless3 from '../../img/notifications/faceless-3.gif'
import { EmptyFriends, EmptyPicture } from '../generic/empty'

// COMPONENTS
export class Card extends Component{

    constructor(props){

        super(props)
        this.state = {'topic': props.topic}
    }
    render(){

        // No topic
        if(this.state.topic == undefined){
            return <div></div>
        }

        // Notification card
        if(this.state.topic.is_notification){
            if(this.state.topic.title == "friends"){

                // Random image
                let image_src
                let rand = this.state.topic.gae_key
                if (rand < 0.33){
                    image_src = alone1
                }
                else if (rand < 0.66){
                    image_src = alone2
                }
                else{
                    image_src = alone3
                }
                return(
                    <div>
                        <img src={notification_background}
                            className="SwipeCardImage overflowHidden"
                            draggable="false"/>
                        
                        <div className="SwipeCardNotificationContent" style={{'width': '100%'}}>
                            <div className="SwipeCardNotificationTitle">
                                You have no friends...
                            </div>
                            <br/>
                            <div className="SwipeCardNotificationImageContainer">
                                <img src={image_src} style={{'width': '100%'}} className="SwipeCardNotificationImage"/>
                            </div>
                            <br/>
                            <EmptyFriends/>
                        </div>
                    </div>
                    
                    )
                }

            else if(this.state.topic.title == "picture"){

                // Random image
                let image_src
                let rand = this.state.topic.gae_key
                
                if (rand < 0.33){
                    image_src = faceless1
                }
                else if (rand < 0.66){
                    image_src = faceless2
                }
                else{
                    image_src = faceless3
                }
                return(
                    <div>
                        <img src={notification_background}
                            className="SwipeCardImage overflowHidden"
                            draggable="false"/>
                        
                        <div className="SwipeCardNotificationContent" style={{'width': '100%'}}>
                            <div className="SwipeCardNotificationTitle">
                                You have no profile picture...
                            </div>
                            <br/>
                            <div className="SwipeCardNotificationImageContainer">
                                <img src={image_src} style={{'width': '100%'}} className="SwipeCardNotificationImage"/>
                            </div>
                            <br/>
                            <EmptyPicture/>
                        </div>
                    </div>
                    
                    )
                }
        }


        // Normal card
        let avatar
        
        if(!context.is_default_user){

            let user_liked
            let user_liked_text
            if(this.state.topic.is_recommendation){

                user_liked = this.state.topic.user_liked
                user_liked_text = user_liked.pseudo + " recommended it!"
            }
            else if(this.state.topic.user_liked){

                user_liked = this.state.topic.user_liked
                user_liked_text = user_liked.pseudo + " liked it."
                
            }
            else if(this.state.topic.is_bookmark){

                user_liked = context.user
                user_liked_text = "You bookmarked it."
                
            }
            if(user_liked){
                avatar = <div>
                            <div className="SwipeCardContentAvatar">
                                <Avatar user={user_liked} size='15vw' shape='blob'/>
                                <span className="SwipeCardContentAvatarText">{user_liked_text}</span>
                            </div>
                        </div>
            }
            
        }

        let word_component
        
        if(this.state.topic.words_top_texts){
            if(this.state.topic.words_top_texts.length > 0){

                let word_top = new Word({'text':this.state.topic.words_top_texts[0]})
                
                word_component = (
                    <div className="SwipeCardContentWord" style={{'top':vhToPx('5.8vh')}}>
                        <WordCell word={word_top} is_swipe={true}/>
                    </div>
                    )
            }
        }

        return(
            <div>
                <img src={this.state.topic.poster_url}
                    className="SwipeCardImage"
                    draggable="false"/>
                
                <div className="SwipeCardContent">
                    <div className="SwipeCardContentTitle">
                        {this.state.topic.title}
                    </div>
                    {word_component}
                    {avatar}
                    
                </div>
            </div>
              
              )
    }
}