export function removeFromArray(an_array, an_element){
	const idx = an_array.indexOf(an_element)
	if(idx > -1){
		an_array.splice(idx, 1); // in place
		
	}
	return an_array
}

export function isArray(an_array){
	return an_array.constructor === Array
}

export function arrayNoDupe(a) {
    var temp = {};
    for (var i = 0; i < a.length; i++)
        temp[a[i]] = true;
    var r = [];
    for (var k in temp)
        r.push(k);
    return r;
}

export function isEqualArrays(a1, a2){
    
    if(!a1){
        a1 = []
    }
    if(!a2){
        a2 = []
    }
    let is_equal = true
    a1.forEach(function(a_value){
        if(!a2.includes(a_value)){
            is_equal = false
        }
    })
    
    a2.forEach(function(a_value){
        if(!a1.includes(a_value)){
            is_equal = false
        }
    })
    
    return is_equal
}