// IMPORTS
import React, {Component} from "react";
import FacebookLogin from 'react-facebook-login';

import facebook from "../../img/social/facebook.svg"
import google from "../../img/social/google.svg"

import {capitalizeFirstLetter} from "../../util/string"

import {GoogleConnect, k_google_scopes_contacts} from "../../models/social/google"
import {FacebookConnect} from "../../models/social/facebook"

// CONSTANTS
const button_type_login = 'login'
const button_type_friends = 'friends'

// CLASSES
export class SocialButton extends Component{

    static defaultProps = {'button_type': button_type_login, 'friends_str': 'friends'}

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    render(){

        let text = this.props.button_type == button_type_login ? "Continue with " : "Add " + this.props.friends_str + " from "
        text += capitalizeFirstLetter(this.props.title)
        return (<div className="SocialButton verticalMiddle centered" onClick={this.onClick}>
                    <div className="inlineBlock" style={{'paddingRight':'2vw'}}>
                        <img style={{'verticalAlign':'middle'}} src={this.socialImage()}/>
                    </div>
                    <div  className="inlineBlock">
                        {text}
                    </div>
                </div>)

    }
    onClick(){
        this.props.on_click_function()
    }

    socialImage(){
        switch(this.props.title){
            case 'google':
                return google
            case 'facebook':
                return facebook
        }
    }
}

export class GoogleButton extends Component{
    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
        this.state = {'button_ref': React.createRef()}
    }
    render(){
        return(
            <div style={{'position':'relative'}}>
                <SocialButton title='google' on_click_function={this.onClick} button_type={this.props.button_type} friends_str='contacts'/>
                <div id="google_button_hidden" ref={this.state.button_ref}
                style={{'position':'absolute','top':'1vw', 'opacity': 0.}}/>
            </div>
        )
    }
    componentDidMount(){

        let scopes
        let callback_function
        if(this.props.button_type == 'friends'){
            scopes = k_google_scopes_contacts
            callback_function = this.props.callback_function
        }
        GoogleConnect.createButton('google_button_hidden', scopes, callback_function)
    }
    onClick(){

        this.state.button_ref.current.click();

        if(this.props.button_type == 'friends'){
            GoogleConnect.hasClickedAuthorized()
        }

    }
}

export class FacebookButton extends Component{
    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
        this.state = {'button_ref': React.createRef()}
    }
    render(){

        let scope = "public_profile,email"
        let fields = "name,email,picture"
        let callback_function = FacebookConnect.parseUser
        if(this.props.button_type == "friends"){
            scope += ",user_friends"
            fields+= ",friends"
            callback_function = FacebookConnect.parseFriends
        }
        return(
            <div style={{'position':'relative'}}>
                <SocialButton title='facebook' on_click_function={this.onClick} button_type={this.props.button_type}/>
                <div id="facebook_button_hidden" style={{'position':'absolute','top':'1vw', 'opacity': 0., 'width': '100vw'}} ref={this.state.button_ref}>
                    <FacebookLogin
                        appId="324112753198143"
                        autoLoad={false}
                        fields={fields}
                        scope={scope}
                        callback={callback_function}
                        icon="fa-facebook"
                        cssClass="ButtonFacebook"
                        disableMobileRedirect={true}
                        redirectUri='https://wurd.reviews'
                        />
                </div>
            </div>
        )
    }
    componentDidMount(){

    }
    onClick(){
        // not used
        this.state.button_ref.current.click()
    }
}