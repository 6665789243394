// IMPORTS
import $ from 'jquery'

import {context} from '../util/global'
import {showConfirmation, loadingImage} from '../util/display'
import {setInnerHTMLForDiv} from '../util/div'
import {replaceAll} from '../util/string'
import { isWurdLoggedIn } from './user'
import {displayLoginPopup} from '../components/views/login'
import {Model, classes_dict} from './main'
import { Url } from '../util/server'
import { Topic } from './topic'

// CONSTANTS
const k_button_yes = 'Yes'
const k_button_no = 'No'

// CLASSES
export class Word extends Model{
	
	// Display
	select(){
		// not used, all in component?
		// count, is_selected
		if(this.is_selected_by_user){
			
			this.selected_count = this.selected_count - 1;
		
		}else{
			this.selected_count = this.selected_count + 1;
		}
		this.is_selected_by_user = !this.is_selected_by_user

		// url
		let url = new Url('qSelectWordWithGaeKey', 'word_gae_key', this.gae_key, 'topic_gae_key', this.topic_gae_key, 'author_gae_key', this.author_gae_key, 'text', escape(this.text))
		let a_word = this
		url.get(function(response){a_word.wasSelected(response)})

	}
	
	wasSelected(response){
		
		// Display follow?
		let results = response.results;
	    let selected_word = results;

		// Display delete?
	    if((selected_word.is_created_by_user) && (selected_word.selected_count == 0)){
	        showConfirmation('Do you want to delete ' + selected_word.text + '?', [k_button_yes, k_button_no], function(){selected_word.delete()})
	    }
	}
	
	delete(){
		// topic_div_id is necessary to refresh
		let url = new Url('qDeleteWordWithGaeKey', 'word_gae_key', this.gae_key)
		
		let a_word = this
		url.get(function(response){a_word.wasDeleted(response)})

    }
	wasDeleted(response){
		// topic_div_id is necessary to refresh
		if(response.error == undefined){
	
		}
	}
	
}

classes_dict['Word'] = Word

// CREATE
export function createWord(topic, word_text, callback_function = undefined) {

	new Url('qCreateWord', 'topic_gae_key', topic.gae_key, 'text', escape(word_text)).get(callback_function)

}

function flagWordWithGaeKey(word_gae_key){
	
	showConfirmation('Do you want to flag ' + Word.retrieve(word_gae_key).text + '?', [k_button_yes, k_button_no], function(){flagWordWithGaeKeyDelegate(word_gae_key)})
	
}
function flagWordWithGaeKeyDelegate(word_gae_key){
	let url = new Url('suFlagWordWithGaeKey', 'word_gae_key', word_gae_key)
	url.get()
}

// ACTIONS
function enterWord(topic_gae_key, topic_div_id) {

    if(!(isWurdLoggedIn())){
        displayLoginPopup();
    }else{
	
        let new_word_text = document.getElementById("wordsBar_" + topic_div_id).value;
        if(new_word_text === null || new_word_text.trim() === ""){
            alert('Please enter a valid word!');
        }
        else{
	
            let a_topic = Topic.retrieve(topic_gae_key);
            setInnerHTMLForDiv(loadingImage(a_topic.category_title), topic_div_id + "_reviews");

			let url = new Url('qCreateWord', 'topic_gae_key', topic_gae_key, 'text', escape(new_word_text))
			
			/* TODO
			let a_topic_cell = TopicCell.retrieve(topic_div_id)
			let callback_function = function(response){a_topic_cell.parseCreatedWord(response)}
			url.get(callback_function)
			*/
        }
    }
}

/*
function hasSelectedWord(response,word_div_id,topic_div_id) {
    
    var results = response.results;
    var selected_word = JSON.parse(results.word);
    var author = JSON.parse(results.author);
    var topic = JSON.parse(results.topic);
    if(selected_word.is_selected_by_user){
        selected_word.is_showing_author = !(author.is_friend_with_current_user);
    }else{selected_word.is_showing_author = false;
    }
    if(selected_word !== 'undefined'){models_dict[selected_word.gae_key] = selected_word;}
    if(author !== 'undefined'){models_dict[author.gae_key] = author;}
    if(topic !== 'undefined'){models_dict[topic.gae_key] = topic;}
    if(selected_word.is_showing_author){
        //setInnerHTMLForDiv(followText(author,word_div_id),word_div_id+'_author');
        setInnerHTMLForDiv(followText(author,selected_word.gae_key),"topic_word_author_" + selected_word.gae_key);
    }else{//setInnerHTMLForDiv('',word_div_id+'_author');
    }
    if((selected_word.is_created_by_user) && (selected_word.selected_count == 0)){
        setInnerHTMLForDiv(deleteText(selected_word.gae_key,topic_div_id),"topic_word_author_" + selected_word.gae_key,topic_div_id);
    }

}
*/
function deleteText(word_gae_key,topic_div_id){
    return "<a href='javascript:void();' onclick='deleteWord(&quot;" + word_gae_key + "&quot;)'>Delete word?</a>";
}
function followText(author,word_gae_key){
    var out_string = "<a href='javascript:void();' onclick='pressFollowButton(&quot;" + author.gae_key + "&quot;,&quot;" + word_gae_key + "&quot;,true)'>";
    //out_string += "Follow " + htmlSpace() + userPictureImgDivWithSizeAndMarginTop(author,'25px','8px') + htmlSpace() + " <span class='user_name'>" + userName(author) + "</span>?</a>";     
    //out_string += "Follow " + userPictureImgDivWithSizeAndMarginTop(author,'25px','-17px') + htmlSpace() + "</a>?";     
    return out_string;
}
function thanksFollowText(author,word_gae_key){
    //var out_string = "<a href='javascript:void()' onclick='showProfileSectionWithUserKey(&quot;" + author.gae_key + "&quot;)'>" + htmlSpace() + userPictureImgDivWithSizeAndMarginTop(author,'25px','8px') + htmlSpace() + " <span class='user_name'>" + userName(author) + "</span></a>.";     
    //var out_string = "Following <a href='javascript:void()' onclick='showProfileSectionWithUserKey(&quot;" + author.gae_key + "&quot;)'>" + htmlSpace() + userPictureImgDivWithSizeAndMarginTop(author,'25px','-17px') + htmlSpace() + "</a>.";     
    return "";
}
function friendText(a_user){
	/*
    var out_string = "<h5>";
    followButtonOn.user_follow_button = a_user.is_friend_with_current_user;

    out_string = out_string + buttonHtml("follow","user_follow_button",a_user.gae_key,30);
    if(!a_user.is_friend_with_current_user){
        out_string = out_string + " to follow.";
    }
    else{
        out_string = out_string + " to stop following.";
    }
    out_string = out_string + "</h5>";
    return out_string;
	*/
}
function deleteWord(word_gae_key){
	
	let a_word = Word.retrieve(word_gae_key)
    a_word.delete();
	
}
function selectWord(word_gae_key){

	// Called when selecting a word
    if(!(isWurdLoggedIn())){
	
        displayLoginPopup();

    } else {
	
		let a_word = Word.retrieve(word_gae_key)
        a_word.select();

	}
}
