// IMPORTS
import {Component} from "react"

// CLASSES
export class MenuTabs extends Component{

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)

        let selected_title = props.selected_title
        if(selected_title == undefined){
            selected_title = this.props.titles[0]
        }
        this.state = {'selected_title': selected_title,
                    }
    }
    render(){

        let buttons_components = []
        let that = this
        let count_titles = this.props.titles.length
        
        let idx = 0
        this.props.titles.forEach(function(title){

            let class_names = "MenuTabButton"
            let is_selected = title == that.state.selected_title

            let title_view
            if(that.props.views){
                title_view = that.props.views[idx]
            }
            else{
                title_view = title
            }
            
            class_names += is_selected ? " MenuTabButtonSelected":""
            buttons_components.push(
                <button key={title}
                        className={class_names}
                        style={{'width':89.67/count_titles + 'vw'}}
                        onClick={()=>that.onClick(title)}>
                    {title_view}
                </button>
            )

            idx += 1
        })

        return <div className="MenuTabContainer">
                    {buttons_components}
                </div>
    }
    onClick(title){
        this.setState({'selected_title': title})
        if(this.props.on_click_function){
            this.props.on_click_function(title)
        }
    }
}