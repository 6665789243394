// IMPORTS
import { loadScript } from "../../util/external";
import { User } from "../user";
import { vwToPx } from "../../util/display";

// https://developers.google.com/identity/oauth2/web/guides/overview

// CONSTANTS & GLOBALS
const k_google_api_key = 'AIzaSyA7Qljp5XL5PhYxtVvlFrwUZYdOkJTiDdI'
const k_google_client_id = '342759058770-se0rmo7fkrn2d77lvvj8t26e89mbv51h.apps.googleusercontent.com'
	
// Discovery doc URL for APIs used by the quickstart
const k_google_discovery_doc = 'https://www.googleapis.com/discovery/v1/apis/people/v1/rest';

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
export const k_google_scopes_contacts = 'https://www.googleapis.com/auth/contacts.readonly';

let google_token_client;
let google_gapi_inited = false;
let google_gis_inited = false;

// CLASSES
// User
export class GoogleUser{
	constructor(user_id = undefined, name = undefined, first_name = undefined, last_name = undefined, short_name = undefined, email = undefined, image_url = undefined, friends_list = undefined,
	social_access_token = undefined, social_access_token_expiry = undefined){
		
		this.social_account = 'google'
		this.user_id = user_id
		this.name = name
		this.first_name = first_name
		this.last_name = last_name
		this.short_name = short_name
		this.email = email
		this.social_access_token = social_access_token
		this.social_access_token_expiry = social_access_token_expiry
		this.image_url = image_url
		this.friends_list = friends_list
		
	}
}
export let google_user = new GoogleUser()
let client

// Connect
export class GoogleConnect{
	static createButton(div_id_hidden, scopes = undefined, callback_function = undefined){

		this.scopes = scopes
		
		if(scopes == undefined){ // auth button
		
	        // Set callback
			window.google.accounts.id.initialize({
			    client_id: k_google_client_id,
			    callback: function(response){GoogleConnect.parseHasAuthenticated(response, callback_function)},
			    auto_prompt:false,
			});

			// Set button
			window.google.accounts.id.renderButton(
			    document.getElementById(div_id_hidden),
			    { theme: "filled_black", size: "large", shape: "circle", width: vwToPx('100vw'), heigh: 100}  // customization attributes
			);
		}
		else{
			
			client = window.google.accounts.oauth2.initTokenClient({
	          	client_id: k_google_client_id,
	          	scope: scopes,
		        callback: function(response){GoogleConnect.parseHasAuthorized(response, callback_function)},
	        });
		}
	}
	
	static hasClickedAuthorized(){
		// Ask for contact permissions
		/*
		a_connection.client.callback = async (resp) => {
	    if (resp.error !== undefined) {
	        throw (resp);
	    }
	    	parseHasAuthorized(resp)
	    };
		*/
	    if (window.gapi.client.getToken() === null) {
	        // Prompt the user to select a Google Account and ask for consent to share their data
	        // when establishing a new session.
	    	client.requestAccessToken({prompt: 'consent'});
	    } else {
	        // Skip display of account chooser and consent dialog for an existing session.
	        client.requestAccessToken({prompt: ''});
	    }
		
	}
	
	static parseHasAuthenticated(response, callback_function = undefined){
		
		let token = response.credential
		response = parseJwt(token)
		
		// Google User
		google_user.user_id = response.sub
		google_user.name = response.name
		google_user.first_name = response.given_name
		google_user.last_name = response.family_name
		google_user.image_url = response.picture
		google_user.email = response.email
		google_user.social_access_token = token
		google_user.social_access_token_expiry = response.exp
		
		User.createWithSocialUser(google_user) // updates if not logged in
		//User.updateWithSocialUser(google_user)
		
	}
	static async parseHasAuthorized(response, callback_function = undefined){
		
		//await GoogleConnect.getUserProfile(response.access_token)
		GoogleConnect.getContactEmails(callback_function)
	}
	

	static async getUserProfile(){
		// not used
		let response;
	    try {
	      response = await window.gapi.client.people.people.get({
	        'resourceName': 'people/me',
	        'personFields': 'metadata,emailAddresses,names,photos'
	      });
	    } catch (err) {
	      return;
	    }
	    
	    response = response.result
	    
	    // Google User
		google_user.user_id = response.metadata.sources[0].id
		google_user.name = response.names[0].displayName
		google_user.first_name = response.names[0].givenName
		google_user.last_name = response.names[0].familyName
		google_user.image_url = response.photos[0].url
		google_user.email = response.emailAddresses[0].value
		google_user.social_access_token = undefined
		google_user.social_access_token_expiry = undefined

	}
	
	static async getContactEmails(callback_function = undefined){

	    let response;
	    try {
	      response = await window.gapi.client.people.people.connections.list({
	        'resourceName': 'people/me',
	        'pageSize': 1000,
	        'personFields': 'names,emailAddresses',
	      });
	    } catch (err) {
	      return;
	    }
	    const connections = response.result.connections;
	    if (!connections || connections.length == 0) {
	      return;
	    }
	    // Flatten to string to display
	    let emails = []
	    connections.forEach(function(person){
			if (!(!person.emailAddresses || person.emailAddresses.length === 0)){
				person.emailAddresses.forEach(function(an_email){
					emails.push(an_email.value)	
				})	            
	        }
		})
	    
	    // Send emails
		if(callback_function != undefined){
			callback_function(emails)
		}

		/*
	    google_user.friends_list = emails
	    
	    User.updateFriendsWithSocialUser(google_user)
		*/
	    
	}
}

// Init
function googleGapiLoaded() {
    window.gapi.load('client', intializeGapiClient);
}
function googleGisLoaded() {

	/*
    google_token_client = google.accounts.oauth2.initTokenClient({
    	client_id: k_google_client_id,
    	scope: k_google_scopes_contacts,
    	callback: '', // defined later
    });
    */
}     
async function intializeGapiClient(){
	
	await window.gapi.client.init({
        apiKey: k_google_api_key,
    	discoveryDocs: [k_google_discovery_doc],
    });
}
  
// Util
function parseJwt(token){
	
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

// SCRIPTS
loadScript("https://accounts.google.com/gsi/client", googleGisLoaded)
loadScript("https://apis.google.com/js/api.js", googleGapiLoaded)