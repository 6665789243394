// IMPORTS
import {Component} from 'react'

import { ViewTopic } from '../views/topic'
import { ActivityHeartButton,
    ActivityBombButton,
    ActivityBookmarkButton,
    ActivityTrashButton } from '../generic/button'

import { ViewRecommend } from "../views/recommend";

import {MainContext} from '../generic/context'
import { vhToPx, vwToPx } from '../../util/display'

import { Deck } from './deck'
import {context} from '../../util/global'

import { Topic } from '../../models/topic';

import {showValidation} from '../generic/modal';

import { Url } from '../../util/server';

import {User} from '../../models/user'

import share from '../../img/icons/share.svg'
import { ViewFriends } from '../views/friends';
import { ViewProfile } from '../views/profile';
import { k_modal_type_default} from '../generic/modal';

// import { AutoPlaySilentVideo } from '../views/loading';
// import gold from '../../img/animations/gold.mp4'

let is_requesting = false
let topics_keys_showed = []

// CLASSES
export class Swiper extends Component{

    static contextType = MainContext;

    constructor(props){

        super(props)
        this.state = {'topics': props.topics,
                    'bookmarks': [],
                    'recommendations': [],
                    'top_card_index': 0,
                    'discard_direction': undefined,
                    } // to trigger card discard
        this.onClickTopic = this.onClickTopic.bind(this)
        this.onTriggered = this.onTriggered.bind(this)
        this.onPan = this.onPan.bind(this)
        this.parseNewTopics = this.parseNewTopics.bind(this)
        this.onCardChange = this.onCardChange.bind(this)
        this.onAnimationEnd = this.onAnimationEnd.bind(this)
        this.onCloseTopic = this.onCloseTopic.bind(this)
        this.showRecommendInfo = this.showRecommendInfo.bind(this)
        this.undoLastActivity = this.undoLastActivity.bind(this)
        this.showValidation = this.showValidation.bind(this)
        this.parseBookmarks = this.parseBookmarks.bind(this)
        this.parseFriends = this.parseFriends.bind(this)
        this.parseBookmarksOrFriends = this.parseBookmarksOrFriends.bind(this)
        this.onCloseNotification = this.onCloseNotification.bind(this)

    }
    render(){

        let that = this

        // Load bookmarks and recommendations
        if(this.state.topics){
            if(!context.has_loaded_bookmarks_recommendations){
                // Get bookmarks and recommendations
                new Url('qRecommend', 'content', 'bookmark').get(this.parseBookmarks)
                new Url('qRecommend', 'content', 'friends', 'add_user_activity', true).get(this.parseFriends)
                context.has_loaded_bookmarks_recommendations = true
            }
        }

        return(
            <div>
                
                <Deck
                    card_change_function = {this.onCardChange}
                    top_card_index = {this.state.top_card_index}
                    topics={[...this.state.topics]}
                    on_click_function={this.onClickTopic}
                    on_trigger_function={this.onTriggered}
                    discard_direction={this.state.discard_direction}
                    animation_ended_function={this.onAnimationEnd}
                    validation_function={this.showValidation}
                    />

                {/* <div style={{'minHeight':vhToPx('100vh'),'margin-top':'0','zIndex':-1, 'position': 'absolute'}}>
                    <AutoPlaySilentVideo video={gold} style={{'width':'100%'}}/>
                </div> */}
                
                <div className="SwipeIconsLargeContainer"
                    style={{'width':vwToPx('100vw'),
                            'top':vhToPx('76vh'),
                      }}>
                    <div className="SwipeIconsContainer SwipeIconsContainerStyle"
                        style={{'width':vhToPx('38.524vh'),
                        'height': vhToPx('9.2446vh'),
                        }}>
                        <ActivityBombButton on_click_function={(activity_score) => that.onTriggered('dislike', activity_score)} info_function = {(activity_score) => that.showValidation('dislike', activity_score)}/>
                        <ActivityTrashButton on_click_function={(activity_score) => that.onTriggered('trash', activity_score)} info_function = {(activity_score) => that.showValidation('trash', activity_score)}/>
                        <ActivityBookmarkButton on_click_function={(activity_score) => that.onTriggered('bookmark', activity_score)} info_function = {(activity_score) => that.showValidation('bookmark', activity_score)}/>
                        <ActivityHeartButton on_click_function={(activity_score) => that.onTriggered('like', activity_score)} info_function = {(activity_score) => that.showValidation('like', activity_score)}/>
                    </div>
                </div>
            </div>
        )
    }

    onAnimationEnd(){
        // Beware: called multiple times
        if(this.state.discard_direction == undefined){
            return
        }
        
        this.setState({'discard_direction': undefined,
                        'top_card_index': this.state.top_card_index + 1,
                        },)
        
        
    }
    onClickTopic(){

        let topic = this.state.topics[this.state.top_card_index]

        if(topic.is_notification){
            if(topic.title == "friends"){
                this.context.app_ref.current.showModal(<ViewFriends/>, 0.05, false, this.onCloseNotification)
            }
            else if(topic.title == "picture"){
                this.context.app_ref.current.showModal(<ViewProfile user={context.user} is_hidden={false} is_set_picture={true}/>, 0.05, false, undefined, k_modal_type_default, false)
            }
        }
        else{

            let topic_view = <ViewTopic topic={topic}/>
            this.context.app_ref.current.showModal(topic_view, .5, false, this.onCloseTopic)
        }

    }
    onCloseTopic(){
        // on Topic modal close
        let topic = Topic.retrieve(this.state.topics[this.state.top_card_index].gae_key) // get latest version
        
        if(topic.user_activity){
            this.setState({'discard_direction': topic.user_activity})
        }

        ViewTopic.onCloseModal()
    }
    onCloseNotification(){
        this.setState({'discard_direction': "trash"})
    }
    onCardChange(top_card_index){
        this.setState({'top_card_index': top_card_index})
    }
    onTriggered(activity_type, activity_score = 1){

        // if requesting more topics, wait
        if(is_requesting){
            return
        }
        
        let idx = this.state.top_card_index
        let a_topic = this.state.topics[idx] // assumed to be in sync with Deck

        // if notification, just discard
        if(a_topic.is_notification){
            this.setState({'discard_direction': activity_type})
            return
        }

        if(activity_type == 'like'){
            this.showRecommendInfo()
        }

        // send request
        let remaining_topics_count_after_swipe = this.state.topics.length-idx-1
        let top_count = 5 - remaining_topics_count_after_swipe
        
        if(top_count > 0){

            a_topic.recommendWithActivity(activity_type,
                                        activity_score,
                                        top_count,
                                        this.context,
                                        this.state.topics.slice(idx),
                                        this.parseNewTopics)

            is_requesting = true
        }

        this.setState({'discard_direction': activity_type}) // , ()=>this.setState({'discard_direction': undefined})
        
        if(activity_type == 'bookmark'){
            context.needs_reload['bookmarks'] = true
        }
        if(activity_type == 'like'){
            context.needs_reload['profile'] = true
        }
        if(activity_type == 'dislike'){
            context.needs_reload['profile'] = true
        }
    }
    parseNewTopics(response){

        is_requesting = false

        // Add bookmarks and recommendations
        let topics = []
        let bookmarks_remaining = [...this.state.bookmarks]
        let recommendations_remaining =[]
        
        this.state.recommendations.forEach((recommendation) => {
            if(recommendation.user_activity == ""){
                recommendations_remaining.push(recommendation)
            }
        })

        response.results.topics.forEach((topic, index) => {

            let rand = Math.random()
            
            if(index + this.state.topics.length > this.state.top_card_index + 1){ // not first 2 cards
                if(bookmarks_remaining.length > 0){
                    if(rand < 0.1){
                        
                        let topic_bookmark = bookmarks_remaining.pop()
                        topic_bookmark.is_bookmark = true

                        if((topic_bookmark.user_activity != "like") && (topic_bookmark.user_activity != "dislike")){
                            topics.push(topic_bookmark)
                        }
                    }
                }
                if(recommendations_remaining.length > 0){
                    if((rand < 0.4) && (rand > 0.1)){
                        
                        let topic_recommendation = recommendations_remaining.pop()
                        topic_recommendation.is_recommendation = true

                        if((topic_recommendation.user_activity != "like") && (topic_recommendation.user_activity != "dislike")){
                            topics.push(topic_recommendation)
                        }
                    }
                }
                
                if(context.access_token != undefined){
                    if(context.user.image_url == ""){
                        if((rand > 0.4) && rand < 0.5){
                            topics.push(new Topic({title: "picture", is_notification: true, 'gae_key': Math.random()}))
                        }
                    }
                
                    if(!context.has_friends){
                        if((rand > 0.5) && rand < 0.6){
                            topics.push(new Topic({title: "friends", is_notification: true, 'gae_key': Math.random()}))
                        }
                
                    }
                }
            }

            if (!topics_keys_showed.includes(topic.gae_key)){
                topics.push(topic)   
                topics_keys_showed.push(topic.gae_key)
            }
        })

        // Set state
        this.setState({'topics': this.state.topics.concat(topics),
                        'bookmarks': bookmarks_remaining,
                        'recommendations': recommendations_remaining,
        })
    }
    parseBookmarksOrFriends(response, state_key, is_bookmark = false, is_recommendation = false){
        // Parse topics
        let topics = response.results.topics
        topics.forEach(function(a_topic){
            if(a_topic.top_friend_gae_key){
                a_topic.user_liked = User.retrieve(a_topic.top_friend_gae_key)
            }
            a_topic.is_bookmark = is_bookmark
            a_topic.is_recommendation = is_recommendation
        })

        // Update state
        let state = {}
        state[state_key] = topics
        this.setState(state)
    }
    parseBookmarks(response){
        this.parseBookmarksOrFriends(response, 'bookmarks', true, false)
    }
    parseFriends(response){
        this.parseBookmarksOrFriends(response, 'recommendations', false, true)
    }
    onPan(event){

    }

    showRecommendInfo(){
        
        let a_topic = this.state.topics[this.state.top_card_index]
        let topic_title = a_topic.title
        const max_length = 12
        if(topic_title.length > max_length){
            topic_title = topic_title.substring(0,max_length) + '...'
        }

        let that = this
        let on_click_function = function(){
            that.context.app_ref.current.showModal(<ViewRecommend topic={a_topic}/>, 0.05)
        }

        this.context.app_ref.current.showInfo(<div className="ModalViewInfoContent">
                        <div className="ModalViewInfoText inlineBlock">Recommend <span className="purpleColor">{topic_title}</span> to friends!</div>
                        <div className="ModalViewInfoImage inlineBlock"><img src={share} width={vwToPx('7vw')}/></div>
                    </div>,
                    on_click_function)
    }

    showValidation(activity_type, activity_score){
        showValidation(this.state.topics[this.state.top_card_index], activity_type, activity_score, this.context.app_ref, false)

    }

    undoLastActivity(a_topic){
    }

}