// IMPORTS
import React, { Component } from "react";
import $ from "jquery"

import {ImagePicker} from "../generic/upload"

import {capitalizeFirstLetter} from '../../util/string'
import {vwToPx} from '../../util/display'
import avatarblob from '../../img/avatar/blob.svg'
import { MainContext } from "../generic/context";
import { context } from "../../util/global";

import image_default from "../../img/empty/profile.png"
import image_selected from "../../img/avatar/selected.svg"
import image_unselected from "../../img/avatar/unselected.svg"

// CONSTANTS
export const k_avatar_shape_circle = 'circle'
export const k_avatar_shape_blob = 'blob'

// CLASSES
export class Avatar extends Component{

    static contextType = MainContext
    static defaultProps = {
        'shape': k_avatar_shape_blob,
        'size': '100px',
        'is_disabled': false,
        'is_selected': false,
        'is_selectable': false,
    }
    constructor(props){

        super(props)

        let image_ref = React.createRef()
        let picker_ref
        if(this.props.is_pickable){
            picker_ref = React.createRef()
        }

        this.onClickImage = this.onClickImage.bind(this)
        this.state = {'image_src': undefined,
                        'image_ref': image_ref,
                        'picker_ref': picker_ref,
                        }

    }
    onClickImage(){
        if(this.props.is_pickable){
            if(this.state.picker_ref){
                $('#' + this.state.picker_ref.current.inputID()).trigger('click')
            }
        }
    }
    render(){

        let image_size = vwToPx(this.props.size)
        let image_size_scaled = vwToPx(this.props.size.replace('vw', '') * 1.35 + 'vw')
        let image_component
        
        let image_src = this.state.image_src
        if(image_src == undefined){
            image_src = this.props.user.image_url
        }
        if((image_src == "") | (image_src == undefined)){
            image_src = image_default
        }
        
        let selectable_component
        if(this.props.is_selectable){
            selectable_component = <div style={{'zIndex':-1, 'position':'absolute'}}>
                                        <div style={{'top':'-1.3vw', 'left': '-3.4vw', 'position': 'relative'}}>
                                            <img
                                                src={this.props.is_selected?image_selected:image_unselected}
                                                width={image_size_scaled}
                                                
                                            />
                                        </div>
                                    </div>
        }
        
        if(this.props.shape == k_avatar_shape_circle){

            image_component = 
                            <div>
                                {selectable_component}
                                <div 
                                    style={{'width': image_size, 'height': image_size}}
                                    className="AvatarImageContainerCircleImage">
                                    <div>
                                        <img className = "AvatarImage"
                                            src={image_src}
                                            height={image_size}
                                            width={image_size}
                                            ref={this.state.image_ref}
                                            onClick={this.onClickImage}
                                        />
                                    </div>
                                </div>
                            </div>
        }
        else{

            let svg_size = 100; // svg is scaled to 100px
            let svg_scale = image_size.replace('px', '') / svg_size

            image_component = <svg width={image_size} height={image_size}>
                                    <defs>
                                        <clipPath id="svgPath" >
                                            {/*
                                            <path d="M 96.5261
                                                    39.4989
                                                    C 96.5261
                                                    61.5903
                                                    69.6168
                                                    90.9998
                                                    47.5254
                                                    90.9998
                                                    C 25.434
                                                    90.9998
                                                    0.525391
                                                    71.0913
                                                    0.525391
                                                    48.9999
                                                    C -0.974306
                                                    16.9999
                                                    31.0261
                                                    -2.00175
                                                    59.0254
                                                    0.499155
                                                    C 81.1168
                                                    0.499155
                                                    96.5261
                                                    17.4075
                                                    96.5261
                                                    39.4989 Z" fill="blue"/>
                                            */}
                                            <path d={"M " + 96.5261*svg_scale
                                            + " " + 39.4989*svg_scale
                                            + " C " + 96.5261*svg_scale
                                            + " " + 61.5903*svg_scale
                                            + " " + 69.6168*svg_scale
                                            + " " + 90.9998*svg_scale
                                            + " " + 47.5254*svg_scale
                                            + " " + 90.9998*svg_scale
                                            + " C " + 25.434*svg_scale
                                            + " " + 90.9998*svg_scale
                                            + " " + 0.525391*svg_scale
                                            + " " + 71.0913*svg_scale
                                            + " " + 0.525391*svg_scale
                                            + " " + 48.9999*svg_scale
                                            + " C -" + 0.974306*svg_scale
                                            + " " + 16.9999*svg_scale
                                            + " " + 31.0261*svg_scale
                                            + " -" + 2.00175*svg_scale
                                            + " " + 59.0254*svg_scale
                                            + " " + 0.499155*svg_scale
                                            + " C " + 81.1168*svg_scale
                                            + " " + 0.499155*svg_scale
                                            + " " + 96.5261*svg_scale
                                            + " " + 17.4075*svg_scale
                                            + " " + 96.5261*svg_scale
                                            + " " + 39.4989*svg_scale
                                            + " Z"
                                            }/>
                                        </clipPath>
                                    </defs>
                                    <foreignObject clipPath="url(#svgPath)" width="100%" height="100%">
                                        <img src={image_src} className="AvatarImage"  width={image_size} height={image_size} ref={this.state.image_ref} onClick={this.onClickImage}/>
                                    </foreignObject>
                                </svg>
        }

        let picker_component
        if((this.props.user.gae_key == context.user.gae_key)
            & (this.props.is_pickable)){
            // Picker
            picker_component = <ImagePicker
                                ref={this.state.picker_ref}
                                image_ref={this.state.image_ref}
                                upload_url={this.props.user.image_upload_url}/>
        }

        let classes_names = "inlineBlock transparent AvatarImageContainer" + capitalizeFirstLetter(this.props.shape)
        if(this.props.is_disabled){
            classes_names += " grayText"
        }
        return (<div className={classes_names} style={{'width': image_size, 'height': image_size}}>
                    {image_component}
                    {picker_component}
                </div>)
    }
}