// IMPORTS
import { Component } from "react";
import React from "react";

import {arrayNoDupe} from "../../util/array"

import {sleep} from "../../util/generic"

import search from "../../img/icons/search.svg"
import x from "../../img/icons/x.svg"
import trash from "../../img/icons/trash.svg"

// CONSTANTS
const k_autocomplete_delay = 300

// CLASSES
export class SearchBar extends Component{

    constructor(props){

        super(props)

        let input_ref = React.createRef()
        this.state = {'is_selected': false,
                        'has_text': false,
                        'input_ref': input_ref,
                    }
        this.onFocus = this.onFocus.bind(this)
        this.onBlur = this.onBlur.bind(this)
        this.onChange = this.onChange.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.onEnter = this.onEnter.bind(this)
        
    }
    static defaultProps = {
        'placeholder': 'Search'
    }
    render(){
        
        let classes_names_icon
        if(this.state.is_selected){
            classes_names_icon = "SearchBarIconSelected"
        }
        else{
            classes_names_icon = "SearchBarIcon"
        }

        let classes_names_box = "SearchBarBox"
        if(this.state.is_selected){
            classes_names_box += " SearchBarBoxSelected"
        }

        let classes_names_x = "SearchBarX hidden"
        if(this.state.has_text){
            classes_names_x = "SearchBarX"
        }

        return(
            <div className="SearchBar">
                <img className={classes_names_icon} src={search}/>
                <input ref={this.state.input_ref}
                        className={classes_names_box}
                        type="search"
                        name="search"
                        placeholder={this.props.placeholder}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        onKeyDown={this.onEnter}
                        autoComplete="off"
                        value={this.state.override_value}
                        id="search_input"/>
                <button className={classes_names_x} onClick={this.clearValue}>
                    <img className="SearchBarXIcon" src={x}/>
                </button>

            </div>
        )
    }

    clearValue(){
        this.setState({'override_value': ''},
            () =>this.setState({'override_value': undefined})
            )
        if(this.props.clear_function != undefined){
            this.props.clear_function()
        }
    }

    // Autocomplete
    onChange(event){
        
        if(this.props.autocomplete_function != undefined){
            this.props.autocomplete_function(event.target.value)
        }

        if(event.target.value != ""){
            this.setState({'has_text': true})
        }
        else{
            this.setState({'has_text': false})
        }
    }
    onEnter(event){

        if(this.props.enter_function){
            if (event.key === 'Enter') {
                this.props.enter_function()
                this.state.input_ref.current.blur()
            }
        }
    }
    static autocompleteParseValue(value){

        value = value.toLowerCase()
		value = value.replace("'s", "s").replace('& ', '')
		
		return value
    }
    setAutocompleteList(source_array){
		
		if(source_array !== undefined){

            // source_array is a list
            source_array = arrayNoDupe(source_array)
            
            for (let i=0; i<source_array.length; i++) {
                source_array[i] = SearchBar.autocompleteParseValue(source_array[i])
            }
			
		}
        else{
            source_array = []
        }

		this.autocomplete_list = source_array

	}
    
    autocomplete(text){
		
		let search_bar = this	  	
	  	sleep(k_autocomplete_delay, function(){search_bar.validateAutocomplete(text)})
	  	
	}
	
	validateAutocomplete(text){

		if(text == this.text){
			this.autocomplete_function(text);
		}
	}

    // Events
    onFocus(){

        this.setState({'is_selected': true})

        if(this.props.onFocus != undefined){
            this.props.onFocus()
        }
    }

    onBlur(){
        this.setState({'is_selected': false})
    }
}
  
function _aucompleteValidKeyCode(keycode){
    if (keycode >= 48){
        if (keycode <= 90){
            return true
        }
    }
    if(keycode == 8){
        return true
    }
    
    return false
  }