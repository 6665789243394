// IMPORTS
import { getCookie } from "./cookies";

// CONSTANTS
export let context = {}
context.server_root = "https://wurd.reviews/";
context.client_root = context.server_root;

context.div_unique = 0;
context.is_loading = false;
context.needs_reload = {'swipe': false, 'bookmarks': false, 'feed': false, 'profile': false}
context.is_topic_search = false;
context.is_desktop = undefined;
context.category_title = getCookie('category_title', 'movies')
context.platforms = getCookie('platforms', ['netflix'])
context.platforms_available = {'movies': [], 'tv': []}
context.genres = getCookie('genres', [])
context.access_token = getCookie('access_token')
context.has_new_recommendations = false
context.is_default_user = false
context.has_loaded_bookmarks_recommendations = false
context.has_friends = false

//context.recent_activity_str;
//context.user_reviews_str;
context.user_has_notifications = false;

context.needs_my_profile_reload = false
context.profile_is_set = false;

// SENT BY SERVER
//context.server_current_user;

// SHARE TOKEN
export function urlVariableValue(variable_name){
	
	const query_string = window.location.search
	const url_params = new URLSearchParams(query_string)
	
	if(url_params.has(variable_name)){
		return url_params.get(variable_name)
	}
	else{
		return undefined
	}
}
function shareTokenKeyReceived(){
	
	let share_token_key = urlVariableValue('s')
	if(share_token_key == undefined){
		share_token_key = urlVariableValue('share_token_key')
	}
	return share_token_key
}

context.share_token_key_received = shareTokenKeyReceived() // ShareToken received by a friend
context.share_token_key_send = undefined // to send to friends
console.log('   share token:', context.share_token_key)