// IMPORTS
import React, {useState, useEffect, useContext} from 'react'
import { MainContext } from '../generic/context'
import { useSprings, animated, to as interpolate } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'
// https://use-gesture.netlify.app/docs/state

import { pxToVw, vhToPx, vwToPx } from '../../util/display'

import { Card } from './card'
import { SwipeButtonLike, SwipeButtonDislike, SwipeButtonBookmark, SwipeButtonTrash } from './button'
import { showValidation } from '../generic/modal'

// CONSTANTS
const max_duration_click = 200 // ms
const min_opacity_background = 0.001
const z_index_icons_container = 1000 // from SwipeIconsLargeContainer
const z_index_above = 1500

// UTIL
// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = (i) => ({
    x: 0,
    y: i==0?0:parseFloat(vhToPx('-5vh').replaceAll('px', '')),
    scale: i==0?1:0.9,
    rot: 0, // -10 + Math.random() * 20
    zIndex: 5000000, // -i
    delay: i * 100,
  })
  const from = (i) => ({ x: 0, rot: 0, scale: 0.95, y: 0, zIndex: -i, })
  // This is being used down there in the view, it interpolates rotation and scale into a css transform
  const trans = (r, s) =>
    `rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`  // perspective(1500px) rotateX(30deg) 

// COMPONENTS
export function Deck(props) {

    let topics = props.topics

    let context = useContext(MainContext)

    const discard_direction = props.discard_direction // force discard from parent
    
    const top_card_index = props.top_card_index
    const [active_for, setActiveFor] = useState(0.)
    let is_triggered = false
    const [dark_screen_opacity, setDarkScreenOpacity] = useState(min_opacity_background)
    const [selected_button, setSelectedButton] = useState(undefined)
    let [activity_score, setActivityScore] = useState(0)
    const [z_index, setZIndex] = useState(0)
    let [timeouts, setTimeouts] = useState(undefined)

    // Original display animation
    const [spring_props, api] = useSprings(topics.length, i => ({
      ...to(i),
      from: from(i),
      onRest() {props.animation_ended_function()},
    })) // Create a bunch of springs using the helpers above
    
    // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
    //const drag_options = {'bounds': {'top': -100, 'bottom': 100, 'left':-100, 'right':100 }}
    
    const bind = useGesture({
        
        onDrag: ({ args: [index], active, movement: [offset_x, offset_y], velocity: [velocity_x, velocity_y], elapsedTime }) => {
            // offset_x: > 0 or < 0 pixels moved
            // direction_x: 1, 0 or -1 based on x direction

            // timer
            setActiveFor(elapsedTime)

            // defines destination x, rot, scale etc. of animation    
            api.start(i => {
              
              if (index !== i) return // We're only interested in changing spring-data for the current spring
              
              if(dark_screen_opacity == min_opacity_background){
                if(active_for > max_duration_click){
                  setDarkScreenOpacity(1.)
                }
              }  

              if(active){
                setZIndex(z_index_above)
              }
              else{
                setZIndex(z_index_icons_container-1-i)
              }
              let offset_x_vw = pxToVw(offset_x)
              let offset_y_vw = pxToVw(offset_y)
              
              const is_triggered_x = isTrigger(offset_x_vw, velocity_x, i, top_card_index, active)
              const is_triggered_y = isTrigger(offset_y_vw, velocity_y, i, top_card_index, active)

              is_triggered = is_triggered_x | is_triggered_y

              let x_to
              let y_to
              if(!is_triggered){

                if(active){ // dragging
                  x_to = offset_x
                  y_to = offset_y
                }
                else{ // back to origin
                  x_to = 0
                  y_to = 0
                }
              }
              else{ // triggering
                if(!active){ // trigger
                  if(is_triggered_x){
                    x_to = (200 + window.innerWidth) * Math.sign(offset_x)
                    y_to = 0
                  }
                  else if(is_triggered_y){
                    x_to = 0
                    y_to = (200 + window.innerHeight) * Math.sign(offset_y)
                  }
                }
                
              }
              const rotation_to = !active & !is_triggered_x ? 0 : offset_x_vw / 100 + (is_triggered_x ? Math.sign(offset_x) * 10 * velocity_x : 0) // How much the card tilts, flicking it harder makes it rotate faster
              const scale_to = active ? 1.05 : 1 // Active cards lift up a bit

              if(is_triggered){

                let activity_type
                if(is_triggered_x){
                  if(offset_x > 0){ // right
                    activity_type = 'like'
                  }
                  else{ // left
                    activity_type = 'dislike'
                  }
                }
                else{
                  if(offset_y > 0){ // bottom
                    activity_type = 'trash'
                  }
                  else{ // top
                    activity_type = 'bookmark'
                  }
                }
                setSelectedButton(activity_type)
                
                if(activity_type != selected_button){
                  
                  const show_validation_function = function(increment = 1){

                    activity_score += increment
                    setActivityScore(activity_score)
                    showValidation(topics[top_card_index], activity_type, activity_score, context.app_ref, true)

                  }

                  const show_validation_function_with_timeout = function(){

                    let timeouts = []
                    show_validation_function();
                    timeouts.push(setTimeout(show_validation_function, 750))
                    timeouts.push(setTimeout(show_validation_function, 1500))
                    // timeouts.push(setTimeout(()=>show_validation_function(-1), 2250))
                    // timeouts.push(setTimeout(()=>show_validation_function(-1), 3000))

                    return timeouts

                  }

                  // show validation
                  timeouts = show_validation_function_with_timeout()
                  setTimeouts(timeouts)
                  
                }
                //setTimeout(()=>setActivityScore(activity_score + 1), 750)

                if(!active){

                    props.on_trigger_function(activity_type, activity_score)
                    if(timeouts){
                      timeouts.forEach(function(a_timeout){
                        clearTimeout(a_timeout)
                      })
                      setTimeouts(undefined)
    
                    }

                }

              }
              else{

                setSelectedButton(undefined)
                setActivityScore(0)

                if(selected_button){
                  context.app_ref.current.hideValidationModals()
                }

                if(timeouts){
                  timeouts.forEach(function(a_timeout){
                    clearTimeout(a_timeout)
                  })
                  setTimeouts(undefined)

                }
                
              }

              return {
                x: x_to,
                y: y_to,
                rot: rotation_to,
                scale: scale_to,
                delay: undefined,
                config: { friction: 50,
                        tension: active ? 800 : is_triggered_x ? 200 : 500,},
              }
            })
          
          },
          /*drag: drag_options,*/
          onDragEnd({ args: [index]}){

            setDarkScreenOpacity(0.001)
            api.start(i => {
                
              if (index !== i) return // We're only interested in changing spring-data for the current spring
              //return {zIndex: -i}
              setZIndex(z_index_icons_container-1-i)

            })
          }
          
        
      })
    
    // Pass index to parent
    useEffect(() => {props.card_change_function(top_card_index)}, [top_card_index])

    // Discard?
    if(discard_direction != undefined){
      api.start(i => {
          if(i == top_card_index){
            if(discard_direction == 'like'){
              return {
                x:parseFloat(vwToPx('120vw').replaceAll('px', ''))
              }
            }
            if(discard_direction == 'dislike'){
              return {
                x:-parseFloat(vwToPx('120vw').replaceAll('px', ''))
              }
            }
            if(discard_direction == 'bookmark'){
              return {
                y:-parseFloat(vhToPx('100vh').replaceAll('px', ''))
              }
            }
            if(discard_direction == 'trash'){
              return {
                y:parseFloat(vhToPx('100vh').replaceAll('px', ''))
              }
            }
          }
          if(i == top_card_index + 1){ // next card
            return{y:0.,
                  scale:1.}
          }
      })
      
    }

    // Animated divs
    let map_function = function({ x, y, rot, scale }, i){

      if(i < top_card_index - 1){
        return undefined
      }

      let is_above = i<=top_card_index
      let class_names_container = "SwipeCardContainer " + (i<top_card_index-1 ? "hidden" : "")
      return(
          <animated.div className="SwipeDeck" style={{zIndex:is_above ? z_index : -i, width:vhToPx('45.57vh')}} key={topics[i].gae_key}>
            <animated.div className={class_names_container} style={{'x':x,'y':y}}>
              {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
              <animated.div className="SwipeCard" //{is_above ? "SwipeCard" : "SwipeCard SwipeCardBehind"}

                {...bind(i)} // i is passed to args as index
                onClick={()=>onClick(active_for, is_triggered, props.on_click_function)}
                style={{
                  transform: interpolate([rot, scale], trans),
                  width: vhToPx('45.57vh'),

                  /*
                  width: is_above ? vhToPx('45.57vh') : vhToPx('42.47vh'),
                  marginLeft: is_above ? 0 : vhToPx('1.4vh'),
                  */
                }}
              >
                <Card topic={topics[i]} is_bookmark={topics[i].is_bookmark} is_recommendation={topics[i].is_recommendation} rand={Math.random()}/>
                  
              </animated.div>
            </animated.div>
          </animated.div>
      )
    }

    // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
    return (
      <div style={{'zIndex': 0}}>
        
        {spring_props.map(({ x, y, rot, scale }, i) => map_function({ x, y, rot, scale }, i))
        }

        <div className = "SwipeDarkScreen" style={{'opacity': dark_screen_opacity, 'zIndex': z_index_icons_container + 1}}/>
        <SwipeButtonLike is_selected={selected_button=='like'} z_index={1500} opacity={dark_screen_opacity}/>
        <SwipeButtonDislike is_selected={selected_button=='dislike'} z_index={1500} opacity={dark_screen_opacity}/>
        <SwipeButtonBookmark is_selected={selected_button=='bookmark'} z_index={1500} opacity={dark_screen_opacity}/>
        <SwipeButtonTrash is_selected={selected_button=='trash'} z_index={1500} opacity={dark_screen_opacity}/>
      </div>  
    )
    
  }

function onClick(active_for, is_triggered, on_click_function){
  // also called when releasing drag => check x,y
  if((active_for > max_duration_click) | is_triggered){
    return
  }
  on_click_function()

}

function isTrigger(offset_vw, velocity, i, top_card_index){
    if(i > top_card_index){
      return false
    }
    if(Math.abs(offset_vw) > 25){
      return true
    }
    /*
    if(velocity > 0.3){
      return true
    }
    */
    return false
}