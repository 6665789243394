// IMPORTS
import { Component } from "react";

import { BlobTop } from "../background/blob";
import { Avatar } from "../users/avatar";
import { ActivityCellsList } from "../users/activity";
import { ViewLoading } from "./loading";

import { Url } from "../../util/server";
import { MainContext } from "../generic/context";
import { context } from "../../util/global";
import { EmptyFeed } from "../generic/empty";

import { vhToPx } from "../../util/display";
import { MenuTop } from "../menu/top";
import { FollowButton } from "../users/friends";
import { Scroller } from "../../models/scrolling";

// CONSTANTS
const scroller_id = "scroller_profile"

// CLASSES
export class ViewProfile extends Component{

    static contextType = MainContext
    static defaultProps = {'is_set_picture': false,
                            'is_hidden':true}
    constructor(props){
        
        super(props)
        let activities = this.props.activities
        if(activities == undefined){
            activities = []
        }
        this.state = {
                        'is_hidden': this.props.is_hidden,
                        'user': this.props.user,
                        'activities': activities,
                    }
        this.parseUser = this.parseUser.bind(this)
        this.onFirstLoad = this.onFirstLoad.bind(this)

        let that = this

        let url_get
        if(props.user){
            url_get = new Url('qGetUserProfile', 'user_gae_key', this.props.user.gae_key)
        }
        else{
            url_get = new Url('qGetUserProfile') // current user
        }
        this.scroller = new Scroller(scroller_id, url_get, that.parseUser)

    }
    render(){
        
        if(!this.props.is_loaded_on_mount){
            if(this.state.is_hidden){
                return <ViewLoading/>
            }
        }
        if(this.state.user == undefined){
            return <div></div>
        }

        let is_me = this.state.user.gae_key == context.user.gae_key

        let activity_component
        if(!this.props.is_set_picture){
            if(this.state.activities.length > 0){
                activity_component = <ActivityCellsList activities={this.state.activities}/>
            }
            else{
                activity_component = <EmptyFeed/>
            }
        }

        let tap_avatar
        if(is_me){
            if(this.props.user){
                if((this.state.user.image_url == "") | this.props.is_set_picture){
                    tap_avatar = <div className="grayText textCenter">Tap your avatar to upload a picture</div>
                }
            }
        }
        let follow_component
        if(!this.props.is_set_picture){
            if(!is_me){
                follow_component = <div><FollowButton user={this.state.user} class_names="ButtonProfileFollow"/><br/><br/></div>
            }
        }

        let menu_component
        if(!this.props.is_set_picture){
            if(!this.props.is_hidden_button){
                menu_component = <MenuTop buttons={['friends', 'settings']}/>
            }
        }

        let stats_component
        if(!this.props.is_set_picture){
            stats_component = <ViewStats user={this.state.user}/>
        }

        if(activity_component){
            activity_component = <div className="ContainerActivity">
                                    <div className="noMargin" style={{'marginBottom':'5vw'}}>
                                        {this.state.user.pseudo}'s activity.
                                    </div>
                                    <br/>
                                    {activity_component}
                                </div>
        }
        else{
            activity_component = <div className="ContainerActivity"><br/>Upload your picture so your friends know it's you!</div>
        }

        return(
            <div className="ContainerMain ContainerMainBlue"
                    style={{'minHeight': vhToPx('100vh')}}
                    id={scroller_id}
                    >
                <BlobTop/>
                {menu_component}
                
                <div className="ContainerProfile">
                    
                    <div className="ContainerProfileUser">
                        <div className="ContainerProfileAvatar">
                            <Avatar user={this.state.user} shape='blob' is_pickable={is_me}/>
                        </div>
                        <div className="ContainerProfileUserName">
                            {this.state.user.pseudo}
                        </div>
                        {tap_avatar}

                        {stats_component}

                        {follow_component}

                    </div>
                    <br/>
                    <br/>
                
                </div>

                <div className="ContainerProfileActivity">
                    {activity_component}
                </div>
                
            </div>
        )
    }

    componentDidMount(){
        if(this.props.is_loaded_on_mount){
            if(this.props.user != undefined){
                new Url('qGetUserProfile', 'user_gae_key', this.props.user.gae_key).get(this.parseUser)
            }
        }
    }
    onFirstLoad(){
        if(!this.props.is_loaded_on_mount){
            if(!this.context.app_ref.current.checkIfLogin()){
                this.setState({'is_hidden': false})
                return
            }

            // Get profile
            this.scroller.get()
        }
    }
    parseUser(response){
        
        let activities = response.results.activities
        activities = this.state.activities.concat(activities)
        
        if(response.results.author != undefined){
            this.setState({
                'activities': activities,
                'user': response.results.author,
                'is_hidden': false})
        }
        else{
            this.setState({
                'activities': activities,
            })
            this.context.app_ref.current.setUser(response.user, response.access_token)
        }
    }

}

class ViewStats extends Component{
    render(){
        return (
            <div className="ContainerProfileStats">
                <div className="ContainerProfileStatsCell">
                    <div className="ContainerProfileStatsCellNumber">
                        {this.props.user.count_follower}
                    </div>
                    <div className="ContainerProfileStatsCellText">
                        following
                    </div>
                </div>
                <div className="ContainerProfileStatsCell">
                    <div className="ContainerProfileStatsCellNumber">
                    {this.props.user.count_leader}
                    </div>
                    <div className="ContainerProfileStatsCellText">
                        followers
                    </div>
                </div>
                <div className="ContainerProfileStatsCell">
                    <div className="ContainerProfileStatsCellNumber">
                    {this.props.user.count_create_words}
                    </div>
                    <div className="ContainerProfileStatsCellText">
                        reviews
                    </div>
                </div>
                <div className="ContainerProfileStatsCell">
                    <div className="ContainerProfileStatsCellNumber">
                    {this.props.user.count_select_words}
                    </div>
                    <div className="ContainerProfileStatsCellText">
                        upvotes
                    </div>
                </div>
            </div>
        
        )
    }
}