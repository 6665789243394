// IMPORTS
import { Component } from "react";

import heart from "../../img/icons/heart.gif"
import bomb from "../../img/icons/bomb.gif"
import trash from "../../img/icons/trash.svg"
import bookmark from "../../img/icons/bookmark.svg"

import { vhToPx, vwToPx } from "../../util/display";
import { BlobPurpleGreen1 } from "../background/blob";
import { BlobPurpleGreen2 } from "../background/blob";
import { BlobPurpleGreen3 } from "../background/blob";
import { BlobTopLeft } from "../background/blob";
import { BlobBottomLeft } from "../background/blob";

// CLASSES
export class ViewInfo extends Component{
    render(){

        let img_size = vwToPx('10vw')
        return (<div className="ContainerMain ContainerMainBlack" style={{'minHeight': vhToPx('100vh')}}>
                    
                    <div className='ContainerH1'>
                        <br/>
                        <br/>
                        <h1>Tell us what you like, we'll tell you what to watch!</h1>
                    </div>
                    <br/>
                    <div className="ContainerContent" style={{'marginBottom':vhToPx('15vh')}}>
                        
                        <h2 className="ContainerInfo">
                            You saw it and you:
                        </h2>
                        <br/>
                        <div>
                            <div className="inlineBlock imageCenter"><img src={heart} width={img_size}/></div>
                            <div className="inlineBlock ContainerInfo">{"\u00a0\u00a0"} liked it</div> 
                        </div>
                        <div>
                            <div className="inlineBlock imageCenter"><img src={bomb} width={img_size}/></div>
                            <div className="inlineBlock ContainerInfo">{"\u00a0\u00a0"} did not like it</div> 
                        </div>
                        <br/>
                        <br/>
                        <h2 className="ContainerInfo">
                            You didn't see it and you:
                        </h2>
                        <br/>
                        <div>
                            <div className="inlineBlock imageCenter"><img src={bookmark} width={img_size}/></div>
                            <div className="inlineBlock ContainerInfo">{"\u00a0\u00a0"} are interested</div> 
                        </div>
                        <div>
                            <div className="inlineBlock imageCenter"><img src={trash} width={img_size}/></div>
                            <div className="inlineBlock ContainerInfo">{"\u00a0\u00a0"} are not interested</div> 
                        </div>

                        Got it thanks!
                        Stop showing this

                    </div>

                </div>)
    }
}