// IMPORTS
import React from "react";
import { getCookie } from "../../util/cookies";

// CONSTANTS
export const MainContext = React.createContext({'app_ref':undefined,
                                                'user': undefined,
                                                'access_token': undefined,
                                                'activities_recently_liked': undefined,
                                                'has_new_recommendations': false});
