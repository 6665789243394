// IMPORTS
import { Component } from "react";

import search from "../../img/empty/search.svg"
import bookmarks from "../../img/empty/bookmarks.svg"
import profile from "../../img/empty/profile.png"
import feed from "../../img/empty/feed.svg"
import followers from "../../img/empty/followers.svg"

import { vwToPx } from "../../util/display";

import { Avatar } from "../users/avatar";
import {context} from "../../util/global"

// CLASSES
class Empty extends Component{
    render(){
        return <div className="Empty">
                    <div>
                        <img src={this.props.src}
                            width={vwToPx('25vw')}
                            height={vwToPx('25vw')}/>
                    </div>
                    <div>
                        {this.props.text}
                    </div>
                </div>
    }

}

export class EmptySearch extends Component{
    render(){
        return <div>
            <Empty src={search} text = "Oops, we didn't find anything..."/>
        </div>
    }
}

export class EmptyBookmarks extends Component{
    render(){
        return <div>
            <Empty src={bookmarks} text = "No bookmark yet..."/>
        </div>
    }
}

export class EmptyRecommendations extends Component{
    render(){
        return <div>
            <Empty src={bookmarks} text = "No friends' recommendation yet..."/>
        </div>
    }
}

export class EmptyFeed extends Component{
    render(){
        return <div>
            <Empty src={feed} text = "No activity yet..."/>
        </div>
    }
}

export class EmptyFriends extends Component{
    render(){
        return <div className="centered">
                    <div>
                        <img src={followers}
                            width={vwToPx('25vw')}
                            height={vwToPx('25vw')}/>
                    </div>
                    <div>
                    &nbsp;&nbsp;&nbsp;Tap to add!
                    </div>
                </div>
    }
}

export class EmptyPicture extends Component{
    render(){
        return <div className="centered">
                    
                    <Avatar user={context.user}/>
                    <div>
                    &nbsp;&nbsp;&nbsp;{'Tap to set!'}
                    </div>
                
            </div>
    }
}