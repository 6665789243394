// IMPORTS
import {Model} from './main'

import { Topic } from "./topic";
import { User } from "./user";
import { Word } from "./word";

// CLASSES
class Activity extends Model{

}

// FUNCTIONS
export function activityWithActivity(activity){
    if(activity.user == undefined){
        activity.user = User.retrieve(activity.user_gae_key)
    }
    if(activity.topic == undefined){
        activity.topic = Topic.retrieve(activity.target_gae_key)
    }
    if(activity.word == undefined){
        if(activity.target_secondary_gae_key != undefined){
            activity.word = Word.retrieve(activity.target_secondary_gae_key)
        }
    }

    return activity
}

export function activityGroupsWithActivities(activities_list){
    
    if(activities_list == undefined){
        return []
    }

    // group words by user & topic
    let activities_groups = []
    let key
    const activities_likes = ['like', 'dislike']
    activities_list.forEach(an_activity => {

        an_activity = activityWithActivity(an_activity)

        if(an_activity.user){

            key = an_activity.user.gae_key + an_activity.topic.gae_key
            if(activities_groups[key] == undefined){

                activities_groups[key] = {'activity_type': undefined,
                                            'activities_list': [],
                                            'user':User.retrieve(an_activity.user.gae_key),
                                            'topic':Topic.retrieve(an_activity.topic.gae_key),
                                            'score': 1,
                                            'words': []}
            }

            activities_groups[key]['activities_list'].push(an_activity)

            if(activities_likes.includes(an_activity.activity_type)){
                activities_groups[key]['activity_type'] = an_activity.activity_type
                activities_groups[key]['score'] = an_activity.score
            }
            else if(an_activity.activity_type == 'select_word'){
                activities_groups[key]['words'].push(an_activity.word)
            }
        }

    });

    return activities_groups
}