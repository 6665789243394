// IMPORTS
import './App.css';
import React, {Component} from 'react'

import { MenuBottom, BookmarksCell, MoviesCell, FeedCell, ProfileCell, RecommendationsCell } from './components/menu/bottom';
import {ViewLogin} from './components/views/login'
import {ViewProfile} from './components/views/profile'
import {ViewFeed} from './components/views/feed'
import {ViewSwipe} from './components/views/swipe'
import {ViewBookmarks} from './components/views/bookmarks'
import { ViewSharedRecommendation } from './components/views/recommend';
import { ViewInfo } from './components/views/info';

import { ModalView, k_modal_type_default, k_modal_type_info, k_modal_type_validation,
       } from './components/generic/modal';

import {MainContext} from './components/generic/context'
import { context, urlVariableValue } from './util/global';

import {Alert} from './components/generic/alert'

import { getCookie, setCookie } from './util/cookies';
import { getUrlValue } from './util/server';
import { ViewTopic } from './components/views/topic';
import { ViewDesktop } from './components/views/desktop';
import { isDesktop } from './util/generic';
import { WordCell } from './components/words/cell';


// CLASSES
class App extends Component {
  
  constructor(props){
      super(props)
      this.state = {'modal_views': [], // 'modal_ref': React.createRef()
                    'modal_validation_refs': [],
                    'app_ref': this.props.reference,
                    'user': undefined,
                    'access_token': getCookie('access_token', undefined),
                    'menu_bottom_ref': React.createRef(),
                    }

      this.showModal = this.showModal.bind(this)
      this.showAlert = this.showAlert.bind(this)
      this.onTest = this.onTest.bind(this)
      this.setHasNewRecommendations = this.setHasNewRecommendations.bind(this)
      this.hideValidationModals = this.hideValidationModals.bind(this)
      this.showValidation = this.showValidation.bind(this)
  }

  render(){

    // Desktop
    if(isDesktop()){
      return <ViewDesktop/>
    }

    let home_cell = <MoviesCell view={<ViewSwipe/>} title = 'movies' is_selected={true}/>
    let feed_cell = <FeedCell view={<ViewFeed/>} title = 'feed'/>
    let bookmarks_cell = <BookmarksCell view = {<ViewBookmarks/>} title = 'bookmarks'/>
    //let recommendations_cell = <RecommendationsCell view = {<ViewRecommendations/>} title = 'recs'/>
    let profile_cell = <ProfileCell view = {<ViewProfile/>} title = 'profile'/>

    let modal_views = []
    let idx = 0
    let a_ref
    let idm = 0
    let that = this
    this.state.modal_views.forEach(function(modal_view){
        
      a_ref = React.createRef()
      
      idm+=1
      modal_views.push(
                  <ModalView children = {modal_view.view}
                            key = {idx}
                            ref = {a_ref}
                            is_hidden = {modal_view.is_hidden}
                            back_button_opacity = {modal_view.back_button_opacity}
                            show_full_screen = {modal_view.show_full_screen}
                            on_close_function = {modal_view.on_close_function}
                            modal_type = {modal_view.modal_type}
                            z_index = {modal_view.z_index}
                          
                    />
      )

      modal_view.ref = a_ref
      if(modal_view.modal_type == k_modal_type_validation){
        that.state.modal_validation_refs.push(a_ref)
      }

      idx += 1

    })
    /*
  
      <div>
        <MainContext.Provider value={{'app_ref': this.state.app_ref,
              'activities_recently_liked': undefined,
              'has_new_recommendations': false,
          }}> 
          <MenuBottom ref={this.state.menu_bottom_ref} cells={[home_cell, feed_cell, bookmarks_cell, profile_cell]}/>
          {modal_views}
          
      </MainContext.Provider>
      </div>
    */

    return (
          
      <div>
        <MainContext.Provider value={{'app_ref': this.state.app_ref,
              'activities_recently_liked': undefined,
              'has_new_recommendations': false,
              'is_default_user': false,
          }}> 
          <MenuBottom ref={this.state.menu_bottom_ref} cells={[home_cell, feed_cell, bookmarks_cell, profile_cell]}/>
          {modal_views}
          
      </MainContext.Provider>
      </div>
        
      )
    }
    showModal(view, back_button_opacity = undefined,    
                  show_full_screen = false,
                  on_close_function = undefined,
                  modal_type = undefined,
                  is_hidden = true,
                  z_index = undefined){

        let modal_views = [...this.state.modal_views]
        
        modal_views.push({'view':view,
                        'is_hidden': is_hidden,
                        'back_button_opacity': back_button_opacity,
                        'show_full_screen': show_full_screen,
                        'on_close_function': on_close_function,
                        'modal_type': modal_type,
                        'z_index': z_index,
                        })
        
        this.setState({'modal_views':modal_views})

    }
    closeModal(){
      this.state.modal_views[this.state.modal_views.length-1].ref.current.hide()
    }
    showInfo(view, on_close_function = undefined){
      this.showModal(view, undefined, false, on_close_function, k_modal_type_info, false)
    }
    showValidation(view, on_close_function = undefined){

      this.hideValidationModals()
          
      this.showModal(view, undefined, false, on_close_function, k_modal_type_validation, false)
    }
    hideValidationModals(){

      this.state.modal_validation_refs.forEach(function(a_ref){
        if(a_ref.current){
            if(!a_ref.current.state.is_hidden){
              a_ref.current.hide()
            }
        }
      })
    }
    onTest(value){
      console.log(value)
    }
    showAlert(value){
        let view = <Alert value={value}/>
        this.showModal(view, 0.05, true)
    }
    componentDidMount(){

      if(isDesktop()){
        return
      }

      // Update access token
      let access_token = getUrlValue('a')
      if(access_token != undefined){
        setCookie('access_token', access_token)
      }

      // Show modal
      //this.showModal(<ViewInfo/>, 0.05, false, undefined, k_modal_type_default, false, 1000000)
      let page = getUrlValue('p')
      if(page == 'reset'){
        this.showModal(<ViewLogin is_reset_password={true}/>, 0.05, false, undefined, k_modal_type_default, false, 1000000)
      }

      // If topic, show topic
      let topic_gae_key = getUrlValue('t')
      if(topic_gae_key != undefined){
        this.showModal(<ViewTopic topic={{'gae_key': topic_gae_key}}/>, 0.5, false, ViewTopic.onCloseModal)
      }

    }
    componentDidUpdate(){
      
      if(this.state.modal_views){
        this.state.modal_views.forEach(function(a_modal_view){
          if(a_modal_view.ref.current){
            if(!a_modal_view.ref.current.state.is_loaded){
              a_modal_view.ref.current.setState({'is_hidden': false, 'is_loaded': true})
            }
          }
        })
        
      }
    }

    setUser(user, access_token, share_token_send = undefined, has_new_recommendations = undefined,
          share_token_received = undefined, is_default_user = undefined, has_friends = undefined){
        
      context.user = user
      context.access_token = access_token

      if(is_default_user != undefined){
        context.is_default_user = is_default_user
      }
      if(has_friends != undefined){
        context.has_friends = has_friends
      }
      
      setCookie('access_token', access_token)

      if(has_new_recommendations != undefined){
        this.setHasNewRecommendations(has_new_recommendations)
      }

      if(share_token_received != undefined){

        // Remove existing modals
        let that = this
        this.state.modal_views.forEach(function(a_modal_view){
          that.closeModal()
        })

        // Show
        this.showModal(<ViewSharedRecommendation topic={share_token_received.topic} user_from={share_token_received.user}/>,
        0.05,
        )
      }

      if(share_token_send != undefined){
        /*if(urlVariableValue('s') == undefined){*/
          context.share_token_key_send = share_token_send.short_key
          window.history.replaceState({}, '', context.server_root + "?s=" + context.share_token_key_send)
        /*}*/
      }

    }

    setHasNewRecommendations(has_new_recommendations){

      this.state.menu_bottom_ref.current.setState({'has_new_recommendations': has_new_recommendations})
      this.state.menu_bottom_ref.current.state.views_ref[2].current.setState({'has_new_recommendations': has_new_recommendations})

    }

    checkIfLogin(show_modal = true){

      let access_token_cookie = getCookie('access_token')
      if((access_token_cookie !== undefined) & (access_token_cookie != '')){
        return true
      }
      else{
        if(show_modal){
          this.showModal(<ViewLogin/>, 0.05, true)
        }
        
      }
      return false
    }
}

export default App;