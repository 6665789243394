// IMPORTS
import { Component } from "react";

import { Url } from "../../util/server";
import { MainContext } from "../generic/context";
import { vhToPx } from "../../util/display";

import { UsersCells } from "../users/cell";

import { FacebookButton } from "../social/button";
import { GoogleButton } from "../social/button";

import { SearchBar } from "../generic/search";

import { context } from "../../util/global";
import { hideKeyboard } from "../../util/display";
import { ViewProfile } from "./profile";

import {follow_stack} from "../../models/friends"
import { PersonnalizedLink } from "./recommend";
import { SeparationOr } from "../generic/text";

import friends from "../../img/icons/friendswhite.svg"

// CONSTANTS
const k_autocomplete_url = context.server_root + '/q/?q=qSearchUsers&text='
const k_autocomplete_min_length = 3

// CLASSES
export class ViewFriends extends Component{

    static contextType = MainContext
    constructor(props){
        
        super(props)

        this.state = {'friends_current': [],
                    'friends_recommended': undefined,
                    'menu': 'Current',
                    'is_searching': false,

                    // list of autocomplete options, received from server, and their gae keys
                    'autocomplete_list': [],
                    'autocomplete_friends': [],
        
                    // last server search
                    'autocomplete_server_last_title': undefined,
        
                    // current search value
                    'autocomplete_search_title': '',
        
                    // status
                    'is_searching': false,
                    }
        
        this.parseCurrentFriends = this.parseCurrentFriends.bind(this)
        this.parseRecommendedFriends = this.parseRecommendedFriends.bind(this)
        this.onMenuTabs = this.onMenuTabs.bind(this)
        this.searchHasChanged = this.searchHasChanged.bind(this)
        this.searchBarDidEnter = this.searchBarDidEnter.bind(this)
        this.serverMatch = this.serverMatch.bind(this)
        this.parseSearch = this.parseSearch.bind(this)
        this.parseAutocomplete = this.parseAutocomplete.bind(this)
        this.parseAutocompleteWithResponse = this.parseAutocompleteWithResponse.bind(this)
        this.onClickFriend = this.onClickFriend.bind(this)
        this.parseGoogleContacts = this.parseGoogleContacts.bind(this)

    }
    render(){

        let friends_list = this.state.menu=='Current' ? this.state.friends_current : this.state.friends_recommended

        if(this.state.autocomplete_friends != undefined){

            let add_friend = true
            this.state.autocomplete_friends.forEach(function(a_friend){
                friends_list.forEach(function(current_friend){
                    if(current_friend.gae_key == a_friend.gae_key){
                        add_friend = false
                    }
                })
                if(add_friend){
                    friends_list.push(a_friend)
                }
            })
        }

        let friends_filtered = []
        let search_friend = this.state.autocomplete_search_title
        
        if(search_friend != ''){

            let reg = new RegExp(SearchBar.autocompleteParseValue(search_friend))
            for (let i=0; i<friends_list.length; i++) {

                if(friends_list[i].pseudo){
                    if (SearchBar.autocompleteParseValue(friends_list[i].email).match(reg)) {
                        friends_filtered.push(friends_list[i])
                    }   
                }
            }
        }
        else{
            friends_filtered = friends_list
        }

        let link_component = <div className="ContainerContent"><PersonnalizedLink share_token_key={context.share_token_key_send} has_separation={false}/></div>

        return(
            <div className="ContainerMain ContainerMainBlue" style={{'minHeight': vhToPx('100vh')}}>

                <div className='ContainerH1'>
                    <h1>Your friends</h1>
                </div>
                {link_component}
                <div className="ContainerContent">
                    <SeparationOr opacity={0.5} margin_bottom={'-6vw'}/>
                </div>
                {/*
                <div style={{'width': '91.67vw', 'paddingLeft': '4.16vw'}}>
                    <GoogleButton button_type='friends' callback_function={this.parseGoogleContacts}/>
                    <FacebookButton button_type='friends'/>
                </div>
                <div className="ContainerContent">
                    <SeparationOr opacity={0.5} margin_bottom={'-6vw'}/>
                </div>
                */}
                {/*<MenuTabs titles={['Current', 'Recommended']} on_click_function={this.onMenuTabs}/>*/}
                
                <div className="ContainerContent" style={{'marginBottom':vhToPx('15vh')}}>
                    <SearchBar placeholder="Search users by email"
                                autocomplete_function={this.searchHasChanged}
                                clear_function={()=>this.searchHasChanged('')}/>
                    <br/><br/>
                    <UsersCells users={friends_filtered} on_click_function={this.onClickFriend} is_show_email={true}/>
                </div>
            </div>
        )
    }

    componentDidMount(){

        // Get friends
        new Url('qGetCurrentFriends', 'limit', 15).get(this.parseCurrentFriends)

    }

    static onClose(){
        follow_stack.send()
    }

    onMenuTabs(title){
        if(title == 'Recommended'){
            if(this.state.friends_recommended == undefined){
                new Url('qGetRecommendedFriends', 'limit', 15).get(this.parseRecommendedFriends)
            }
        }
        this.setState({'menu': title})
    }

    parseCurrentFriends(response){

        let friends_current = response.results
        friends_current.forEach(function(user){
            user.is_followed=true
        })
        this.setState({'friends_current': response.results})
    }
    parseRecommendedFriends(response){
        this.setState({'friends_recommended': response.results})
    }

    parseGoogleContacts(emails){

        let that = this
        new Url('qGetUsersWithEmails', 'emails', emails).get(
            function(response){

                let friends = []
                response.results.forEach(function(a_friend){
                    if(a_friend.gae_key != context.user.gae_key){
                        friends.push(a_friend)
                    }
                })
                that.setState({'friends_current': friends})
            }
        )
    }

    onClickFriend(user){
        this.context.app_ref.current.showModal(<ViewProfile user={user} is_loaded_on_mount={true} is_hidden_button={true}/>, 0.05, true)
    }

    // Search
    searchBarDidEnter(){ // actual search when no topic?
        hideKeyboard()
    }

    parseSearch(response){
    }
	
    // Autocomplete
    parseAutocompleteWithResponse(response, title = undefined){

        let autocomplete_list = []
        let autocomplete_friends = []
        response.results.forEach(function(a_result){
            if(a_result.gae_key != context.user.gae_key){
                autocomplete_list.push(a_result.email)
                autocomplete_friends.push(a_result)
            }
        })

		this.parseAutocomplete(autocomplete_list, autocomplete_friends, title)
    }
    parseAutocomplete(autocomplete_titles, autocomplete_friends, title=undefined){

		this.setState({'autocomplete_list': autocomplete_titles,
                        'autocomplete_friends': autocomplete_friends,
                        'autocomplete_server_last_title': title,
                        'is_searching': false})
 		
	}

    searchHasChanged(title){

        let search_view = this

        // new title is server title + characters
        this.setState({'autocomplete_search_title': title})
        
        if(title.includes(search_view.state.autocomplete_server_last_title)){
            return
        }

        // title is too short, no autocomplete
        if(title.length < k_autocomplete_min_length){
            return search_view.parseAutocomplete([], [], undefined)
        }

        return search_view.serverMatch(title)
    }
	
	serverMatch(title){
        if(this.state.is_searching){
            return
        }
        this.setState({'is_searching': true})
        let search_view = this

        let parse_autocomplete_function = function(response){search_view.parseAutocompleteWithResponse(response, title)}

		Url.simpleGet(k_autocomplete_url + title, parse_autocomplete_function, undefined, 'jsonp')
	}
	
	static _autocompleteParse(value){
		
		value = value.toLowerCase()
		value = value.replace("'s", "s").replace('& ', '')
		
		return value
		
	}
}

export class ViewAddFriends extends Component{

    static contextType = MainContext
    constructor(props){

        super(props)

        this.onClick = this.onClick.bind(this)

    }

    render(){

        let image_component = <span onClick={this.onClick}>
                                    <img className="imageCenter" src={friends}/>
                                </span>

        if(this.props.is_long){
            return <span>You don't follow anyone. Find people to follow by tapping {image_component}</span>
        }
        else{
            return <span>Tap {image_component} to add friends.</span>
        }
    }

    onClick(){
        this.context.app_ref.current.showModal(<ViewFriends/>, 0.05, false, ViewFriends.onClose)
    }
}