// IMPORTS
import React, {Component} from "react";

import {FacebookButton, GoogleButton} from "../social/button"
import {BlobTopLeft, BlobBottomLeft, BlobPurpleGreen1, BlobPurpleGreen2, BlobPurpleGreen3} from "../background/blob"
import {MenuTop} from "../menu/top";
import { ModalView } from "../generic/modal";
import { SeparationOr } from "../generic/text";

import { Url } from "../../util/server";
import { setCookie } from "../../util/cookies";
import { MainContext } from "../generic/context";

import { vhToPx } from "../../util/display";
import { userHasLoggedin } from "../../models/user";

// CLASSES
export class ViewLogin extends Component{
    
    static contextType = MainContext
    constructor(props){
        super(props)

        this.onClickEmailButton = this.onClickEmailButton.bind(this)
        this.onClickLoginButton = this.onClickLoginButton.bind(this)
        this.onClickSignupButton = this.onClickSignupButton.bind(this)
        this.onChangeEmail = this.onChangeEmail.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
        this.onChangePseudo = this.onChangePseudo.bind(this)
        this.hasCheckedEmail = this.hasCheckedEmail.bind(this)
        this.hasLoggedIn = this.hasLoggedIn.bind(this)
        this.onForgotPassword = this.onForgotPassword.bind(this)
        this.onResetPassword = this.onResetPassword.bind(this)

        this.state = {'email': undefined,
                        'password': undefined,
                        'pseudo': undefined,
                        'login_ref': React.createRef(),
                        'signup_ref': React.createRef(),
                        'is_forgot_password': false,
                        'is_password_reset': false, // after password is reset
                        }
    }

    render(){
        
        // Forgot password
        if(this.state.is_forgot_password){
            return <ViewLoginTemplate>
                        <div style={{'paddingBottom':'8.53vw'}}>
                            <h1>
                                    Oh well...
                            </h1>
                        </div>
                        <div>
                            <h2>
                                We just sent you an email to reset your password!
                            </h2>
                        </div>
                    </ViewLoginTemplate>
        }

        // Reset password
        //  was just reset
        if(this.state.is_password_reset){
            return <ViewLoginTemplate>
                        <div style={{'paddingBottom':'8.53vw'}}>
                            <h1>
                                Reset your password
                            </h1>
                        </div>
                        <div>
                            <h2>
                                Thanks, your password was reset!
                            </h2>
                        </div>
                    </ViewLoginTemplate>
        }
        //  needs resetting
        if(this.props.is_reset_password){
            return <ViewLoginTemplate>
                    <div style={{'paddingBottom':'8.53vw'}}>
                            <h1>
                                    Reset your password
                            </h1>
                        </div>
                        <div>
                                <form onSubmit={this.onResetPassword}>
                                    <input type="password" placeholder="Enter your new password" className='Input'
                                            onChange={this.onChangePassword}/>
                                </form>
                            </div>
            </ViewLoginTemplate>
        }

        // Login
        let login_view = (<ViewLoginTemplate>
                            <div style={{'paddingBottom':'8.53vw'}}>
                                <h1>
                                        Welcome back!
                                </h1>
                            </div>
                            <div>
                                <form onSubmit={this.onClickLoginButton}>
                                    <input type="password" placeholder="Enter your password" className='Input'
                                            onChange={this.onChangePassword}/>
                                </form>
                                <div className="purpleColor" onClick={this.onForgotPassword} style={{'top':'-7vw','position':'relative', 'float': 'right'}}>
                                    Forgot password?
                                </div>
                                <br/>
                                <br/>
                            </div>
                            

                            <div>
                                <button className="ButtonLoginEmail" onClick={this.onClickLoginButton}>
                                    Login
                                </button>
                            </div>

                        </ViewLoginTemplate>)

        // Signup
        let signup_view = (<ViewLoginTemplate>
                                <div style={{'paddingBottom':'8.53vw'}}>
                                    <h1>
                                            Create a new account!
                                    </h1>
                                </div>
                                <div>
                                    <form onSubmit={this.onClickSignupButton}>
                                        <input type="text" placeholder="Pick a pseudo" className='Input'
                                                onChange={this.onChangePseudo}/>
                                    </form>
                                </div>
                                <div>
                                    <form onSubmit={this.onClickSignupButton}>
                                        <input type="password" placeholder="Enter your password" className='Input'
                                                onChange={this.onChangePassword}/>
                                    </form>
                                </div>
                                <div>
                                    <button className="ButtonLoginEmail" onClick={this.onClickSignupButton}>
                                        Create account
                                    </button>
                                </div>
                            </ViewLoginTemplate>)

        return (
            <div>
                <ViewLoginTemplate>
                    <div style={{'paddingBottom':'8.53vw'}}>
                        <h1>
                                Log in or sign up.
                        </h1>
                    </div>
                    <div className="ContainerSocialButtons">
                        
                        <FacebookButton/>
                        <GoogleButton/>

                    </div>
                    <SeparationOr/>
                    <div>
                        <form onSubmit={this.onClickEmailButton}>
                            <input type="email" placeholder="Enter your email" className='Input'
                                    onChange={this.onChangeEmail}/>
                        </form>
                    </div>
                    <div>
                        <button className="ButtonLoginEmail" onClick={this.onClickEmailButton}>
                            Continue with email
                        </button>

                    </div>
                    
                </ViewLoginTemplate>

                {/* LOGIN */}
                <ModalView ref={this.state.login_ref} show_full_screen={true}>
                    {login_view}
                </ModalView>

                {/* SIGN UP */}
                <ModalView ref={this.state.signup_ref} show_full_screen={true}>
                    {signup_view}
                </ModalView>
            </div>
          );
    }

    shouldComponentUpdate(nextProps, nextState) { 
        
        if (nextState.email !== this.state.email) { 
            // updating email
          return false;
        }
        else if (nextState.password !== this.state.password) { 
            // updating email
          return false;
        }
        else if (nextState.pseudo !== this.state.pseudo) { 
            // updating email
          return false;
        }
        return true;
      }

    onFirstLoad(){
        // Get activity
    }

    onChangeEmail(event){
        this.setState({'email': event.target.value})
    }
    onChangePassword(event){
        this.setState({'password': event.target.value})
    }
    onChangePseudo(event){
        this.setState({'pseudo': event.target.value})
    }
    onClickEmailButton(event){
        
        event.preventDefault()
        new Url("qCheckEmail",
					'email', this.state.email,
					).get(this.hasCheckedEmail)
        
    }
    hasCheckedEmail(response){

        if(response.results){
            // email exists
            this.state.login_ref.current.show()
        }
        else{
            // email doesn't exist
            this.state.signup_ref.current.show()
        }
    }

    onClickLoginButton(event){

        event.preventDefault()
        if(!this.checkValid('email')){return}
        if(!this.checkValid('password')){return}

        // Same url for sign up and login
        let url = new Url("qCreateUserWithEmailAndPassword",
                    'pseudo', this.state.pseudo,
					'password', this.state.password,
					'email', this.state.email,
					)

        url.get(this.hasLoggedIn);
    }

    onClickSignupButton(event){

        event.preventDefault()
        if(!this.checkValid('pseudo')){return}

        this.onClickLoginButton(event)

    }

    hasLoggedIn(response){
        userHasLoggedin(response)
    }

    checkValid(field){
        if((this.state[field] == undefined) || (this.state[field] == "")){
            this.context.app_ref.current.showAlert('Please fill your ' + field)
            return false
        }
        return true
    }
    onForgotPassword(){
        this.setState({'is_forgot_password': true})
        new Url('qForgotPassword', 'email', this.state.email).get()
    }
    onResetPassword(event){

        event.preventDefault()
        new Url('qResetPassword', 'password', this.state.password).get()
        this.setState({'is_password_reset': true})
        this.context.app_ref.current.closeModal()
        
    }
}

export function displayLoginPopup(){
    // TODO
}

class ViewLoginTemplate extends Component{
    render(){
        return (<div className="ContainerMain" style={{'minHeight': vhToPx('100vh')}}>
                    
                    <BlobPurpleGreen2/>
                
                    <MenuTop/>

                    <div className="ContainerLoginBottom">
                        {this.props.children}
                    </div>
                </div>)
    }
}