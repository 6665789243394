// IMPORTS
import {Component} from "react"

import { BlobTopLeft2 } from "../background/blob"
import { BlobTop } from "../background/blob"
import { MenuBottom } from "../menu/bottom"
import { MenuTop } from "../menu/top"
import { ViewLoading } from "./loading"

import {Swiper} from "../topics/swipe"

import { getUrlValue, Url } from "../../util/server"

import { MainContext } from "../generic/context"
import { context } from "../../util/global"

import { getCookie, setCookie } from "../../util/cookies"

import {isEqualArrays} from "../../util/array"

import { vhToPx } from "../../util/display"
import { User } from "../../models/user"

import { context as globalcontext } from "../../util/global"

import { Topic } from "../../models/topic"

// CLASSES
export class ViewSwipe extends Component{

    static contextType = MainContext
    constructor(props){
        super(props)
        this.state = {'is_hidden': true,
                        'topics': props.topics,
                    }
        this.parseSwipeTopics = this.parseSwipeTopics.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)

    }
    render(){

        if(this.state.is_hidden){
            return <ViewLoading/>
        } 

        return(
            <div scroll="no" className="ContainerMain ContainerMainBlue noScroll" style={{'minHeight': vhToPx('100vh')}}>

                <BlobTop z_index={-100000}/>

                <MenuTop buttons={['search', 'filter']} on_close_function={this.onCloseModal}/> 
                <Swiper topics={this.state.topics}/>

            </div>
        )
    }
    onFirstLoad(is_first_request = false){

        this.setState({'is_hidden': true, 'topics': []})

        // Update access token: in case app.js doesnt trigger it
        let access_token = getUrlValue('a')
        if(access_token != undefined){
            setCookie('access_token', access_token)
        }

        let category_title = context.category_title
        let platforms = context.platforms
        let genres = context.genres

        let that = this

        new Url('qRecommend',
                'category_title', category_title,
                'platform', platforms,
                'genres', genres,
                'is_first_request', is_first_request).get(function(response){that.parseSwipeTopics(response, is_first_request)})

    }
    parseSwipeTopics(response, is_first_request = false){

        // Parse topics
        let topics = response.results.topics
        topics.forEach(function(a_topic){
            if(a_topic.top_friend_gae_key){
                a_topic.user_liked = User.retrieve(a_topic.top_friend_gae_key)
            }
        })

        // // TEMP
        // topics = [new Topic({title: "friends", is_notification: true, 'gae_key': Math.random()})].concat(topics)

        // Available platforms
        if(response.platforms != undefined){
            
            ['movies', 'tv'].forEach(function(category_title){
                if(response.platforms[category_title].length > 0){
                    globalcontext.platforms_available[category_title] = response.platforms[category_title][0].platforms
                }
                else{
                    globalcontext.platforms_available[category_title] = []
                }
            })
        }

        // Set state
        this.setState({
                        'is_hidden': false,
                        'topics': topics,
                    })

        // Set user
        if(is_first_request){
            this.context.app_ref.current.setUser(response.user, response.access_token, response.share_token,
                                    response.has_new_recommendations,
                                    response.share_token_received,
                                    response.is_default_user,
                                    response.has_friends,)
                                }
        else{
            this.context.app_ref.current.setUser(response.user, response.access_token)
        }

    }

    onCloseModal(){

        let current_context = context

        // Cookies
        setCookie('category_title', current_context.category_title)
        setCookie('platform', current_context.platforms)
        setCookie('genres', current_context.genres)

        // Refresh?
        let is_refresh = false
        if(current_context.category_title != this.state.category_title){
            is_refresh = true
        }
        else if(!isEqualArrays(current_context.platforms, this.state.platforms)){
            is_refresh = true
        }
        else if(!isEqualArrays(current_context.genres, this.state.genres)){
            is_refresh = true
        }
    
        if(is_refresh){
            
            this.setState({'is_hidden': true})
            this.onFirstLoad()
        }

    }
}