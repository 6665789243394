// IMPORTS
import {Component} from 'react'
import top from '../../img/background/blob/top.svg'
import topleft from '../../img/background/blob/topleft.svg'
import topleft2 from '../../img/background/blob/topleft2.svg'
import bottomleft from '../../img/background/blob/bottomleft.svg'
import pg1 from '../../img/background/blob/purplegreen1.svg'
import pg2 from '../../img/background/blob/purplegreen2.svg'
import pg3 from '../../img/background/blob/purplegreen3.svg'

// CLASSES
export class BlobTop extends Component{
    render(){
        let style = undefined
        if(this.props.z_index != undefined){
            style = {'zIndex': this.props.z_index}
        }
        return <div style={style}><img src={top} className='BackgroundBlobTopLeft'/></div>
    }
}

export class BlobTopLeft extends Component{
    render(){
        return <img src={topleft} className='BackgroundBlobTopLeft'/>
    }
}
export class BlobTopLeft2 extends Component{
    render(){
        return <img src={topleft2} className='BackgroundBlobTopLeft'/>
    }
}

export class BlobBottomLeft extends Component{
    render(){
        return <img src={bottomleft} className='BackgroundBlobBottomLeft'/>
    }
}

export class BlobPurpleGreen1 extends Component{
    render(){
        return <img src={pg1} className='BackgroundBlobBottomLeft'/>
    }
}

export class BlobPurpleGreen2 extends Component{
    render(){
        return <img src={pg2} className='BackgroundBlobBottomLeft'/>
    }
}
export class BlobPurpleGreen3 extends Component{
    render(){
        return <img src={pg3} className='BackgroundBlobBottomLeft'/>
    }
}