// IMPORTS
import { Component } from "react";

import { ActivityCellsList } from "../users/activity";
import { ViewLoading } from "./loading";

import { Url } from "../../util/server";

import { activityWithActivity } from "../../models/activity";
import { MainContext } from "../generic/context";
import { EmptyFeed } from "../generic/empty";

import { vhToPx } from "../../util/display";

import { Scroller } from "../../models/scrolling";

import { MenuTop } from "../menu/top";

import {context as globalcontext} from "../../util/global"

import {ViewAddFriends} from "../views/friends"

// CONSTANTS
const url_get = new Url('qGetNotifications')
const scroller_id = "scroller_feed" // can't cursor for now, not working here

// CLASSES
export class ViewFeed extends Component{

    static contextType = MainContext
    constructor(props){
        super(props)
        this.parseActivities = this.parseActivities.bind(this)
        this.state = {'activities': [],
                        'is_hidden': true,
                        'has_friends': true}

        let that = this
        this.scroller = new Scroller(scroller_id, url_get, that.parseActivities)

        this.checkIfRefresh = this.checkIfRefresh.bind(this)

    }
    render(){

        if(this.state.is_hidden){
            return <ViewLoading/>
        } 

        let main_component
        if(this.state.activities.length == 0){
            if(!this.state.has_friends){
                main_component = <div>
                                    <EmptyFeed/> 
                                    <br/><br/>
                                    <span className="grayText">
                                        <ViewAddFriends is_long={true}/>
                                    </span>
                                </div>
            }
            else{
                main_component = <EmptyFeed/> 
            }
        }
        else{
            main_component = <ActivityCellsList
                            activities={this.state.activities}
                            show_topic_image={true}/>
        }

        let menu_component = <MenuTop buttons={['friends']} buttons_only={true} on_close_function={this.checkIfRefresh}/>

        return(
            <div className="ContainerMain ContainerMainBlue" id={scroller_id}
                    style={{'minHeight': vhToPx('100vh')}}>
                {menu_component}
                <div className='ContainerH1'>
                    <h1>Feed</h1>
                </div>
                <div className="ContainerContent">
                    <h2> your friends' activity.</h2>
                    <br/><br/>
                    {main_component}
                </div>
            </div>
        )
    }

    onFirstLoad(){

        if(!this.context.app_ref.current.checkIfLogin()){
            this.setState({'is_hidden': false})
            return
        }
        
        // Get activity
        this.scroller.get()

        if(!this.state.is_hidden){
            this.setState({'is_hidden': true})
        }
    }

    parseActivities(response){
        
        let activities = []
        if(response.results.activities != undefined){
            response.results.activities.forEach(function(an_activity){
                activities.push(activityWithActivity(an_activity))
            })
        }
        
        activities = this.state.activities.concat(activities)
        
        this.setState({'activities': activities,
                        'is_hidden': false,
                    'has_friends':globalcontext.has_friends})

        this.context.app_ref.current.setUser(response.user, response.access_token)
    }

    checkIfRefresh(){

        if(globalcontext.needs_reload['feed']){
            this.onFirstLoad()
        }
    }

}