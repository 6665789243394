// IMPORTS
import $ from 'jquery'

import {context} from '../util/global'
import {setCookie, getCookie} from '../util/cookies'
import {loadingImage} from '../util/display'
import {setInnerHTMLForDiv} from '../util/div'
import {Url} from '../util/server'
import {Model, models_dict, classes_dict} from './main'
import {Scroller} from './scrolling'

// CLASSES
export class User extends Model{
	name(){
		if(this.name){
			return this.name;
		}
		return this.pseudo;
	}

	picture(){
	    if(this.image_id){
	        return (context.context + '?q=getPictureWithId&id=' + this.image_id);
	    }
	    else{return '/files/img/profileIcon.png';}
	    //else{return '/files/img/defaultProfilePicture.svg';}
	}
	pictureImgDiv(){
	    return this.pictureImgTagWithSize('25px');
	}
	pictureImgDivWithSize(a_size){
	    return this.pictureImgDivWithSizeAndMarginTop(a_size,-1);
	}
	isMe(){
    	return this.gae_key == context.user_me.gae_key;
	}
	friendName(){
	    if(this.isMe()){
	        return 'you';
	    }else{
	        return this.name();
	    }
	}
	
	profileViewId(){
		return 'user_profile_' + this.gae_key
	}
	
	getProfile(){

		// Loading
		setInnerHTMLForDiv(loadingImage('profile'), this.profileViewId());
		
		let a_url = new Url('qGetUserProfile', 'user_gae_key', this.gae_key)
		let a_user = this
		let callback_function = function(response){return User.parseUserProfile(response, a_user.gae_key)}

		// Scroller
		let scroller_profile = new Scroller()
		let profile_view_id = this.profileViewId()
		
		scroller_profile.set(profile_view_id, a_url, callback_function, true)

		// Get first page		
		scroller_profile.get()
		
	}
	
	static parseUserProfile(response, user_gae_key){

		return response.results.html // parsed in Scroller

	}
	
	getNotifications(){
		
		setInnerHTMLForDiv(loadingImage('profile'), 'notifications');
		
	    let url = new Url('qGetNotifications')
		
		url.get(function(response){User.parseNotifications(response)})
		
	}
	
	static parseNotifications(response){

		let html = response.results.html
		setInnerHTMLForDiv(html, 'notifications');

	}
	
	// Social
	static createWithSocialUser(social_user){
		
		let share_token_key = context.share_token_key
		
		let a_url = new Url('qCreateUserWithSocialUser',
		'social_account', social_user.social_account,
		'user_id', social_user.user_id,
		'email', social_user.email,
		'image_url', social_user.image_url,
		'name', social_user.name,
		'short_name', social_user.short_name,
		'first_name', social_user.first_name,
		'last_name', social_user.last_name,
		'social_access_token', social_user.social_access_token,
		'social_access_token_expiry', social_user.social_access_token_expiry,
		'share_token_key', share_token_key)
		
		a_url.get(userHasLoggedin)
	}
	static updateWithSocialUser(social_user){
		
		let share_token_key = context.share_token_key
		
		let a_url = new Url('qUpdateUserWithSocialUser',
		'social_account', social_user.social_account,
		'user_id', social_user.user_id,
		'email', social_user.email,
		'image_url', social_user.image_url,
		'name', social_user.name,
		'short_name', social_user.short_name,
		'first_name', social_user.first_name,
		'last_name', social_user.last_name,
		'social_access_token', social_user.social_access_token,
		'social_access_token_expiry', social_user.social_access_token_expiry,
		'share_token_key', share_token_key)
		
		a_url.get()
	}

	static updateFriendsWithSocialUser(social_user){
		let a_url = new Url('qUpdateFriendsWithSocialUser',
		'social_account', social_user.social_account,
		'user_id', social_user.user_id,
		'email', social_user.email,
		'image_url', social_user.image_url,
		'name', social_user.name,
		'short_name', social_user.short_name,
		'first_name', social_user.first_name,
		'last_name', social_user.last_name,
		'social_access_token', social_user.social_access_token,
		'social_access_token_expiry', social_user.social_access_token_expiry,
		'friends_list', social_user.friends_list,
		)
		
		a_url.get(parseRecommendedFriends)
	}
}

export class Activity extends Model{
	
}

export class Recommendation extends Model{
	
}

classes_dict['User'] = User
classes_dict['Recommendation'] = Recommendation

context.user_me = new User(context. server_current_user);
export let is_super_user = false
export function isSuperUser(){
	is_super_user = context.user_me.gae_key == 'agt3dXJkcmV2aWV3c3IRCxIEVXNlchiAgIDM67OOCgw'
}
isSuperUser()

// LOGIN
function isLoggedIn(){
    return isWurdLoggedIn(); //  || isFBLoggedIn() || isTwitterLoggedIn());
}
let url_current = undefined;
function updateProfile(response){
	
	// Parse user
	context.user_me = response.user
	let html = response.results['html']

}
export function userHasLoggedin(response){

	setCookie('access_token', response.access_token)
	window.location.reload()

}
export function isWurdLoggedIn(){
	
	let cookie_access_token = getCookie('access_token')
    return (cookie_access_token != undefined) & (cookie_access_token != '') & (cookie_access_token != 'undefined');
}
function appendLoginVariablesUrlToPostData(post_data){
    if(isWurdLoggedIn()){
        post_data.append("access_token", getCookie('access_token'));
    }
    return post_data;
}

// BUTTONS

// PARSE
function parseUserConnected(response){

    // Retrieve user
	let results = response.results
	let user_response = results.user;
    if(user_response !== undefined){
	
        context.user_me = User(JSON.parse(user_response));
        models_dict[context.user_me.gae_key] = context.user_me;
        
        //setProfileMenu(); TODO

    }

    // Parse friends reviews
    //parseUserProfile(response, context.user_me.gae_key); TODO
    //parseFriendsReviews(response); TODO

}

// FOLLOW

// TEMP
function parseRecommendedFriends(response){
	
}