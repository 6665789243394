// IMPORTS
// https://react-bootstrap.github.io/components/navbar/#home
import React, {Component} from 'react'

import feed from '../../img/icons/feed.svg'
import movies from '../../img/icons/movies.svg'
import tv from '../../img/icons/tv.svg'
import podcasts from '../../img/icons/podcasts.svg'
import games from '../../img/icons/games.svg'
import music from '../../img/icons/music.svg'
import profile from '../../img/icons/profile.svg'
import bookmark from '../../img/icons/bookmark.svg'
import recommendations from '../../img/icons/recommendations.svg'

import feedselected from '../../img/icons/feedselected.svg'
import moviesselected from '../../img/icons/moviesselected.svg'
import tvselected from '../../img/icons/tvselected.svg'
import profileselected from '../../img/icons/profileselected.svg'
import bookmarkselected from '../../img/icons/bookmarkselected.svg'
import recommendationsselected from '../../img/icons/recommendationsselected.svg'

import bookmarknew from '../../img/icons/bookmarknew.svg'

import { capitalizeFirstLetter } from '../../util/string'
import {category_title} from '../../util/cookies'
import { MainContext } from '../generic/context'
import { vwToPx } from '../../util/display'
import { context } from '../../util/global'

// CONSTANTS
const k_menu_titles = ['swipe', 'feed', 'bookmarks', 'profile']

// CLASSES
export class MenuBottom extends Component{

    static contextType = MainContext
    constructor(props){

        super(props)
        let is_loaded = []
        let cells_ref = []
        let views_ref = []

        let idx = 0
        this.props.cells.forEach(function(a_cell){
            if(idx == 0){
                is_loaded[idx] = true
            }
            else{
                is_loaded[idx] = false
            }
            cells_ref[idx] = React.createRef();
            views_ref[idx] = React.createRef();

            idx += 1
        })

        this.state = {'selected_index': 0,
                        'is_loaded': is_loaded,
                        'cells_ref': cells_ref,
                        'views_ref': views_ref,
                        'has_new_recommendations': false}

        this.onClick = this.onClick.bind(this)

    }

    render(){

        /*
        let cells_components = []
        let routes_components = []
        let idx = 1
        
        this.props.cells.forEach(function(a_cell){
            cells_components.push(
                a_cell
            )

            let a_path = '/' + a_cell.props.title
            if(a_cell.props.is_home){
                a_path = '/'
            }
            routes_components.push(
                <Route path={a_path} element={a_cell.props.view} key={idx}/>
            )
            idx += 1
        })
        */

        let cells_components = []
        let views_components = []

        let a_menu_bottom = this
        let idx = 0
        this.props.cells.forEach(function(a_cell){
  
            let idy = idx // to bind the variable
            const cell_with_ref = React.cloneElement(
                a_cell, 
                {'ref': a_menu_bottom.state.cells_ref[idx],
                'is_selected': a_menu_bottom.state.selected_index == idy,
                'has_new_recommendations': a_menu_bottom.state.has_new_recommendations}
              );

            cells_components.push(
                <span onClick={() => a_menu_bottom.onClick(idy)} key={cell_with_ref.props.title}>
                    {cell_with_ref}
                </span>
            )
            idx += 1
        })

        idx = 0
        let is_hidden
        let class_name
        let selected_index = this.state.selected_index
        
        this.props.cells.forEach(function(a_cell){

            let idz = idx
            is_hidden = idz != selected_index
            if(is_hidden){
                class_name = "hidden"
            }
            else{
                class_name = ""
            }
            const view_with_ref = React.cloneElement(
                a_cell.props.view, 
                {'ref': a_menu_bottom.state.views_ref[idx]}
              );

            views_components.push(
                <div key={a_cell.props.title} className={class_name}>
                    {view_with_ref}
                </div>
            )

            idx += 1
        })

        return( <div>
            {views_components}
            <div className="MenuBottom">
                <div className="MenuBottomContainer">
                    {cells_components}
                </div>
            </div>
        </div>)
        /*
        return( <div className={class_name}>
                    <BrowserRouter>
                        <Routes>
                            {routes_components}
                        </Routes>
                        <div className="MenuBottom">
                            <div className="MenuBottomContainer">
                                {cells_components}
                            </div>
                        </div>
                    </BrowserRouter>
                </div>)
        */
    }

    componentDidMount(){
        this.state.views_ref[0].current.onFirstLoad(true) // is_first_request
    }

    onClick(selected_index){
        
        let needs_reload = !this.state.is_loaded[selected_index] // TODO: different from is_loaded?
        if(context.needs_reload[k_menu_titles[selected_index]]){
            needs_reload = true
            this.state.cells_ref[selected_index].current.setState({'is_hidden': true})
        }
        
        if(needs_reload){
            this.state.views_ref[selected_index].current.onFirstLoad()
            this.state.is_loaded[selected_index] = true
            context.needs_reload[k_menu_titles[selected_index]] = false
        }
        this.setState({'selected_index': selected_index})

        let idx = 0
        let is_selected
        this.state.cells_ref.forEach(function(cell_ref){

            let idy = idx
            is_selected = idy == selected_index
            cell_ref.current.setState({'is_selected': is_selected})

            idx += 1
        })
    }
}

export class MenuBottomCell extends Component {

    static defaultProps = {'width': vwToPx('8.467vw'),
                            'top': vwToPx('1.5vw'),}

    render(){

        return(
            /*
            <Link to={link} className="nav-link" onClick={this.onClick}>
                {this.state.button}
            </Link>
            */
            <span className="inlinedBlock">
                <button className="transparent MenuBottomButton">
                    <div className="MenuBottomCell">
                        <div className="MenuBottomImageContainer" style={{'top':this.props.top}}>
                            <img
                            src={this.props.is_selected ? this.props.image_selected : this.props.image}
                            className="MenuBottomImage"
                            alt={capitalizeFirstLetter(this.props.title) + '.'}
                            width={this.props.width}
                            />
                        </div>
                        <div className="MenuBottomText">
                            {capitalizeFirstLetter(this.props.title)}
                        </div>
                    </div>
                </button>
            </span>
        )
    }
}

export class MoviesCell extends Component{
    constructor(props){
        super(props)
        this.state={'is_selected': props.is_selected}
    }
    render(){
        return(
            <MenuBottomCell title='movies' image={movies}  image_selected={moviesselected} is_selected={this.state.is_selected} width={vwToPx('6.467vw')} top={0}/>
        )
    }
}

export class BookmarksCell extends Component{

    constructor(props){
        super(props)
        this.state={'is_selected': props.is_selected}
    }
    render(){

        return(
            <MenuBottomCell title='bookmarks' image={this.props.has_new_recommendations ? bookmarknew : bookmark} 
                                        image_selected={bookmarkselected}
                                        is_selected={this.state.is_selected}/>
        )
    }
}

export class RecommendationsCell extends Component{
    constructor(props){
        super(props)
        this.state={'is_selected': props.is_selected}
    }
    render(){
        return(
            <MenuBottomCell title='recs' image={recommendations}  image_selected={recommendationsselected} is_selected={this.state.is_selected}/>
        )
    }
}

export class FeedCell extends Component{
    constructor(props){
        super(props)
        this.state={'is_selected': props.is_selected}
    }
    render(){
        return(
            <MenuBottomCell title='feed' image={feed} image_selected={feedselected} is_selected={this.state.is_selected}/>
        )
    }
}

export class ProfileCell extends Component{
    constructor(props){
        super(props)
        this.state={'is_selected': props.is_selected}
    }
    render(){
        return(
            <MenuBottomCell title='profile' image={profile} image_selected={profileselected} is_selected={this.state.is_selected}/>
        )
    }
}
