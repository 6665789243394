// IMPORTS
import $ from 'jquery'
import {capitalizeFirstLetter} from './string'
//import {Keyboard} from "react-native"

// FUNCTIONS
export function hideKeyboard() {
    /*
    if(Keyboard){
        Keyboard.dismiss()
    }
    */
    /*
    let element = document.getElementById(div_id)
    element.attr('readonly', 'readonly'); // Force keyboard to hide on input field.
    element.attr('disabled', 'true'); // Force keyboard to hide on textarea field.
    setTimeout(function() {
        element.blur();  //actually close the keyboard
        // Remove readonly attribute after keyboard is hidden.
        element.removeAttr('readonly');
        element.removeAttr('disabled');
    }, 100);
    */
}
export function showSlideFromRight(a_div){
	
    $('#'+a_div).css('position','absolute');
    $('#'+a_div).animate({left:'15px',opacity:'0'},0);
    $('#'+a_div).removeClass('display_none');
    $('#'+a_div).animate({right:'15px',opacity:'1'},200);

}

export function scrollToAnchor(an_anchor){
    var aTag = $("a[name='"+ an_anchor +"']");
    var tag_offset = aTag.offset().top - 150;
    $('html,body').animate({scrollTop: tag_offset},'slow');
}

export function loadingImage(a_category){
    //return "<center><img src='" + client_root + "wp-content/uploads/loading.gif' width=50 height=50></center>";
    if(a_category !== undefined){a_category = capitalizeFirstLetter(a_category);}else{a_category="";}
    return "<center><br><img src='/files/img/loading" + a_category + ".svg' width=100 height=100><br></center>";
}

export function vwToPx(image_size){
    if(image_size.includes('vw')){
            
        image_size = image_size.replaceAll('vw', '')
        image_size = image_size * window.innerWidth / 100 + 'px'
    }

    return image_size
}
export function vhToPx(image_size){
    if(image_size.includes('vh')){
            
        image_size = image_size.replaceAll('vh', '')
        image_size = image_size * window.innerHeight / 100 + 'px'
    }

    return image_size
}
export function pxToVw(image_size){

    let has_px = String(image_size).includes('px')
    if(has_px){
        image_size = image_size.replaceAll('px', '')
    }

    image_size = image_size / window.innerWidth * 100

    if(has_px){
        image_size += 'px'
    }

    return image_size
}
export function pxToVh(image_size){

    let has_px = String(image_size).includes('px')
    if(has_px){
        image_size = image_size.replaceAll('px', '')
    }
    
    image_size = image_size / window.innerHeight * 100

    if(has_px){
        image_size += 'px'
    }

    return image_size
}

// MOBILE
function openMobileMenu(){
    $('.menu_row').toggleClass('menu_row_selected');
    $('.mobile_menu').toggleClass('display_none');
    $('.menu_triangle').toggleClass('rotate_180');
}
function openMobileSearch(){
    $('.search_row').toggleClass('display_none');
    $('#topicsSearchBar').val('');
    $('#topicsSearchBar').focus();
}

// INFO
let info_notification;
export function showInfo(info_text){
	
    alert(info_text)

	//let show_text = "<h6>" + info_text + " <a href='javascript:void(0)' onclick='closeInfo()'><img src='http://inawordreviews.com/wp-content/uploads/deleteIcon.png' width=25 height=25 align='absmiddle'></a></h6>";

	/*
	info_notification = noty({
        text: show_text,
        animation: {
            open: 'animated fadeInDown', // Animate.css class names
            close: 'animated fadeOutDown', // Animate.css class names
        },
        maxVisible: 1,
        theme: 'relax',
        type: 'information',
        timeout: 'true',
        killer: 'false',
        closeWith: [], // ['click', 'button', 'hover', 'backdrop']
        
    });
	*/
}
function closeInfo(){
    info_notification.close();
}


export function showConfirmation(info_text, buttons, callback_function){
	
	let is_ok = window.confirm(info_text)
	if(is_ok){
		callback_function()
	}
	
	/*
	console.log('showConfirmation: ' + info_text)
	
	let buttons_noty = []
	
	buttons.forEach(function(a_button){

		buttons_noty.push({addClass: 'btn', text: 'Ok', onClick: function($noty) {
					        $noty.close();
					        callback_function(a_button);
					      }});
		
	})
	
    let show_text = "<h6>" + info_text + " <a href='javascript:void(0)' onclick='closeInfo()'><img src='http://inawordreviews.com/wp-content/uploads/deleteIcon.png' width=25 height=25 align='absmiddle'></a></h6>";
    confirmation_notification = noty({
        text: show_text,
        animation: {
            open: 'animated fadeInDown', // Animate.css class names
            close: 'animated fadeOutDown', // Animate.css class names
        },
        maxVisible: 1,
        theme: 'relax',
        type: 'confirm',
        timeout: 'true',
        killer: 'false',
        closeWith: [], // ['click', 'button', 'hover', 'backdrop']
        buttons: buttons_noty,
    });
	*/
}
