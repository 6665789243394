// IMPORTS
import { Component } from "react";

import {Avatar} from '../users/avatar'

import { Url } from "../../util/server";

import {context} from "../../util/global"

// CLASSES
export class WordCell extends Component{

    static defaultProps = {has_count: true,
                            }

    constructor(props){

        super(props)
        this.state = {'word': props.word}
        this.onClick = this.onClick.bind(this)
        this.wasSelected = this.wasSelected.bind(this)
        this.wasDeleted = this.wasDeleted.bind(this)

    }

    render(){

        let avatar = undefined
        /*
        if(this.state.word.author){
            avatar = <span className="WordCellAvatar">
                        <Avatar user={this.state.word.author} size='15vw' shape='circle'/>
                    </span>
        }
        */

        let class_names = "ContainerWord"
    
        if(this.state.word.is_created_by_user){
            class_names += " WordCellCreated"
        }
        else if(this.state.word.is_selected_by_user){
            class_names += " WordCellSelected"
        }
        else if(this.props.is_swipe){
            class_names += " WordCellSwipe"
        }
        else{
            class_names += " WordCellUnselected"
        }

        {/* Text */}
        let text_div = <div className="WordCellText">
                            {this.state.word.text}
                        </div>        

        {/* Count */}
        let count_div
        if(this.props.has_count & (!this.props.is_swipe)){
            class_names += " WordCellWithCount"

            let count = this.state.word.selected_count
            if(count == undefined){
                count = 0
            }

            let class_names_count = "WordCellCount"
            if(this.state.word.is_created_by_user){
                class_names_count += " WordCellCountCreated"
            }
            else if(this.state.word.is_selected_by_user){
                class_names_count += " WordCellCountSelected"
            }
            else{
                class_names_count += " WordCellCountUnselected"
            }
            
            count_div = <div className={class_names_count}>{count}</div>
        }
        
        if(this.props.is_swipe){
            return(
                <div>
                    <div className="ContainerWord WordCellSwipeBelow">
                    {text_div}
                    </div>
                    <div className={class_names} onClick={this.onClick}>
                        {text_div}
                    </div>
                </div>
            )
        }else{
            return(
                <div className={class_names} onClick={this.onClick}>
                    {avatar}
                    {text_div}
                    {count_div}
                </div>
            )
        }
    }

    onClick(){

        // count, is_selected
        let word = this.state.word
		if(word.is_selected_by_user){
			
			word.selected_count -= 1
		
		}else{
			word.selected_count += 1
		}
		word.is_selected_by_user = !word.is_selected_by_user

		// url
		let url = new Url('qSelectWordWithGaeKey',
                            'word_gae_key', word.gae_key,
                            'topic_gae_key', word.topic_gae_key,
                            'author_gae_key', word.author_gae_key,
                            'text', escape(word.text))

		url.get(this.wasSelected)

        // Render
        this.setState({'word': word})

    }

    wasSelected(response){
        let a_word = response.results
        let that = this
        if(a_word.selected_count == 0){
            if(a_word.author_gae_key == context.user.gae_key){
                let is_delete = window.confirm("Delete your wurd: " + a_word.text + "?")
                if(is_delete){
                    let url = new Url('qDeleteWordWithGaeKey',
                            'word_gae_key', a_word.gae_key,
                            )
                    url.get(that.wasDeleted)
                }
            }
        }
    }
    wasDeleted(response){
        if(this.props.on_delete_function){
            this.props.on_delete_function()
        }
    }
}

export class WordsCells extends Component{

    static defaultProps = {
        words: [],
    }
    render(){
        
        let words_list = []
        let that = this
        that.props.words.forEach(function(a_word){

            if(a_word){ // TODO: some undefined words? ex: Upload
                words_list.push(<WordCell key={a_word.gae_key} word={a_word} on_delete_function={that.props.on_delete_function}/>)
            }
        })

        return(
            <div>
                {words_list}
            </div>
        )
    }
}