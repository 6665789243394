// IMPORTS
import { Component } from "react";

import { CloseButton } from "../generic/button"

import { SearchBar } from "../generic/search";
import { UsersCells } from "../users/cell";
import { SeparationOr } from "../generic/text";
import { TopicCell } from "../topics/cell";

import { vhToPx } from "../../util/display";
import { Url } from "../../util/server";
import { MainContext } from "../generic/context";
import { context } from "../../util/global";
import { removeFromArray } from "../../util/array";

import copylink from "../../img/icons/copylink.svg"
import { copyTextToClipboard } from "../../util/generic";
import { Avatar } from "../users/avatar";

// CLASSES
export class ViewRecommend extends Component{

    static contextType = MainContext;

    constructor(props){

        super(props)
        this.state = {'search_friend': '',
                        'user_seen': undefined,
                        'friends_selected': [],
                        'share_token_key': undefined,
                        'is_linked_copied': false}

        this.autocompleteSearch = this.autocompleteSearch.bind(this)
        this.onClick = this.onClick.bind(this)
        this.onRecommend = this.onRecommend.bind(this)

    }

    render(){

        let personalized_link_component = <PersonnalizedLink share_token_key={this.state.share_token_key}/>
                                    
        let friends_filtered = []

        if(this.state.search_friend == ''){
            friends_filtered = context.user.friends
        }
        else{
            let reg = new RegExp(SearchBar.autocompleteParseValue(this.state.search_friend))
            for (let i=0; i<context.user.friends.length; i++) {

                if(context.user.friends[i].pseudo){
                    if (SearchBar.autocompleteParseValue(context.user.friends[i].pseudo.toLowerCase()).match(reg)) {
                        friends_filtered.push(context.user.friends[i])
                    }
                }
            }
        }

        let user_seen_component
        if(this.state.user_seen != undefined){
            user_seen_component = <div className="greenColor">
                                    {this.state.user_seen} has already seen it.
                                    <br/>
                                    <br/>
                                </div>
        }
        

        let explanation_component
        
        if(this.state.friends_selected.length == 0){
            explanation_component = <div className="grayText">
                                        Select friends: <span className="greenColor">{this.props.topic.title}</span> will appear in their bookmarks, with your face on it.
                                    </div>
        }else{
            explanation_component = <button className="ButtonTeal ButtonLarge"
                                            style={{'marginTop': '0.57vw',
                                                    'marginBottom': '3.37vw'}}
                                            onClick={this.onRecommend}>
                                            Recommend it!
                                    </button>
        }
        
        // ContainerModal?
        return(
            <div className='ContainerMain ContainerMainBlue' style={{'minHeight': vhToPx('100vh')}}>
                {/*<CloseButton/>*/}
                <div className='ContainerH1'>
                    <h1>Recommend</h1>
                </div>
                
                <div className="ContainerContent">
                    {personalized_link_component}
                    {explanation_component}
                    <br/>
                    <SearchBar
                        placeholder = 'Search your followers'
                        autocomplete_function={this.autocompleteSearch}   
                        clear_function={()=>this.autocompleteSearch('')}/> 
                    <br/>
                    <br/>
                    {user_seen_component}
                    <UsersCells users={friends_filtered}
                        is_selectable={true}
                        has_follow_button={false}
                        has_select_button={true}
                        cell_type='vignette'
                        on_click_function={this.onClick}/>
                </div>
            </div>
        )
    }
    componentDidMount(){

        let view_recommend = this
        let friends_selected = []
        new Url('qGetShare', 'topic_gae_key', this.props.topic.gae_key).get(function(response){

            view_recommend.setState({'share_token_key': response.results.share_token_key})
            context.user.friends = response.results.friends
            
            let idf = 0
            response.results.is_disabled.forEach(function(is_disabled_f){
                context.user.friends[idf].is_disabled = is_disabled_f
                context.user.friends[idf].is_selected = response.results.is_selected[idf]

                if(response.results.is_selected[idf]){
                    friends_selected.push(context.user.friends[idf])
                }

                idf += 1
            })

            if(friends_selected.length == 0){
                view_recommend.forceUpdate()
            }else{
                view_recommend.setState({'friends_selected': friends_selected})
            }

        })
    }

    autocompleteSearch(value){
        this.setState({'search_friend': value})
    }

    onClick(user){
        if(user.is_disabled){
            this.setState({'user_seen': user.pseudo})
        }
        else{
            if(this.state.friends_selected.includes(user)){
                this.setState(removeFromArray(this.state.friends_selected, user))
            }
            else{
                let friends_selected = [...this.state.friends_selected]
                friends_selected.push(user)
                this.setState({'friends_selected': friends_selected})
            }
        }
    }

    onRecommend(){

        let friends_selected = []
        this.state.friends_selected.forEach(function(user){
            friends_selected.push(user.gae_key)
        })

        let url = new Url('qSendRecommendations',
                            'topic_gae_key', this.props.topic.gae_key,
                            'friends_gae_keys', friends_selected);
	    url.get()
        
        this.context.app_ref.current.closeModal()
    }

}

export class ViewSharedRecommendation extends Component{

    render(){
        return(
            <div className='ContainerMain ContainerMainBlue' style={{'minHeight': vhToPx('100vh')}}>
                <div className="ContainerContent">
                    <div  style={{'marginBottom':'2vw', 'marginTop': '20vw'}}>
                        <span className="inlineBlock imageCenter" style={{'marginRight':'2vw'}}><Avatar user={this.props.user_from} shape='circle' size={'10vw'}/></span> {this.props.user_from.pseudo} recommended you <span className="greenColor">{this.props.topic.title}</span>! 
                    </div>
                    <TopicCell topic={this.props.topic} hide_topic_title={true}/>
                </div>
            </div>
        )
    }
}

export class PersonnalizedLink extends Component{

    static defaultProps = {'has_separation': true}
    constructor(props){
        super(props)
        this.state = {'is_linked_copied': false}

        this.onClickLink = this.onClickLink.bind(this)
    }

    render(){
        let copy_link_element
        let link
        if(this.props.share_token_key != undefined){
            if(this.state.is_linked_copied){
                copy_link_element = <span className="inlineBlock" style={{'color':'#4E88F8', 'opacity': 0.5}}>
                                        Copied
                                    </span>
            }
            else{
                copy_link_element = <span className="inlineBlock" style={{'color':'#4E88F8'}}>
                                        Share link
                                    </span>
            }

            link = "wurd.reviews/?s=" + this.props.share_token_key.slice(0,5)
        }
        let separation_element
        if(this.props.has_separation){
            separation_element = <SeparationOr opacity={0.5}/>
        }

        return <div>
                                                <div className="grayText">
                                                    Send a personalized link to your friends.
                                                </div>
                                                <br/>
                                                <div className="ContainerLink" onClick={this.onClickLink}>
                                                    <span  className="inlineBlock">
                                                        {link}
                                                    </span>
                                                    <span className="inlineBlock floatRight">
                                                        <span className="inlineBlock imageCenter" style={{'marginRight':'2vw'}}>
                                                            <img
                                                                src={copylink}
                                                            />
                                                        </span>
                                                        {copy_link_element}
                                                    </span>
                                                </div>
                                                <br/>
                                                {separation_element}
                                            </div>
    }

    onClickLink(){

        if(this.props.share_token_key != undefined){

            let url = context.server_root + '?s=' + this.props.share_token_key
            if(navigator.share){
                navigator.share({'url': url,
                                'title': "I think you'll like this",
                                'text': "Wurd lets you find the next show you'll watch on Netflix or elsewhere. I think you'll like it!"})
            }
            copyTextToClipboard(context.server_root + '?s=' + this.props.share_token_key)
            
            this.setState({'is_linked_copied': true})
        }
    }
}