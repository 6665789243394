// IMPORTS
import { Component } from "react";

import {TopicCellsList} from "../topics/cell"
import { ViewLoading } from "./loading";

import { Url } from "../../util/server";
import { MainContext } from "../generic/context";
import { EmptyBookmarks, EmptyRecommendations } from "../generic/empty";
import { vhToPx } from "../../util/display";

import { context } from "../../util/global";
import { Scroller } from "../../models/scrolling";
import { MenuTabs } from "../menu/tabs";

import {ViewAddFriends} from "../views/friends"

import $ from 'jquery'

// CONSTANTS
const url_get_bookmarks = new Url('qRecommend', 'content', 'bookmark')
const url_get_recommended = new Url('qRecommend', 'content', 'friends')
const scroller_id = "scroller_bookmarks"
const k_menu_title_bookmarks = 'My list'
const k_menu_title_recommended = "Friends' recs"

// CLASSES
export class ViewBookmarks extends Component{

    static contextType = MainContext
    constructor(props){
        
        super(props)
        this.parseBookmarks = this.parseBookmarks.bind(this)
        this.parseRecommended = this.parseRecommended.bind(this)

        this.state = {
                    'menu_title': k_menu_title_bookmarks,
                    'topics_bookmarks': [],
                    'topics_recommended': [],
                    'is_hidden': true,
                    'has_loaded': false,
                    'has_new_recommendations': false,
                    'has_friends': true}

        let that = this
        this.scroller_bookmarks = new Scroller(scroller_id, url_get_bookmarks, that.parseBookmarks)
        this.scroller_recommended = new Scroller(scroller_id, url_get_recommended, that.parseRecommended, undefined, false)

        this.onMenuTabs = this.onMenuTabs.bind(this)
    }
    render(){

        if(this.state.is_hidden){
            return <ViewLoading/>
        } 

        let main_component
        let topics
        let like_str
        if(this.state.menu_title == k_menu_title_bookmarks){
            topics = this.state.topics_bookmarks
            like_str = 'liked it'
        }
        if(this.state.menu_title == k_menu_title_recommended){
            topics = this.state.topics_recommended
            like_str = 'recommended it'
        }
        if(topics.length == 0){
            if(this.state.menu_title == k_menu_title_bookmarks){
                main_component = <EmptyBookmarks/> 
            }
            if(this.state.menu_title == k_menu_title_recommended){
                main_component = <EmptyRecommendations/> 
            }
        }
        else{
            main_component = <TopicCellsList topics={topics} like_str={like_str}/>
        }

        let menu_title_bookmarks = <span>{k_menu_title_bookmarks}</span>
        let menu_title_recommended = <span>{k_menu_title_recommended}</span>
        if(this.state.has_new_recommendations){
            menu_title_recommended = <span className="redColor">{k_menu_title_recommended}</span>
        }

        let has_friends_component
        if(!this.state.has_friends){
            has_friends_component = <div className="grayText"><ViewAddFriends is_long={true}/></div>
        }

        return(
            <div className="ContainerMain ContainerMainBlue" id={scroller_id}
                    style={{'minHeight': vhToPx('100vh')}}
                    >
                <div className='ContainerH1'>
                    <h1>Bookmarks</h1>
                </div>
                
                <div className="ContainerContent">
                    <h2>& friends' recommendations</h2>
                </div>
                <br/>
                <MenuTabs titles={[k_menu_title_bookmarks, k_menu_title_recommended]}
                            views={[menu_title_bookmarks, menu_title_recommended]}
                            on_click_function={this.onMenuTabs}/>
                <br/>
                <div className="ContainerContent" style={{'marginBottom':vhToPx('15vh')}}>
                    {main_component}
                    {has_friends_component}
                </div>
            </div>
        )
    }

    onFirstLoad(){

        if(!this.context.app_ref.current.checkIfLogin()){
            this.setState({'is_hidden': false})
            return
        }

        // Get bookmarks
        this.setState({'is_hidden': true,
                            topics_bookmarks: []})
        this.scroller_bookmarks.cursor = undefined
        this.scroller_bookmarks.has_more = true
        this.scroller_bookmarks.get()
    }

    parseBookmarks(response){
        let topics = response.results.topics
        topics = this.state.topics_bookmarks.concat(topics)
        
        this.setState({'topics_bookmarks': topics,
                        'is_hidden': false,
                        'has_friends': true})

        this.context.app_ref.current.setUser(response.user, response.access_token)
    }
    parseRecommended(response){
        let topics = response.results.topics
        topics = this.state.topics_recommended.concat(topics)
        
        this.setState({'topics_recommended': topics,
                        'is_hidden': false,
                        'has_friends': context.has_friends})

        this.context.app_ref.current.setHasNewRecommendations(false)
    }

    onMenuTabs(title, get_topics = true){

        this.scroller_bookmarks.is_enabled = title == k_menu_title_bookmarks
        this.scroller_recommended.is_enabled = title == k_menu_title_recommended

        if(get_topics){
            if(title == k_menu_title_bookmarks){
                if(this.state.topics_bookmarks.length == 0){
                    this.scroller_bookmarks.get()
                }
            }
            if(title == k_menu_title_recommended){
                if(this.state.topics_recommended.length == 0){
                    this.scroller_recommended.get()
                }
            }
        }

        this.setState({'menu_title': title})
    }

}