// IMPORTS
import { Component } from "react"
import { AutoPlaySilentVideo } from "./loading"

import intro from "../../img/animations/intro.mp4"
import { vhToPx, vwToPx } from "../../util/display"

// CLASSES
export class ViewDesktop extends Component{
    render(){
        return <div style={{'position':'relative','width':vwToPx('100vw'),'height':vhToPx('100vh')}}>
            <div style={{'position':'fixed','width':'100%','height':'100%'}}>
                <AutoPlaySilentVideo is_loop={false} video={intro} style={{'width':'100%','height':'100%'}}/>
            </div>
            <div style={{'position':'fixed', 'fontSize': '12px !important', 'marginTop': '20vw', 'marginLeft': '5vw', 'marginRight': '5vw'}}>
                Wurd is not yet available on desktop, please open in mobile.
            </div>
        </div>
    }
}