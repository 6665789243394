// IMPORTS
import React, {Component} from 'react'
import { vhToPx, vwToPx } from '../../util/display'

import logo from '../../img/logo/dark.png'

import { createWord } from '../../models/word'
import { MainContext } from '../generic/context'

// CLASSES
export class ViewWordCreate extends Component{
    static contextType = MainContext
    constructor(props){

        super(props)
        this.state = {'word': undefined, 'input_ref': React.createRef()}
        this.onCreateWord = this.onCreateWord.bind(this)
        this.onChangeWord = this.onChangeWord.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }
    render(){
        return(
            <div className="ContainerMain ContainerMainBlack" style={{'minHeight':vhToPx('100vh')}}>
                <div className="ContainerContent">
                    <div className="WordCreateLogo">
                        
                        <img src={logo}
                            width={vwToPx('35vw')}/>
                        
                    </div>
                    <div className="WordCreateContainer">
                        <h1>
                            Give your Wurd
                        </h1>
                        <h2>
                            <div className="grayText">
                                In 30 characters or less
                            </div>
                        </h2>
                        <br/><br/>
                        <div className="WordCreateTitle">{this.props.topic.title}</div>
                        <form onSubmit={this.onCreateWord}>
                            <input type="text" placeholder="" className='WordCreateInput' autoCapitalize="none"
                                    onChange={this.onChangeWord} maxLength='30' ref={this.state.input_ref}/>
                        </form>
                    </div>
                </div>

            </div>

        )
    }
    componentDidMount(){
        this.state.input_ref.current.focus()
    }
    componentWillUnmount(){
        this.state.input_ref.current.blur()
    }

    onCreateWord(event){
        event.preventDefault()
        let word_text = this.state.word
        if(word_text != undefined){
            if(word_text.trim() != ""){

                createWord(this.props.topic, word_text, this.closeModal)

            }
        }
    }
    onChangeWord(event){
        this.setState({'word': event.target.value})
    }
    closeModal(){

        this.props.topic.user_created_word = true
        if(this.props.on_close_function != undefined){
            this.props.on_close_function()
        }
        this.context.app_ref.current.closeModal()
        this.state.input_ref.current.blur()
        

    }
}