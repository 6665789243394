// IMPORTS
// https://react-bootstrap.github.io/components/navbar/#home
import React, {Component} from 'react'

import { vwToPx } from '../../util/display'

import { ViewSearch } from '../views/search'
import { ViewFilter, k_platforms_list } from '../views/filter'
import { ViewFriends } from '../views/friends'
import { ViewSettings } from '../views/settings'

import {MainContext} from '../generic/context'
import { context } from '../../util/global'

import logo from '../../img/logo-light.png'
import filter from '../../img/icons/filter.svg'
import search from '../../img/icons/search.svg'
import settings from '../../img/icons/settings.svg'
import friends from '../../img/icons/friends.svg'

// CLASSES
export class MenuTop extends Component{

    static contextType = MainContext
    static defaultProps = {
        'buttons': [],
    }

    render(){

        let buttons_components_list = []
        if(this.props.buttons.includes('search')){
            buttons_components_list.push(<SearchButton on_close_function={this.props.on_close_function} key={'search'}/>)
        }
        if(this.props.buttons.includes('filter')){
            buttons_components_list.push(<FilterButton on_close_function={this.props.on_close_function} key={'filter'}/>)
        }
        if(this.props.buttons.includes('friends')){
            buttons_components_list.push(<FriendsButton on_close_function={this.props.on_close_function} key={'friends'}/>)
        }
        if(this.props.buttons.includes('settings')){
            buttons_components_list.push(<SettingsButton on_close_function={this.props.on_close_function} key={'settings'}/>)
        }

        let buttons_component
        if(buttons_components_list.length > 0){
            buttons_component = <span className="ButtonsTop">
                                    {buttons_components_list}
                                </span>
        }

        let image_width = vwToPx('27.467vw')
        let image_height = vwToPx(27.467 * 80 / 200 + 'vw')

        if(this.props.buttons_only){
            return <div className="ButtonTop">{buttons_component}</div>
        }
        else{
            return( <div className="MenuTop">
                        <div className="transparent LogoTop" href="#home">
                            <img
                            src={logo}
                            width={image_width}
                            height={image_height}
                            alt="Wurd."
                            />
                        </div>

                        {buttons_component}

                    </div>)
        }
    }
}

class TopButton extends Component{
    static contextType = MainContext;

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    render(){
        return(
            <div className="inlineBlock">
                <button className="transparent MenuTopButton"
                        onClick={this.onClick}>
                    <img
                    src={this.props.image_src}
                    className="MenuTopImage"
                    alt={this.props.image_alt}
                    />
                </button>
            </div>
        )
    }
    onClick(){
        this.props.on_click_function()
    }
}

class SearchButton extends Component{

    static contextType = MainContext;

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    render(){
        return(
            <TopButton
                image_alt="Search."
                image_src={search}
                on_click_function={this.onClick}
                />
        )
    }
    onClick(){

        let view_ref = React.createRef()
        this.context.app_ref.current.showModal(<ViewSearch reference={view_ref} ref={view_ref}/>, 0.05, true, this.props.on_close_function)
    }
}

class FilterButton extends Component{

    static contextType = MainContext;

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    render(){

        let platform_component
        let filter_button = this

        if(context.platforms){
            let platform_icon
            k_platforms_list.forEach(function(a_filter){
                if(a_filter.filter == context.platforms[0]){
                    platform_icon = a_filter.icon
                }
            })
            platform_component = <span className="FilterButtonPlatform">
                                    <img
                                        src={platform_icon}
                                        width={vwToPx('7vw')}
                                    />
                                </span>
        }
        return(
            <span>
                <TopButton
                    image_alt="Filter."
                    image_src={filter}
                    on_click_function={this.onClick}
                    />
                {platform_component}
            </span>
        )
    }
    onClick(){
        this.context.app_ref.current.showModal(<ViewFilter/>, 0.05, false, this.props.on_close_function)
    }
}

class FriendsButton extends Component{
    static contextType = MainContext;

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    render(){
        return(
            <TopButton
                image_alt="Friends!"
                image_src={friends}
                on_click_function={this.onClick}
                />
        )
    }
    onClick(){

        let follow_button = this
        let close_function = function(){

            ViewFriends.onClose()
            if(follow_button.props.on_close_function){
                follow_button.props.on_close_function()
            }
            
        }
        this.context.app_ref.current.showModal(<ViewFriends/>, 0.05, false, close_function)
    }
}

class SettingsButton extends Component{
    static contextType = MainContext;

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    render(){
        return(
            <TopButton
                image_alt="Settings."
                image_src={settings}
                on_click_function={this.onClick}
                />
        )
    }
    onClick(){
        this.context.app_ref.current.showModal(<ViewSettings/>, 0.05, false, this.props.on_close_function)
    }
}