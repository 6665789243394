// IMPORTS
import { Component, useRef, useEffect } from "react";
import loading from "../../img/animations/loading.mp4"
import { vhToPx } from "../../util/display";

// CLASSES
export class ViewLoading extends Component{
    render(){
        let class_names = "ContainerLoading frontView"
        if(this.props.is_hidden){
            class_names += " hidden"
        }
        return(
            <div className={class_names} style={{'minHeight':vhToPx('100vh')}}>
                <AutoPlaySilentVideo video={loading} style={{'width':'100%'}}/>
            </div>
        )
    }
}

export function AutoPlaySilentVideo(props) {
    const videoRef = useRef(undefined);
    useEffect(() => {
        videoRef.current.defaultMuted = true;
    })

    let is_loop = props.is_loop
    if(is_loop == undefined){
        is_loop = true
    }
    if(is_loop){
        return (
            <video
                className={props.className}
                ref={videoRef}
                loop
                autoPlay
                muted
                playsInline
                style={props.style}>
                <source src={props.video} type="video/mp4"/>
            </video>
        );
    }
    else{
        return (
            <video
                className={props.className}
                ref={videoRef}
                autoPlay
                muted
                playsInline
                style={props.style}>
                <source src={props.video} type="video/mp4"/>
            </video>
        );
    }
}