// IMPORTS
import { Component } from "react";

import close from '../../img/icons/close.svg'
import back from '../../img/icons/back.svg'
import radioselected from '../../img/icons/radioselected.svg'
import radiounselected from '../../img/icons/radiounselected.svg'

import bomb from '../../img/icons/bomb.gif'
import heart from '../../img/icons/heart.gif'
//import trash from '../../img/icons/trashcircle.svg'
import trash from '../../img/icons/skip.svg'
import bookmark from '../../img/icons/bookmarkcircle.svg'

import bomb_static from '../../img/icons/bomb.svg'
import heart_static from '../../img/icons/heart.svg'
import { vwToPx } from "../../util/display";
import { MainContext } from "./context";

// CLASSES
class TopButton extends Component{
    render(){

        let style = []
        if(this.props.opacity != undefined){
            style['background'] ='rgba(255, 255, 255, ' + this.props.opacity + ')'
        }
        if(this.props.top_offset != undefined){
            style['marginTop'] = this.props.top_offset
        }

        return(<div className={"ButtonGeneric " + this.props.class_name} style={style}>
            <button onClick={this.props.onClick}>
                <img src={this.props.image_src} style={this.props.image_style}/>
            </button>
        </div>)
    }
}

export class CloseButton extends Component{

    render(){
        return <TopButton class_name="ButtonClose"
            opacity={this.props.opacity}
            top_offset={this.props.top_offset}
            onClick={this.props.onClick}
            image_src={close}
            image_style={{'width':'7.46vw','height':'11vw'}}/>
    }

}

export class BackButton extends Component{

    render(){
        
        return <TopButton class_name="ButtonBack"
            opacity={this.props.opacity}
            top_offset={this.props.top_offset}
            onClick={this.props.onClick}
            image_src={back}
            image_style={{'width':'7.46vw','height':'11vw','marginLeft':'-0.5vw'}}/>
        
    }

}
export class SelectButton extends Component{
    
    constructor(props){
        super(props)
        this.state = {'is_selected':false}
        this.onClick = this.onClick.bind(this)
    }
    render(){

        let src
        if(this.state.is_selected){
            src=radioselected
        }
        else{
            src=radiounselected
        }
        return(<button className="ButtonSelect" onClick={this.onClick}>
            <img src={src}
            className="ButtonSelectImage"/>
        </button>)
    }

    onClick(){
        this.setState({'is_selected':!this.state.is_selected})
    }
}

export class ActivityButton extends Component{
    
    static defaultProps = {'width': '25%'}
    static contextType = MainContext
    constructor(props){
        super(props)
        this.state = {'is_selected':false,
                    }
    }
    render(){

        let class_names_div = "SwipeIconContainer"
        let class_names_img = "SwipeIcon"
        let image_src = this.props.src
        if(this.state.is_selected){
            if(this.props.src_selected != undefined){
                image_src = this.props.src_selected
            }
        }

        if(this.props.is_circle){

            class_names_div += " SwipeIconContainerCenter"
            class_names_img += " SwipeIconContainerCircle"
        }
        if(this.props.is_left){
            class_names_div += " SwipeIconContainerLeft"
        }
        if(this.props.is_right){
            class_names_div += " SwipeIconContainerRight"
        }

        /*
        let button = this
        let on_click_function = () => {
            button.setState({'is_selected':!button.state.is_selected})
            this.props.on_click_function()
        }
        */
       /*
        let long_press_actions = [[0, 1, this.props.long_press_function],
                                [750, 2, this.props.long_press_function],
                                [1500, 3, this.props.long_press_function],
                                [2250, 2, this.props.long_press_function],
                                [3000, 1, this.props.long_press_function],
                                [3750, 1, this.props.long_press_function],
                                ]
        */
        let style = {'width':this.props.width,
                    'top': this.props.top,
                    'bottom': this.props.bottom,
                    'left': this.props.left,
                    'right': this.props.right}
        
        if(this.props.height){
            style['height'] = this.props.height
        }
        return(
            <MultiClickButton on_click_function={this.props.on_click_function} info_function={this.props.info_function} value={0} value_max={3}>
                <div className={class_names_div} style = {style}>
                    <img
                        src = {image_src}
                        className = {class_names_img}
                    />
                </div>
            </MultiClickButton>
        )
        

        /*
        return(
            <LongPressButton on_click_function={this.props.on_click_function} actions={long_press_actions}>
                <div className={class_names_div} style = {style}>
                    <img
                        src = {image_src}
                        className = {class_names_img}
                    />
                </div>
            </LongPressButton>
        )
        */
    }
}

export class ActivityBombButton extends Component{

    static defaultProps = {'width': '25%'}
    render(){
        let src
        if(this.props.is_static){
            src = bomb_static
        }
        else{
            src = bomb
        }
        let left
        let top
        if(this.props.is_static){
            top = vwToPx('-0.3vw') // '-0.3vw'
            left = vwToPx('0.0vw') // '0.9vw'
        }
        return <ActivityButton
                src = {src}
                activity = 'dislike'
                is_left={true}
                width={this.props.width}
                on_click_function={this.props.on_click_function}
                long_press_function = {this.props.long_press_function}
                info_function = {this.props.info_function}
                is_selected={this.props.is_selected}
                top={top}
                left={left}/>
    }
}
export class ActivityHeartButton extends Component{

    static defaultProps = {'width': '25%'}
    render(){
        let src
        if(this.props.is_static){
            src = heart_static
        }
        else{
            src = heart
        }
        let left
        if(this.props.is_static){
            left = vwToPx('-0.2vw') // '0.7vw'
        }
        
        return <ActivityButton
            src = {src}
            activity = 'like'
            is_right={true}
            width={this.props.width}
            is_static={this.props.is_static}
            on_click_function={this.props.on_click_function}
            long_press_function = {this.props.long_press_function}
            info_function = {this.props.info_function}
            left={left} 
            is_selected={this.props.is_selected}/>
    }
}
export class ActivityTrashButton extends Component{

    static defaultProps = {'width': '25%'}
    render(){
        return <ActivityButton
                src = {trash}
                activity = 'trash'
                is_circle = {true}
                width={this.props.width}
                on_click_function={this.props.on_click_function}
                info_function = {this.props.info_function}
                long_press_function = {this.props.long_press_function}/>
    }
}
export class ActivityBookmarkButton extends Component{

    static defaultProps = {'width': '25%'}
    render(){
        return <ActivityButton
                src = {bookmark}
                activity = 'bookmark'
                is_circle = {true}
                width={this.props.width}
                on_click_function={this.props.on_click_function}
                info_function = {this.props.info_function}
                is_selected={this.props.is_selected}
                long_press_function = {this.props.long_press_function}/>
    }
}

export class MultiClickButton extends Component{
    constructor(props){

        super(props)
        
        this.state = {'value': this.props.value,
                        'timeout': undefined}
        this.click = this.click.bind(this)
        this.validate = this.validate.bind(this)
    }
    render(){
        return <span onClick={this.click}>
                    {this.props.children}
                </span>
    }
    click(){

        let that = this
        let value = this.state.value + 1
        if(this.props.value_max){
            value = Math.min(value, this.props.value_max)
        }

        if(this.state.timeout != undefined){
            clearTimeout(this.state.timeout)
        }
        let timeout = setTimeout(that.validate, 500)
        if(this.props.info_function){
            this.props.info_function(value)
        }
        
        this.setState({'value': value, 'timeout': timeout})

    }
    validate(){
        
        this.props.on_click_function(this.state.value)
        this.setState({'value': this.props.value, 'timeout': undefined})
        
    }

}

export class LongPressButton extends Component{
    // Doesnt work well enough => multiple clicks instead
    constructor(props){

        super(props)
        
        this.state = {'value': this.props.value,
                        'timeouts': []}

        this.start = this.start.bind(this)
        this.end = this.end.bind(this)
        this.cancel = this.cancel.bind(this)
        this.move = this.move.bind(this)
        this.click = this.click.bind(this)
        this.leave = this.leave.bind(this)

    }

    render(){
        //  onTouchCancel={this.cancel} onTouchMove={this.move} onMouseMove={this.move}
        return <span onTouchStart={this.start}
                    onMouseDown={this.start}
                    onTouchEnd={this.end}
                    onMouseUp={this.end}
                    onClick={this.click}
                    // onTouchCancel={this.cancel}
                    // onTouchMove={this.move}
                    // onMouseMove={this.move}
                    onMouseLeave={this.leave}>
                    {this.props.children}
                </span>
    }

    start(){
        
        let that = this
        let timeouts = []
        let actions_list = this.props.actions // list of (time in ms, variable value, trigger function when change in value)
        if(actions_list){
            actions_list.forEach(function(action){

                let timeout_function = function(){
                    that.setState({'value': action[1]})
                    if(action[2]){
                        action[2](action[1])
                    }
                
                }
                timeouts.push(setTimeout(timeout_function, action[0]))

            })
        }
        this.setState({'timeouts': timeouts})
        
    }

    end(){
        
        // Call function
        this.props.on_click_function(this.state.value)

        // Cancel time outs
        this.state.timeouts.forEach(function(timeout){

            clearTimeout(timeout) 
            
        })

        // Reset
        this.setState({'value': this.props.value, 'timeouts': []})
        
    }
    move(event){
    }
    cancel(){
    }
    click(){
    }
    leave(){
    }
}