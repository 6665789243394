// IMPORTS
import $ from 'jquery'

import { getCookie } from './cookies'
import { context } from '../util/global'
import {isArray} from './array'
import {User, isSuperUser, isWurdLoggedIn} from '../models/user'
import {Model, models_dict, classWithClassName, None} from '../models/main'

// CONSTANTS
let cursor

// CLASSES
export class Url{
	
	constructor(q, ...variables_and_values){
		
		this.q = q
		this.variables_and_values = variables_and_values
		
	}
	
	address(){

		let an_address = context.server_root + 'q/?q=' + this.q
		for (var i = 0, j = 1; i < this.variables_and_values.length; i+=2, j+=2) {
			
			let value_i = this.variables_and_values[j]
			if(value_i !== undefined){
				if(value_i !== null){
					if(String(value_i) !== 'undefined'){
						
						value_i = encodeURIComponent(value_i)
	        			an_address += '&' + this.variables_and_values[i] + '=' + value_i;
	        			
					}
				}
			}
	    }
		return an_address
	}
	setVariable(variable, value){
		
		let is_existing_variable = false
		for (var i = 0, j = 1; i < this.variables_and_values.length; i+=2, j+=2) {
			
			let variable_i = this.variables_and_values[i]
			if(variable_i == variable){
				
				this.variables_and_values[j] = value
				is_existing_variable = true
				break
				
			}
		}
		if(!is_existing_variable){
			this.variables_and_values.push(variable)
			this.variables_and_values.push(value)
		}
	}
	
	get(callback_function, error_function = undefined){
		
		// Authentification
		let a_url = this.address() + loginVariablesUrl();

		let success_function = function(response){

			let has_results = false
			let is_error = false

			if(response.results != undefined){
				has_results = true
				if(response.results.error !== undefined){
					is_error = true
				}
			}
			else if(callback_function != undefined){
				has_results = true
			}

			if(has_results){
				if(is_error){ // error
	                alert(response.results.error);

					if(error_function !== undefined){
						error_function(response);
					}
					return
	            }
				else{ // success
					
					response = parse(response);
					if(callback_function != undefined){
						callback_function(response);
					}

				}
			}
        }
		
		// Query
		Url.simpleGet(a_url, success_function, error_function, 'jsonp')

	}
	
	static simpleGet(a_url, success_function = undefined, error_function = undefined, dataType = ''){
		
		console.log(a_url)
		
		// Inputs
		if(error_function == undefined){
			error_function = function(xhr, ajaxOptions, thrownError){
				console.log('error: ', a_url, thrownError)
	            return -1;
	        }
		}
		
		// Query
		/*
		fetch(a_url,
			{dataType:dataType,
				method: 'GET',
			mode:'cors',
			headers: {
				'Content-Type': dataType,
			  },
			})
			.then(response => success_function(response))
		*/
		
	    $.ajax({
	        url: a_url,
	        success: success_function,
	        error: error_function,
	        dataType:dataType,
	        crossDomain:true
	    });
		
	}
}

// PARSE
function parse(response){
	
	response = _parseDelegate(response)
	if(response['user'] != undefined){
		
		let user_response = User.retrieve(response['user']['gae_key'])
		if((context.user_me == undefined) || (user_response.pseudo != undefined)){
			
			context.user_me = user_response
			isSuperUser()

		} 
		
	}
	
	return response
	
}
function _parseDelegate(a_model_dict){
	
	// Empty
	if (a_model_dict == null){
		return a_model_dict
	}
	
	// Array
	if(isArray(a_model_dict)){
		
		let out = []
		a_model_dict.forEach(function(model_dict_v){
	
			out.push(_parseDelegate(model_dict_v))	
	
		})
		return out
		
	}
	else if((a_model_dict.constructor == Object) && (a_model_dict.class_name == undefined)){

		let out = []
		for (let [a_key, a_value] of Object.entries(a_model_dict)) {
			out[a_key] = _parseDelegate(a_value)
		}
		return out
		
	}
	else{ // model or value

		let a_class_name = a_model_dict.class_name
		let a_class = classWithClassName(a_class_name)

		if(a_class == None){

			return a_model_dict
		}
		else{
			
			let an_object = new a_class(a_model_dict)
			
			let is_model = a_class.prototype instanceof Model
			//let is_view = a_class.prototype instanceof View
			if(is_model){
				
				models_dict[an_object.gae_key] = an_object
				
			}
			/*
			else if(is_view){
				
				views_dict[an_object.div_id] = an_object
				
			}
			*/
			else{
				console.log('NEITHER A MODEL OR A VIEW')
				console.log(an_object)
				console.log(a_class)
			}

			return an_object
		}
	}
	
	return a_model_dict
}

// UTIL
export function getUrlValue(variable_name)
{
       let query = window.location.search.substring(1);
       let vars = query.split("&");
       for (let i=0;i<vars.length;i++) {
               let pair = vars[i].split("=");
               if(pair[0] == variable_name){
	
					let a_value = pair[1]
					if(a_value.includes(',')){
						a_value = a_value.split(',')
					}
					
					return a_value;
				}
       }
       return undefined;
}
export function noCallBack(response){
    return undefined;
}

// URL
export function loginVariablesUrl(){
    var out_string = ""
    if(isWurdLoggedIn()){
        out_string += "&access_token=" + getCookie('access_token')
    }
	if(context.share_token_key_received){ // even if logged in, in case a topic was recommended; access token takes precedence on share token in logging in
		out_string += "&share_token_key=" + context.share_token_key_received
	}
	/*
    if(isFBLoggedIn()){
        out_string += "&FBID=" + FBID + "&facebook_access_token=" + facebook_access_token;
    }
    if(isTwitterLoggedIn()){
        out_string += "&twitter_access_token=" + twitter_access_token;
    }
	*/
    return out_string
}