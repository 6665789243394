// FUNCTIONS
export function setCookie(cname, cvalue, exdays = 10000) {
    if(cvalue != undefined){

        cvalue = JSON.stringify(cvalue)
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; " + expires;

    }
}
export function getCookie(cname, default_value = undefined) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i=0; i<ca.length; i++) {
        let cc = ca[i];
        while (cc.charAt(0)==' ') cc = cc.substring(1);
        if (cc.indexOf(name) === 0) return JSON.parse(cc.substring(name.length, cc.length));
    }
    return default_value;
}

// // COOKIES
// export let category_title = getCookie('category_title')
// export let region = getCookie('region')
// export let platforms_selected = getCookie('platforms_selected')
// export let genres_selected = getCookie('genres_selected')
// export let content = getCookie('content')
// export let access_token = getCookie('access_token')