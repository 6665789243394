// CONSTANTS
export let models_dict = []
export let classes_dict = []

// CLASSES
export class Model{
	constructor(kwargs){
		
		for (var key in kwargs) {
	        if (kwargs.hasOwnProperty(key)) {
	           this[key] = kwargs[key];
	        }
	    }

		models_dict[this.gae_key] = this
		
	}
	
	static retrieve(gae_key){
		return models_dict[gae_key] // can be undefined	
	}
}

export class None extends Model{
}
classes_dict[undefined] = None

export function classWithClassName(class_name){
	let out = classes_dict[class_name]
	if(out == undefined){
		return None
	}
	return out
}