// IMPORTS
import { Component } from "react";

import { CloseButton } from "../generic/button"

import { MainContext } from "../generic/context";
import { context } from "../../util/global";

import movies from '../../img/filters/movies.svg'
import tv from '../../img/filters/tv.svg'

import apple from '../../img/filters/apple.svg'
import disney from '../../img/filters/disney.svg'
import hbo from '../../img/filters/hbo.svg'
import hulu from '../../img/filters/hulu.svg'
import netflix from '../../img/filters/netflix.svg'
import prime from '../../img/filters/prime.svg'
import showtime from '../../img/filters/showtime.svg'
import { removeFromArray } from "../../util/array";

import { vhToPx } from "../../util/display";

// CONSTANTS
const k_categories_list = [{'filter': 'movies', 'title': 'Movies', 'icon': movies},
                    {'filter': 'tv', 'title': 'TV Shows', 'icon': tv}]

export const k_platforms_list = [{'filter': 'netflix', 'title': 'Netflix', 'icon': netflix},
                        {'filter': 'prime', 'title': 'Prime', 'icon': prime},
                        {'filter': 'hbo', 'title': 'HBO Max', 'icon': hbo},
                        {'filter': 'apple', 'title': 'Apple +', 'icon': apple},
                        {'filter': 'disney', 'title': 'Disney +', 'icon': disney},
                        {'filter': 'hulu', 'title': 'Hulu', 'icon': hulu},
                        {'filter': 'showtime', 'title': 'Showtime', 'icon': showtime},

                        ]

const k_genres_dict = {
                    'movies': [
                        {'filter': 'Comedy', 'title':'Comedy',},
                        {'filter': 'Romance', 'title':'Romance',},
                        {'filter': 'Drama', 'title':'Drama',},
                        {'filter': 'Action', 'title':'Action',},
                        {'filter': 'Adventure', 'title':'Adventure',},
                        {'filter': 'Western', 'title':'Western',},
                        {'filter': 'War', 'title':'War'},
                        {'filter': 'Horror', 'title':'Horror',},
                        {'filter': 'Family', 'title':'Family',},
                        {'filter': 'Animation', 'title':'Animation',},
                        {'filter': 'Thriller', 'title':'Thriller',},
                        {'filter': 'Mystery', 'title':'Mystery',},
                        {'filter': 'Crime', 'title':'Crime',},
                        {'filter': 'sciencefiction', 'title':'Science Fiction',},
                        {'filter': 'Fantasy', 'title':'Fantasy',},
                        {'filter': 'Documentary', 'title':'Documentary',},
                        {'filter': 'History', 'title':'History',},
                        {'filter': 'Music', 'title':'Music',},
                        
                        //{'filter': 'tvmovie', 'title':'TV Movie',},
 
                        
                ],
                    'tv': [
                        {'filter': 'Comedy', 'title':'Comedy', },
                        {'filter': 'Romance', 'title':'Romance', },
                        {'filter': 'Soap', 'title':'Soap',},
                        {'filter': 'actionadventure', 'title':'Action & Adventure', },
                        {'filter': 'scififantasy', 'title':'Sci-Fi & Fantasy', },
                        {'filter': 'Western', 'title':'Western', },
                        {'filter': 'Drama', 'title':'Drama', },
                        {'filter': 'Kids', 'title':'Kids', },
                        {'filter': 'Family', 'title':'Family', },
                        {'filter': 'Animation', 'title':'Animation', },
                        {'filter': 'Documentary', 'title':'Documentary', },
                        {'filter': 'News', 'title':'News', },
                        {'filter': 'Reality', 'title':'Reality', },
                        {'filter': 'History', 'title':'History', },
                        {'filter': 'warpolitics', 'title':'War & Politics', },
                        {'filter': 'Talk', 'title':'Talk', },
                        {'filter': 'Mystery', 'title':'Mystery', },
                        {'filter': 'Musical', 'title':'Musical', },
                        {'filter': 'Music', 'title':'Music', },
    
                ]
}


// CLASSES
export class ViewFilter extends Component{

    constructor(props){

        super(props)
        this.onClick = this.onClick.bind(this)
    }
    render(){

        let category_title = context.category_title
        let view_filter = this

        let idx = 0
        let category_buttons = []
        k_categories_list.forEach(function(a_category){
            category_buttons.push(<ViewFilterButton
                            title={a_category.title}
                            filter = {a_category.filter}
                            icon={a_category.icon}
                            filter_type='category'
                            key={a_category.title}
                            callback_function={view_filter.onClick}
                            is_selected={view_filter.isSelected('category', a_category.filter)}
                            />)
            idx += 1
        })

        idx = 0
        let platforms_buttons = []
        k_platforms_list.forEach(function(a_platform){
            if(context.platforms_available[category_title].includes(a_platform.filter)){
                platforms_buttons.push(<ViewFilterButton
                                title={a_platform.title}
                                filter = {a_platform.filter}
                                icon={a_platform.icon}
                                filter_type='platforms'
                                key={a_platform.title}
                                callback_function={view_filter.onClick}
                                is_selected={view_filter.isSelected('platforms', a_platform.filter)}
                                />)

                idx += 1
            }
        })

        idx = 0
        let genres_buttons = []
        k_genres_dict[category_title].forEach(function(a_genre){
            genres_buttons.push(<ViewFilterButton
                            title={a_genre.title}
                            filter = {a_genre.filter}
                            filter_type='genres'
                            key={a_genre.title}
                            callback_function={view_filter.onClick}
                            is_selected={view_filter.isSelected('genres', a_genre.filter)}
                            />)

            idx += 1
        })

        return(
            <div className='ContainerMain ContainerModal' style={{'minHeight': vhToPx('100vh')}}>

                <CloseButton/>
                <div className='ContainerH1'>
                    <h1>Filters</h1>
                </div>
                <div className="ContainerContent">
                    <div className='ContainerH2'>
                        <h2>Category</h2>
                    </div>
                    <div>
                        {category_buttons}
                    </div>
                    <br/>
                    <div className='ContainerH2'>
                        <h2>Platform</h2>
                    </div>
                    <div>
                        {platforms_buttons}
                    </div>
                    <br/>
                    <div className='ContainerH2'>
                        <h2>Genres</h2>
                    </div>
                    <div>
                        {genres_buttons}
                    </div>
                </div>
            </div>
        )
    }

    onClick(filter_type, filter_value, is_selected){
        
        if(filter_type == 'category'){
            if(!is_selected){
                // unselected a selected category => do nothing
                return
            }
        }

        let context_variable = this.contextVariable(filter_type)
        if(filter_type == 'category'){
            // only one selected
            context_variable.length = 0
        }
        if(is_selected){
            context_variable.push(filter_value)
        }
        else{
            context_variable = removeFromArray(context_variable, filter_value)
        }

        if(filter_type == 'category'){
            context.category_title = context_variable[0]
            context.genres = [] // reset, different genres
        }
        else if(filter_type == 'platforms'){
            context.platforms = [...context_variable]
        }
        else if(filter_type == 'genres'){
            context.genres = [...context_variable]
        }

        // should rerender? But doesnt
        this.forceUpdate()
    }

    isSelected(filter_type, filter){

        return this.contextVariable(filter_type).includes(filter)

    }
    contextVariable(filter_type){
        if(filter_type == 'category'){
            return [context.category_title]
        }
        else if(filter_type == 'platforms'){
            return context.platforms
        }
        else if(filter_type == 'genres'){
            return context.genres
        }
    }
}

class ViewFilterButton extends Component{

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
        //this.state = {'is_selected': this.props.is_selected}
    }

    render(){

        // is selected
        let is_selected = this.props.is_selected

        // display
        let class_name
        if(is_selected){
            class_name = "ButtonFilterSelected"
        }
        else{
            class_name = "ButtonFilterUnselected"
        }
        
        let class_names = "ButtonFilter " + class_name
        let image_component
        let text_style
        if(this.props.icon){
            image_component = <img
                src={this.props.icon}
                style={{'verticalAlign':'middle',
                        'display': 'inline-block',
                        'height': '100%',
                        'paddingRight': '2vw',
                        }}
            />
            text_style = {'paddingRight': '2vw'}
        }
        else{
            text_style = {'paddingRight': '2vw', 'paddingLeft': '2vw'}
        }

        return(
            <button className={class_names}
            style={{'marginBottom':'3.2vw'}}
            onClick={this.onClick}>
                {image_component}
                <span style={text_style} className="inlineBlock">
                    {this.props.title}
                </span>
            </button>
        )
    }

    onClick(){

        //this.setState({'is_selected': is_selected})
        this.props.callback_function(this.props.filter_type,
                            this.props.filter,
                            !this.props.is_selected)
        
    }
}