
// IMPORTS
import $ from "jquery"
import React, { Component } from "react";

import {Url} from "../../util/server"

// CLASSES
let idx = 0
export class ImagePicker extends Component{
	
	// init
	constructor(props){
		// props: image_ref, parent

		super(props)
		this.state = {'idx': idx, 'image_compressed_ref': React.createRef()}
		idx += 1

		this.inputID = this.inputID.bind(this)
		
	}

	render(){
		//type="file" accept="image/png, image/jpg, image/jpeg"
		return <div>
					<input id={this.inputID()}
						type="file" accept="image/*;capture*/"
						className="hidden"/>
					<img className="hidden" src='' id={this.imageCompressedID()} ref={this.state.image_compressed_ref}/>
				</div>
	}

	inputID(){

		return 'image_picker_' + this.state.idx
	}

	imageCompressedID(){

		return 'image_picker_compressed_' + this.state.idx

	}

	componentDidMount(){

		const compressed_size = 100
		let picker = this
		let picker_id = this.inputID()
		let upload_url = this.props.upload_url
		let canvas = document.createElement("canvas");
		canvas.height = compressed_size
		canvas.width = compressed_size
		let context = canvas.getContext("2d");

		$('#' + picker_id).ready(function(){
			
			let image_input = document.getElementById(picker_id)
			let image_compressed = document.getElementById(picker.imageCompressedID())

			// click input
			image_input.addEventListener("change", function (){
				
				let a_file = this.files[0]

				if(a_file){

					// Show
					const reader = new FileReader();
					reader.addEventListener("load", () => {

						const uploaded_image = reader.result;

						// Get image size
						const image_original = new Image();
						image_original.onload = function () {
							
							// Resizing
							const image_compressed = new Image()
							image_compressed.src = uploaded_image

							let width_compressed = compressed_size
							let height_compressed = compressed_size * this.height / this.width
							let y_offset = -(height_compressed - width_compressed) / 2
							
							context.drawImage(image_compressed, 0, y_offset, width_compressed, height_compressed);
							let data_url = canvas.toDataURL(a_file.type);

							//let image_div = document.querySelector('#' + div_id);
							//image_div.src = uploaded_image
							picker.props.image_ref.current.src = data_url

							// Send
							canvas.toBlob((blob) =>
								$.ajax({
									
									url: upload_url,
									type: "PUT",
									data: blob, // a_file
									processData: false,
									contentType: 'image/jpeg',
									crossDomain : true,
									headers: {
										'Content-Type': 'image/jpeg', //'application/octet-stream', 
										'Access-Control-Allow-Origin': '*',
									},
									success: function(response) {
										
											let a_url = new Url('qUserUploadedImage')
											a_url.get()

									},
									error: function(jqXHR, textStatus, errorMessage) {
									}
									}),
								a_file.type)
						};
						image_original.src = uploaded_image;

					});
			
					reader.readAsDataURL(a_file)
					
				}
			});

		})

	}
}

let image_compressed
function compressImage(image_original, width_resized, quality_ratio = undefined) {
	
	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");
	
	const width_original = image_original.width;
	const height_original = image_original.height;

	const size_ratio = width_resized / width_original
	const height_resized = height_original * size_ratio;
	
	canvas.width = width_resized;
	canvas.height = height_resized;

	context.drawImage(
	  image_original,
	  0,
	  0,
	  width_original * size_ratio,
	  height_original * size_ratio
	);
	
	// reducing the quality_ratio of the image
	canvas.toBlob(
	  (blob) => {
		if (blob) {
			image_compressed = blob;
		}
	  },
	  "image/jpeg",
	  undefined
	);
  }