export function daysAgo(a_date){
    
    let today = new Date()
    let days = today.getDate() - a_date.getDate()

    if(days == 0){
        return "today"
    }
    if(days == 1){
        return "yesterday"
    }

    return days + " days ago"
}

export function releaseDate(release_date_str){

    let a_date = new Date(Date.parse(release_date_str))
    let today = new Date()

    let months = today.getMonth() - a_date.getMonth()
    let years = today.getFullYear() - a_date.getFullYear()
    let out
    if((months < 1) & (years == 0)){
        out = "this month"
    }
    else if((months < 2) & (years == 0)){
        out = "last month"
    }
    else if(years < 1){
        out = "this year"
    }
    else if(years < 2){
        out = "last year"
    }
    else{
        out = a_date.getFullYear()
    }
    
    return out
}