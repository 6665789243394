// IMPORTS
import { Component } from "react";
import {ViewTopic} from "../../components/views/topic"
import { MainContext } from "../generic/context"
import {hideKeyboard, vwToPx} from "../../util/display"
import {Avatar} from "../../components/users/avatar"
import {User} from "../../models/user"

// CLASSES
export class TopicCell extends Component{

    static contextType = MainContext;
    static defaultProps = {'like_str': 'liked it',
                            'hide_topic_title': false}

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
    }
    render(){

        let avatar
        if(this.props.topic.top_friend_gae_key){

            let user_liked = User.retrieve(this.props.topic.top_friend_gae_key)
            avatar = <div style={{'position': 'absolute', 'left':vwToPx('8vw'), 'marginTop':vwToPx('2vw')}}>
                        <div className="SwipeCardContentAvatar">
                            <Avatar user={user_liked} size='15vw' shape='blob'/>
                            <span className="SwipeCardContentAvatarText">{user_liked.pseudo} {this.props.like_str}.</span>
                        </div>
                    </div>
        }

        let title_component
        if(!this.props.hide_topic_title){
            title_component = <div>
                                <span className="grayText">{this.props.topic.category_title}</span> {this.props.topic.title}
                            </div>
        }

        return(
            <div className="ContainerTopicCell" onClick={this.onClick}>
                {avatar}
                <img
                    className="ContainerTopicCellImage"
                    src={this.props.topic.image_url}
                    width="100%"
                />
                {title_component}
            </div>

        )
    }
    onClick(){

        hideKeyboard()
        //this.context.app_ref.current.state.modal_ref.current.hide()
        this.context.app_ref.current.showModal(
            <ViewTopic topic={this.props.topic}/>,
            0.5, false, ViewTopic.onCloseModal)

    }
}

export class TopicCellsList extends Component{

    render(){

        let topics_cells_list = []
        let idx = 0;
        let topics_list = this.props.topics
        if(topics_list == undefined){
            topics_list = []
        }
        let that = this
        topics_list.forEach(function(a_topic){
            topics_cells_list.push(<TopicCell key = {a_topic.gae_key + '_' + idx} topic = {a_topic} like_str = {that.props.like_str}/>)
            idx += 1;
        })

        return topics_cells_list
    }
}