// IMPORTS
import { Component } from "react";

// CLASSES
export class Alert extends Component{
    render(){
        return(
            <div>
                {this.props.value}
            </div>
        )
    }
}