// IMPORTS
import $ from 'jquery'

// FUNCTIONS
export function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

export function toggleDivBetweenHeights(topic_div_id,a_height,another_height){
    var current_height = $('#' + topic_div_id).height();
    if(a_height==current_height){
        $("#" + topic_div_id).height(another_height);
        //$("#" + topic_div_id).animate({
        //    height:another_height
        //},200);
    }
    else{
        $("#" + topic_div_id).height(a_height);
        //("#" + topic_div_id).animate({
        //    height:a_height
        //},200);
    }
}
export function setDivHeight(div_id, a_height){
    $("#" + div_id).height(a_height);
}

export function setInnerHTMLForDiv(html, div_id){
    
	let models = document.querySelectorAll('#' + div_id);
	models.forEach(function(model){
		if(model !== null){
			model.innerHTML = html
		}
	})
    
}
export function divHTML(div_id){
	return document.getElementById(div_id).innerHTML
}

export function showDiv(div_id, animation_duration = 0.){
	
   let a_div = $("#" + div_id);
	if(a_div.length === 0){
		return
	}
	
	a_div.show(animation_duration); 
	/*
	a_div.style.display = "block";
	if(animation_duration > 0.){
		
		a_div.style.opacity = 0.
		a_div.animate({
			opacity: 1.
		}, animation_duration)
		
	}
	*/

}
export function hideDiv(div_id, animation_duration = 0.){
	
	// document.getElementById(div_id);
    let a_div = $("#" + div_id);
    if(a_div.length === 0){
		//a_div.style.display = "none"
	}
	else{
		a_div.hide(animation_duration); 
	}
}