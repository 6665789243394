// IMPORTS
// https://www.quod.ai/post/how-to-integrate-facebook-login-api-into-your-react-app
import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login';	
import {User} from '../../models/user'

// User
class FacebookUser{
	constructor(user_id = undefined, name = undefined, first_name = undefined, last_name = undefined, short_name = undefined, email = undefined, image_url = undefined, friends_list = undefined,
	social_access_token = undefined, social_access_token_expiry = undefined){
		
		this.social_account = 'facebook'
		this.user_id = user_id
		this.name = name
		this.first_name = first_name
		this.last_name = last_name
		this.short_name = short_name
		this.email = email
		this.social_access_token = social_access_token
		this.social_access_token_expiry = social_access_token_expiry
		this.image_url = image_url
		this.friends_list = friends_list
		
	}
}
let facebook_user = new FacebookUser()

export class FacebookConnect{
	static parseUser(response){
		
		if (response.status == 'unknown'){
			return
		}
		if (response && !response.error) {
			
			/* handle the result */
			facebook_user.name = response.name
			facebook_user.short_name = response.facebook_user
			facebook_user.first_name = response.first_name
			facebook_user.last_name = response.last_name
			facebook_user.email = response.email
			facebook_user.user_id = response.userID
			facebook_user.social_access_token = response.accessToken
			facebook_user.social_access_token_expiry = response.expiresIn
			
			if(response.picture){
				if(response.picture.data){
					facebook_user.image_url = response.picture.data.url		
				}
			}

			User.createWithSocialUser(facebook_user)

		}
	}

	static parseFriends(response){
		// /friends?fields=uid,email
		
	}
}

// Download SDK
/*
window.fbAsyncInit = function() {
	
	console.log('initializing Facebook')
    FB.init({
      appId      : '324112753198143',
      cookie     : true,
      xfbml      : true,
      version    : 'v13.0'
    });
      
    FB.AppEvents.logPageView();   

};

(function(d, s, id){
	
	 console.log('load Facebook sdk')
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
     
}(document, 'script', 'facebook-jssdk'));
*/
// Is logged in?
/*
function facebookStatusHasChanged(response){
	console.log('facebookStatusHasChanged')
	if(response.status == "connected"){
		
		console.log('	connected!', response)
		response = response.authResponse
		facebook_user.user_id = response.userID
		facebook_user.social_access_token = response.accessToken
		facebook_user.social_access_token_expiry = response.data_access_expiration_time

		FB.api(
	    facebook_user.user_id,
	    'GET',
	    {'fields':'id,name,email,first_name,last_name,short_name,picture'},
	    facebookReceivedUsersProfile
		)
		
	}
}
*/

/*
function facebookHasLoggedIn() {
	
	console.log('facebookHasLoggedIn')
	// Function called after login button
    FB.getLoginStatus(facebookStatusHasChanged)
}

function facebookRefreshDisplay(){
	FB.XFBML.parse()
}
*/