// IMPORTS
import { Component } from "react";
import {Avatar} from "./avatar"
import {FollowButton} from "./friends"
import {SelectButton} from "../generic/button"

const cell_type_row = 'row'
const cell_type_vignette = 'vignette'

class UserCell extends Component{
    
    static defaultProps = {'cell_type': cell_type_row}

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
        this.state = {'is_avatar_selected': this.props.is_avatar_selected}
    }
    render(){

        let select_button
        let class_names_pseudo
        let pseudo_component = this.props.user.pseudo
        if(!pseudo_component){
            pseudo_component = 'anonymous'
        }
        let email_component
        if(this.props.is_show_email){
            
            let email = this.props.user.email
            if(email != undefined){
                if(email.length > 20){
                    email = email.slice(0, 20) + "..."
                }
                email_component = <div className="grayText">{email}</div>
            }
        }

        if(this.props.cell_type == cell_type_row){
            class_names_pseudo = "FollowUserPseudo"
        }
        else{
            class_names_pseudo = ""
        }
        if(this.props.has_select_button){
            if(!this.props.is_disabled){
                select_button = <SelectButton/>
            }
            else{
                // disabled
                select_button = <div className="FollowUserAlreadyFollowed">already seen it</div>
                class_names_pseudo += " grayText"
            }
        }
        
        if(this.props.cell_type == cell_type_row){

            let follow_button
            if(this.props.has_follow_button){
                follow_button = <FollowButton user={this.props.user}/>
            }

            return(<div className="inlineBlock verticalMiddle ContainerUserCellRow fullWidth"
                    >
                        <Avatar user={this.props.user}
                                shape='circle'
                                size='11.73vw'
                                is_disabled={this.props.is_disabled}
                                is_selected={this.state.is_avatar_selected}
                                is_selectable={this.props.is_selectable}/>
                        <div className={class_names_pseudo} onClick={this.onClick}>
                            {pseudo_component}
                            {email_component}
                        </div>
                        {select_button}
                        {follow_button}
                    </div>
                )
        }
        else{
            return(<div className="inlineBlock alignCenter"  style={{'width':'30vw', 'marginBottom': '5.26vw'}}
                onClick={this.onClick}>
                        <Avatar user={this.props.user}
                                shape='circle'
                                size='20vw'
                                is_disabled={this.props.is_disabled}
                                is_selected={this.state.is_avatar_selected}
                                is_selectable={this.props.is_selectable}/>
                        <div className={class_names_pseudo} style={{'marginTop': '1.5vw'}}>
                            {pseudo_component}
                        </div>
                    </div>
                )
        }
    }
    onClick(){

        if(!this.props.is_disabled){
            this.setState({'is_avatar_selected': !this.state.is_avatar_selected})
        }
        if(this.props.on_click_function){
            this.props.on_click_function(this.props.user)
        }
    }
}



export class UsersCells extends Component{

    static defaultProps = {
        users: [],
        has_follow_button: true,
        has_select_button: false,
        cell_type: cell_type_row,
    }
    render(){

        let users_list = []
        let idx = 0
        let users_cells = this
        let vignettes_per_row = 3
        let separation_component
        if(this.props.cell_type == cell_type_row){
            separation_component = <div className="SeparationFullWidth"/>
        }
        else{
            separation_component = <div><br/></div>
        }

        let vignettes_list = [] 
        
        this.props.users.forEach(function(a_user){
            
            let user_cell_component = <UserCell
                        is_disabled={a_user.is_disabled}
                        is_avatar_selected={a_user.is_selected}
                        user={a_user}
                        is_selectable={users_cells.props.is_selectable}
                        has_follow_button={users_cells.props.has_follow_button}
                        has_select_button={users_cells.props.has_select_button}
                        cell_type={users_cells.props.cell_type}
                        on_click_function={users_cells.props.on_click_function}
                        key={a_user.gae_key}
                        is_show_email={users_cells.props.is_show_email}/>

            if(users_cells.props.cell_type == cell_type_row){
                users_list.push(<div key={a_user.gae_key}>
                                    {user_cell_component}
                                    {separation_component}
                                </div>)
            }
            else{
                
                vignettes_list.push(user_cell_component)
                
                if((idx % vignettes_per_row == vignettes_per_row - 1) | (idx == users_cells.props.users.length - 1)){

                    // new row
                    let row_component = <div className="inlineBlock alignCenter" key={a_user.gae_key}>
                                            {vignettes_list}
                                        </div>
                    
                    users_list.push(row_component)
                    vignettes_list = []
                }
            }

            idx += 1
        })
        
        return(
            <div>
                {users_list}
            </div>
        )
    }
}