// IMPORTS
import { Url } from "../util/server"
import $ from 'jquery'

// CLASSES
export class Scroller{
	constructor(div_id, url, callback_function = undefined, is_loading_function = undefined, is_enabled = true){
		
		this.div_id = div_id
		this.url = url
		this.callback_function = callback_function
		this.is_loading_function = is_loading_function
		this.is_loading = false
		this.has_more = true
		this.cursor = undefined
		this.is_enabled = is_enabled

		this.onScroll = this.onScroll.bind(this)
		this.parse = this.parse.bind(this)

		// Set onScroll: can't make react onScroll work on divs. TODO: use react
		let that = this
		$(() => {
			window.addEventListener('scroll', 
				function(event){
					if($('#' + div_id).is(':visible')){
						that.onScroll(event)
					}
				})
		})

	}
	get(){

		let that = this
		let url_get = new Url(that.url.q, ...that.url.variables_and_values)
		url_get.variables_and_values.push('cursor')
		url_get.variables_and_values.push(that.cursor)
		url_get.variables_and_values.push('has_more')
		url_get.variables_and_values.push(that.has_more)
		url_get.get(that.parse)

	}
	onScroll(event){
		if(this.is_enabled){
			if(!this.is_loading){
				if(this.has_more){
					// at bottom?
					const div = document.getElementById(this.div_id)
					const div_rect = div.getBoundingClientRect()
					const y = -div_rect.y
					if(y + window.innerHeight + 50 >= div_rect.height){
						
						// is loading
						this.is_loading = true
						if(this.is_loading_function != undefined){
							this.is_loading_function()
						}

						// get url
						this.get()
						
					}
				}
			}
		}
	}
	parse(response){

		let that = this
		this.cursor = response.cursor
		this.has_more = response.has_more
		if(that.callback_function != undefined){
			that.callback_function(response)
		}
		this.is_loading = false
	}
	
}
