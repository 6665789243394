// IMPORTS
import {context as globalcontext} from '../util/global'
import {Model, classes_dict, models_dict} from './main'

import {Url} from '../util/server'

// CONSTANTS
let topics_div_expanded = [];
let topics_displayed = [];
const k_activity_types = ['like', 'dislike', 'bookmark', 'trash']
const k_topic_button_types = ['like', 'dislike', 'share']

let recommendation_count = 0 // as it gets higher, type of recommendation can change

// CLASSES
export class Topic extends Model{

    userActivity(activity_type, activity_score = 1, context = undefined, send_request = true){
        // context is app context
        if(context != undefined){
            if(!context.app_ref.current.checkIfLogin()){
                return
            }
        }

        /*
        // Share
        if(activity_type == 'share'){
        
            recommender.share(gae_key)
            return;
        }
        */
    
        // Refresh needed
        if(activity_type == 'bookmark'){
            globalcontext.needs_reload['bookmarks'] = true
        }

        // is on
        let is_on = this.user_activity == activity_type;
        is_on = !is_on
        if(is_on){
            this.user_activity = activity_type;
            this.user_activity_score = activity_score;
        }
        else{
            this.user_activity = undefined;
            this.user_activity_score = undefined;
        }

        // request
        if(send_request){
            let url = new Url('qTopicWasActivitiedByUser', 'topic_gae_key', this.gae_key, 'is_on', is_on, 'activity_type', activity_type, 'activity_score', activity_score);
            url.get();
        }

    }

    recommendWithActivity(activity_type, activity_score, top_count, context, topics_exclude = undefined, callback_function = undefined){
        
        this.userActivity(activity_type, activity_score, context, false)

        // request
        if(top_count <= 0){
            return
        }

        let topics_keys_exclude = []
        if(topics_exclude != undefined){
            topics_exclude.forEach(function(a_topic){
                topics_keys_exclude.push(a_topic.gae_key)
            })
        }

        let url = new Url('qRecommend',
                'category_title', context.category_title,
                'platform', context.platforms,
                'region', context.region,
                'genres', context.genres,
                'recommendation_count', recommendation_count,
                'topic_gae_key', this.gae_key,
                'top', top_count,
                'exclude_topics_keys', topics_keys_exclude.join(','),
                'activity', activity_type,
                'activity_score', activity_score)

        url.get(callback_function)
    }

}
export class Movies extends Topic{
}
export class TV extends Topic{
}

export class Platform{
    
}

classes_dict['Topic'] = Topic
classes_dict['Movies'] = Movies
classes_dict['TV'] = TV
classes_dict['Platform'] = Platform