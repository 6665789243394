/// IMPORTS
import {Component} from "react"

// CLASSES
export class Expanso extends Component{

    static defaultProps = {'max_length': 100}
    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
        this.state = {'is_expanded': false}
    }
    render(){

        let text = this.props.text
        if(text == undefined){
            return <div/>
        }
        if((text.length <= this.props.max_length) | (this.state.is_expanded)){
            return text
        }
        else{

            let short_text = text.substring(0, this.props.max_length)
            let last_space_index = short_text.lastIndexOf(' ')
            short_text = short_text.substring(0, last_space_index)
            short_text += '...'

            return(
                <div onClick={this.onClick}>
                    {short_text}
                </div>
            )
        }
        
    }

    onClick(){
        this.setState({'is_expanded': !this.state.is_expanded})
    }
}

export class SeparationOr extends Component{
    static defaultProps = {'opacity': 1.}
    render(){
        return(
            <div style={{'opacity': this.props.opacity, 'marginBottom': this.props.margin_bottom}}>
                <div className="SeparationLine" style={{'float':'left'}}/>
                <div className="SeparationOr inlineBlock">Or</div>
                <div className="SeparationLine" style={{'float':'right'}}/>
            </div>
        )
    }
}