// MISC



export function dictValueWithDefault(a_dict, a_key, default_value){
	if(a_dict[a_key] == undefined){
		return default_value;
	}
	else{
		return a_dict[a_key];
	}
}

// IS MOBILE
var is_mobile = (window.orientation !== undefined);
is_mobile = true;

export function isUploadSupported() {
    if (navigator.userAgent.match(/(Android (1.0|1.1|1.5|1.6|2.0|2.1))|(Windows Phone (OS 7|8.0))|(XBLWP)|(ZuneWP)|(w(eb)?OSBrowser)|(webOS)|(Kindle\/(1.0|2.0|2.5|3.0))/)) {
        return false;
    }
    var elem = document.createElement('input');
    elem.type = 'file';
    return !elem.disabled;
};

export function findBootstrapEnvironment() {

    return 'xs'
    /*
    var envs = ['xs', 'sm', 'md', 'lg'];

    var $el = $('<div>');
    $el.appendTo($('body'));

    for (var i = envs.length - 1; i >= 0; i--) {
        var env = envs[i];

        $el.addClass('hidden-'+env);
        if ($el.is(':hidden')) {
            $el.remove();
            return env;
        }
    }
    */
}
export function isMobile(){
    var current_env = findBootstrapEnvironment();
    if((current_env == 'xs') || (current_env == 'sm')){
        return true;
    }else{return false;}
}
export function isDesktop(){
  return window.innerWidth > window.innerHeight
}

// CLIPBOARD
// from: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
export function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      if (successful){
          alert("Copied to clipboard");
      }
    } catch (err) {
    }
  
    document.body.removeChild(textArea);
  }
export function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
    }, function(err) {
    });
}

// SLEEP
export function sleep(ms, callback_function) {
  return setTimeout(callback_function, ms);
}