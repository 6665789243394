// IMPORTS
import { Component } from "react";

import { Avatar } from "./avatar";

import { daysAgo } from "../../util/date";
import { WordsCells } from "../words/cell";

import { MainContext} from "../generic/context";
import {context as globalcontext} from "../../util/global"
import { ViewTopic } from "../views/topic";

import { hideKeyboard } from "../../util/display";

import {activities_groups, activityGroupsWithActivities} from "../../models/activity"

import heart from "../../img/icons/heart.gif"
import bomb from "../../img/icons/bomb.gif"

import { vwToPx } from "../../util/display";
import { ViewProfile } from "../views/profile";

import { k_modal_type_default } from "../generic/modal";

// CLASSES
export class ActivityCell extends Component{

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this)
        this.onClickAvatar = this.onClickAvatar.bind(this)
    }

    static contextType = MainContext;

    static defaultProps = {
        'show_topic_image': true,
        'show_days_ago': false,
    }
    render(){

        let image_component // adding image_component blurs everything??
        let classes_names = "block"

        if(this.props.show_topic_image){
            image_component = <div className="ContainerActivityImage" onClick={this.onClick}>
                                    <img
                                        src={this.props.activity.topic.image_url}
                                        width='100%'
                                        height='100%'
                                    />
                                </div>
            classes_names += " ContainerActivity"
        }

        let pseudo_text = this.props.activity.user.pseudo
        if(this.props.activity.user.gae_key == globalcontext.user.gae_key){
            pseudo_text = "You"
        }
        let pseudo_text_class_names = ""
        //if(this.props.show_topic_image){

        let like_text
        let and_called_it_text
        let activity_type = this.props.activity.activity_type
        let activity_score = this.props.activity.score
        if(!activity_score){
            activity_score = 1
        }
        let words = this.props.activity.words
        if(['like', 'dislike'].includes(activity_type)){

            let img_src
            let images = []
            let text
            if(activity_type == 'like'){
                img_src = heart
                if(activity_score == 1){
                    text = 'liked'
                }
                else if(activity_score == 2){
                    text = 'really liked'
                }
                else if(activity_score == 3){
                    text = 'absolutely loved'
                }
            }
            else{
                img_src = bomb
                if(activity_score == 1){
                    text = 'disliked'
                }
                else if(activity_score == 2){
                    text = "really didn't like"
                }
                else if(activity_score == 3){
                    text = 'absolutely hated'
                }
            }

            for(let i=0;i<activity_score;i+=1){

                let style={'width':vwToPx('8vw')}
                if(i > 0){
                    style['marginLeft'] = '-2vw'
                }
                images.push(<img src={img_src} style={style} key={i}/>)
            }

            like_text = <span>{text}<span className="imageCenter">{images}</span></span>
            if(words.length > 0){
                and_called_it_text = ' and called it:'
            }
            else{
                and_called_it_text = '.'
            }
        }
        else { // no like or dislike
            if(words.length > 0){
                like_text = 'called'
                and_called_it_text = ':'
            }
            else{
                return <div></div>
                // like_text = 'saw'
                // and_called_it_text = '.'
            }
        }
        pseudo_text = <span>
                        <span onClick={this.onClickAvatar}>{pseudo_text + " "}</span>
                        {like_text}
                        {" "}
                        <span className='TopicInText'>{this.props.activity.topic.title}</span>
                        {and_called_it_text}
                        </span>
        //}
        /*
        else{
            pseudo_text_class_names = "grayText"
        }
        */
        pseudo_text = <span className={pseudo_text_class_names} style={{'marginBottom': '1.67vw'}}>
                            {pseudo_text}
                        </span>

        let days_ago
        if(this.props.show_days_ago){
            days_ago = <div>
                            {daysAgo(this.props.activity.created_at)}
                        </div>
        }

        let container_classes_names = "row ContainerActivity"
        if(this.props.idx % 2 == 1){
            container_classes_names += " ContainerActivityPurple"
        }
        
        return(
            <div className={container_classes_names}>
                <div className="col" style={{'width': '12vw', 'height': '12vw'}} onClick={this.onClickAvatar}>
                    <Avatar user={this.props.activity.user} shape='circle' size='10.67vw'/>
                </div>
                <div className="col" style={{'paddingLeft':'2.667vw', 'width': '74vw'}}>
                    <div className="row ContainerActivityPseudo">
                        {pseudo_text} 
                    </div>
                    <div className="row">
                        {days_ago} 
                    </div>
                    <div className="row">
                        <WordsCells words={this.props.activity.words}/>
                    </div>
                    {image_component}
                </div>
                
            </div>
        )
    }

    onClick(){

        hideKeyboard()
        //this.context.app_ref.current.state.modal_ref.current.hide()
        this.context.app_ref.current.showModal(
            <ViewTopic topic={this.props.activity.topic}/>,
            0.5, false, ViewTopic.onCloseModal)

    }

    onClickAvatar(){

        hideKeyboard()
        if(this.props.activity.user.gae_key != globalcontext.user.gae_key){
            this.props.activity.user.is_followed = true
            this.context.app_ref.current.showModal(
                <ViewProfile user={this.props.activity.user} is_hidden_button={true} is_loaded_on_mount={true}/>, 0.05, false, undefined, k_modal_type_default, false
            )
        }

    }
}

export class ActivityCellsList extends Component{

    static defaultProps = {
        'show_topic_image': true,
    }
    render(){

        let activities_components_list = [];
        let idx = 0
        let activity_list_object = this
        let activities_list = this.props.activities
        if(activities_list == undefined){
            activities_list = []
        }

        let activities_groups = activityGroupsWithActivities(activities_list)

        let activity
        for (const [key, activity_group] of Object.entries(activities_groups)){
            
            activity = {
                'topic': activity_group['topic'],
                'activity_type': activity_group['activity_type'],
                'score': activity_group['score'],
                'words': activity_group['words'],
                'user': activity_group['user'],
            }

            activities_components_list.push(<ActivityCell
                                                activity={activity}
                                                show_topic_image={activity_list_object.props.show_topic_image}
                                                key ={key}
                                                idx = {idx}/>)
            idx += 1
        }
        return(
            <div>
                {activities_components_list}
            </div>
        )
    }
}